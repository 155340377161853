export const INPUT_REQUIREMENTS = {
  PASSWORD_REQUIRMENTS: "required|length:8|*matches:/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?\\W)/",
  GOOGLE_AUTHENTICATE_REQUIREMENTS: "required|length:6",
};

export const FORM_ERRORS_TEXT = {
  EMAIL_NOT_VALID: "Please enter a valid email address associated with your institute",
  EMAIL_IS_NOT_FREE: "Please enter a valid email address associated with your institute",
  PASSWORD_NOT_VALID: "Passwords must have at least: one symbol, one number, one lowercase and one uppercase letter.",
  PASSWORD_FAILED_RESET_REQUEST: "Password reset request failed",
  PASSWORD_FAILED_RESET: "Password reset failed",
  REGISTRATION_FAILED: "Registration  failed",
  UNKNOWN_SERVER_ERROR: "Unknown error. Please, contact us or try again later.",
};

export const AUXILIARY_URLS = {
  GOOGLE_AUTHENTICATOR_FOR_ANDROID:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  GOOGLE_AUTHENTICATOR_FOR_IOS: "https://apps.apple.com/us/app/google-authenticator/id388497605",
};

export const PROFESSIONS_TITLE = {
  doctor: "Doctor",
  resident: "Resident",
  fellow: "Fellow",
  genetic_counselor: "Genetic Counselor",
  nurse: "Nurse",
  nurse_practicitioner: "Nurse Practicitioner",
  nurse_dietitician: "Nurse Dietitician",
  other: "Other",
};

export const SUBSPACIALTY_TITLE = {
  general_genetics: "General Genetics",
  cardiology: "Cardiology",
  oncology: "Oncology",
  pediatrics: "Pediatrics",
  ophthalmology: "Ophthalmology",
  pharmacogenetics: "Pharmacogenetics",
  neurology: "Neurology",
  biochemical: "Biochemical",
  other: "Other",
};

export const OMIM_ENTRY_TYPES = {
  gene: "gene",
  phenotype: "phenotype",
  predomPhenotype: "predominantly phenotypes",
  gene_and_phenotype: "gene/phenotype",
};

export const OMIM_RELATIONSHIP_TYPES = {
  gene: "Gene-Phenotype",
  phenotype: "Phenotype-Gene",
  na: "Not applicable",
};

export const sentenceIdPrefix = "lh_eli8_sent_";
