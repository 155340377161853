<template>
  <div>
    <div v-for="item in props.nodes" :key="item" class="section">
      <span v-if="item.infons.type.includes('title')">
        <span class="section-title">
          <InteractiveJsonParagraph :paragraph="item.text"></InteractiveJsonParagraph>
        </span>
      </span>
      <span v-else-if="item.infons.type === 'paragraph' || item.infons.type === 'abstract'">
        <InteractiveJsonParagraph :paragraph="item.text" :citation-text="props.citationText"></InteractiveJsonParagraph>
      </span>
      <span v-else-if="item.infons.type === 'table_caption'">
        <span class="section-title">{{ item.text }}</span>
      </span>
      <div v-else-if="item.infons.type === 'table'" class="large-tbl">
        <table>
          <thead>
            <tr>
              <th v-for="header in preprocessTableRow(preprocessTable(item).at(0))" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in preprocessTable(item).slice(1)" :key="row">
              <td v-for="cell in preprocessTableRow(row)" :key="cell">{{ cell }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-else-if="item.infons.type === 'table_foot'">
        <span class="table-footer">{{ item.text }}</span>
      </span>
      <span v-else-if="item.infons.type === 'ref'">
        <div class="ref">
          {{ preprocessRefNames(item.infons) }}{{ item.text }}. <em>{{ item.infons.source }}</em>
          {{ item.infons.year }};{{ item.infons.volume }}:{{ item.infons.fpage }}-{{ item.infons.lpage }}.
          <span v-if="item.infons.pubIdDoi"> doi {{ item.infons.pubIdDoi }}</span>
          <span v-if="item.infons.pubIdPmid"> [PubMed: {{ item.infons.pubIdPmid }}]</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script setup>
import InteractiveJsonParagraph from "./InteractiveJsonParagraph.vue";

const props = defineProps({
  nodes: {
    type: Object,
    required: true,
  },
  citationText: {
    type: String,
    required: true,
  },
});

function preprocessRefNames(infons) {
  let refNames = "";
  Object.keys(infons).forEach((element) => {
    if (element.includes("name")) {
      const lastName = infons[element].split(";")[0].split(":")[1];
      const firstName = infons[element].split(";")[1].split(":")[1];
      refNames += lastName + " " + firstName + ", ";
    }
  });
  if (refNames.length > 0) {
    return refNames + "et al. ";
  } else {
    return "";
  }
}

function preprocessTableRow(row) {
  return row.split("\t");
}

function preprocessTable(item) {
  return item.text.split("\t \t");
}
</script>

<style lang="sass" scoped>
.table-footer
  font-size: 12px
  line-height: 0.5

.ref
  margin-top: 15px
</style>
