import { defineStore } from "pinia";
import { ref } from "vue";

const userGuideTour = "user-guide-tour";

export const useInterfaceElementsStore = defineStore("interface-elements", () => {
  const sideMenuIsCollapsed = ref(true);
  const isSearchDropdownVisible = ref(false);
  const geneExplorerMenuShrunk = ref(false);

  const userGuideDefaultSteps = { first: false, second: false };

  const storedGuideTour = localStorage.getItem(userGuideTour);

  const userGuideTourInfo = ref(storedGuideTour ? JSON.parse(storedGuideTour) : userGuideDefaultSteps);

  function setNewGuideTourData(step) {
    if (step in userGuideTourInfo.value) {
      userGuideTourInfo.value[step] = true;

      localStorage.setItem(userGuideTour, JSON.stringify(userGuideTourInfo.value));
      userGuideTourInfo.value = userGuideTourInfo.value;
    }
  }

  return {
    sideMenuIsCollapsed,
    isSearchDropdownVisible,
    geneExplorerMenuShrunk,
    userGuideTourInfo,
    setNewGuideTourData,
  };
});
