<template>
  <div class="custom-simplifier-wrapper">
    <h4>Enter text for simplification in the box below</h4>
    <input
      id="custom-notes-title"
      v-model="userInputTitle"
      type="text"
      class="notes-title"
      :class="{ 'red-border': validateFields && !userInputTitle }" />
    <div class="error-wrapper">
      <p v-if="validateFields && !userInputTitle">Enter a title to simplify text.</p>
    </div>
    <textarea
      id="sentenceArea"
      v-model="userInputContent"
      class="classic-text-area"
      :class="{ 'red-border': validateFields && !userInputContent }"></textarea>
    <div class="error-wrapper">
      <p v-if="validateFields && !userInputContent">Enter content to simplify text.</p>
      <p v-if="failure">
        Something went wrong and we couldn't start simplification. Please, contact us or try again later.
      </p>
    </div>
    <div class="button-wrapper">
      <button @click="openSimplificationPage()">Simplify</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { eli8Service } from "@/services";

const router = useRouter();

const userInputTitle = ref(null);
const userInputContent = ref(null);
const failure = ref(false);
const validateFields = ref(false);

async function openSimplificationPage() {
  if (userInputTitle.value && userInputContent.value) {
    try {
      const response = await eli8Service.startUserInputSimplification({
        title: userInputTitle.value,
        content: userInputContent.value,
      });

      router.push({
        name: "simplify-user-input",
        params: { noteId: response },
      });
    } catch (error) {
      failure.value = true;
    }
  } else {
    validateFields.value = true;
  }
}
</script>

<style lang="sass" scoped>
.custom-simplifier-wrapper
  width: inherit
  padding: 0 56px 8px 0
  h4
    font-size: 25px
    font-weight: bold
    margin-bottom: 16px

  .red-border
    border: 2px solid $red

  .error-wrapper
    margin-bottom: 16px
    p
      color: $red
      font-size: 15px
      font-weight: 600
      margin-bottom: 8px

  .notes-title
    border-radius: 8px
    padding: 0px 0px 2px 8px
    margin-bottom: 16px

  .classic-text-area
    width: 100%
    min-height: 190px
    border-radius: 8px
    padding: 15px
    font-size: 20px
    color: $black
    margin-bottom: 8px

  .button-wrapper
    display: flex
    justify-content: flex-end

    button
      padding: 11px 42px
      color: $white
      font-size: 15px
      font-weight: 600
      border: none
      border-radius: 8px
      background-color: $blue
</style>
