import api from "@/services/api";

const { post } = api.getPrefixedMethods("analytics");

export const analyticsService = {
  logGeneticExplorerSearchAction(actionData) {
    post("genetic-explorer-search-log", actionData);
  },
  logGeneticExplorerPaperAction(actionData) {
    post("genetic-explorer-papers-log", actionData);
  },
  logGeneticExplorerParagraphAction(actionData) {
    post("genetic-explorer-paragraph-log", actionData);
  },
};
