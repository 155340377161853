<template>
  <GeneticSearchResults
    :get-list-function="getListFunction"
    :get-single-paper="getSinglePaper"
    :get-related-paper="getRelatedPaper"
    :log-paper-action="logPaperAction"
    :current-tab="'omim'">
    <template #list-view="slot">
      <div class="element-content cursor-pointer">
        <span class="element-header">
          <span class="first-term-letter" :class="getOmimTypeClass(slot.itemInfo.item.entry.entryType)">
            {{ getOmimTypeLetter(slot.itemInfo.item.entry.entryType) }}
          </span>
          {{ slot.itemInfo.item.entry.mimNumber }}
          {{ slot.itemInfo.item.entry.title }}
        </span>
        <span class="gray-auxiliary-information">
          <span v-if="slot.itemInfo.item.entry.cytogenicLocation">
            <br />
            <span>Cytogenetic location: </span>
            <span class="blue-auxiliary-information">{{ slot.itemInfo.item.entry.cytogenicLocation }}</span>
          </span>
          <span v-if="slot.itemInfo.item.entry.genomicCoordinates.length > 0">
            <span> Genomic coordinates (GRCh38): </span>
            <span
              v-for="gene in slot.itemInfo.item.entry.genomicCoordinates"
              :key="gene.chrom"
              class="blue-auxiliary-information">
              {{ gene.chrom }}: {{ gene.start.toLocaleString("en-EN") }} - {{ gene.end.toLocaleString("en-EN") }}
            </span>
          </span>
        </span>
        <br />
        <span>
          <span class="green-auxiliary-information">Matching terms: </span>
          <span v-for="term in slot.itemInfo.item.srcTerm" :key="term.title" class="green-auxiliary-information">
            {{ term.title }};
          </span>
        </span>
        <br />
        <span class="gray-auxiliary-information">
          <span>Date Created: </span>
          <span class="tabs-bold">{{ convertDateFormat(slot.itemInfo.item.entry.dateCreated) }}</span>
          <span v-if="slot.itemInfo.item.entry.dateModified">
            <span> Date Modified: </span>
            <span class="tabs-bold">{{ convertDateFormat(slot.itemInfo.item.entry.dateModified) }}</span>
          </span>
        </span>
      </div>
    </template>

    <template #paper-view="slot">
      <PaperSideMenu
        v-if="slot.itemContent.downloaded"
        :parsed-before-data="{
          'Gene-Phenotype Relationships': slot.itemContent.downloaded.relationshipType === OMIM_RELATIONSHIP_TYPES.gene,
          'Phenotype-Gene Relationships':
            slot.itemContent.downloaded.relationshipType === OMIM_RELATIONSHIP_TYPES.phenotype,
          'Clinical Synopsis': slot.itemContent.downloaded.clinicalSynopsis,
          'Phenotypic Series': slot.itemContent.downloaded.phenotypicSeriesTable.length,
        }"
        :parsed-data="slot.itemContent.downloaded.parsedPaperData.textSections"
        :parsed-after-data="{
          'Allelic Variants': slot.itemContent.downloaded.allelicVariants,
          References: slot.itemContent.downloaded.references,
          Contributors: slot.itemContent.downloaded.contributors,
          'Creation Date': slot.itemContent.downloaded.creationDate,
          'Edit History': slot.itemContent.downloaded.editHistory,
        }">
      </PaperSideMenu>
      <div class="tab-body-right" :class="{ 'shrunk-menu': userInterfaceElements.geneExplorerMenuShrunk }">
        <div class="paper-header omim-paper-header">
          <div class="main-header-paper-info">
            <div class="right-header-paper-info">
              <span class="paper-mim-number bold-title">
                <span class="first-term-letter" :class="getOmimTypeClass(slot.itemContent.content.entryType)">{{
                  getOmimTypeLetter(slot.itemContent.content.entryType)
                }}</span>
                {{ slot.itemContent.content.mimNumber }}
                <br />
                <span class="paper-tab-title">
                  <InteractiveJsonParagraph :paragraph="slot.itemContent.content.title"></InteractiveJsonParagraph>
                </span>
              </span>
              <span v-if="slot.itemContent.content.alternativeTitles">
                <span class="alternative-header gray-auxiliary-information">Alternative titles; symbols</span>
                <br />
                <span v-for="title in titlesPreprocess(slot.itemContent.content.alternativeTitles)" :key="title">
                  <span class="alternative-titles">{{ title }}</span>
                  <br />
                </span>
              </span>
              <span v-if="slot.itemContent.content.includedTitles">
                <br />
                <span class="alternative-header">Other entities represented in this entry:</span>
                <br />
                <span v-for="title in titlesPreprocess(slot.itemContent.content.includedTitles)" :key="title">
                  <span class="alternative-titles">{{ title }}</span>
                  <br />
                </span>
              </span>
              <span
                v-if="
                  slot.itemContent.downloaded &&
                  slot.itemContent.downloaded.relationshipType === OMIM_RELATIONSHIP_TYPES.gene
                "
                class="omim-location-coordinates">
                <span
                  v-if="
                    slot.itemContent.downloaded.genemapTable.length &&
                    slot.itemContent.downloaded.genemapTable[0].approvedGeneSymbols
                  ">
                  <br />
                  <span class="gray-auxiliary-information">HGNC Approved Gene Symbol: </span>
                  <span class="omim-loc-coord-dark-gray">{{
                    slot.itemContent.downloaded.genemapTable[0].approvedGeneSymbols
                  }}</span>
                </span>
                <span>
                  <span v-if="slot.itemContent.content.cytogenicLocation">
                    <br />
                    <span class="gray-auxiliary-information">Cytogenetic location: </span>
                    <span class="omim-loc-coord-dark-gray">{{ slot.itemContent.content.cytogenicLocation }}</span>
                  </span>
                  <br />
                  <span v-if="slot.itemContent.content.genomicCoordinates.length > 0">
                    <span class="gray-auxiliary-information">Genomic coordinates (GRCh38): </span>
                    <span
                      v-for="gene in slot.itemContent.content.genomicCoordinates"
                      :key="gene.chrom"
                      class="omim-loc-coord-dark-gray">
                      {{ gene.chrom }}: {{ gene.start.toLocaleString("en-EN") }} -
                      {{ gene.end.toLocaleString("en-EN") }}
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div
              v-if="
                slot.itemContent.content.snomedctIds ||
                slot.itemContent.content.icd10cmIds ||
                slot.itemContent.content.icd9cmIds
              "
              class="icd-wrapper">
              <span class="icd" @click="showTooltip = !showTooltip">ICD+</span>
              <div class="icd-tooltip icd-tooltip-hidden" :class="{ 'icd-tooltip-show': showTooltip }">
                <div class="triangle-up"></div>
                <span v-if="slot.itemContent.content.snomedctIds">
                  <span class="icd-tooltip-bold">SNOMEDCT: </span>{{ slot.itemContent.content.snomedctIds }}
                  <br />
                </span>
                <span v-if="slot.itemContent.content.icd10cmIds">
                  <span class="icd-tooltip-bold">ICD10CM: </span>{{ slot.itemContent.content.icd10cmIds }}
                  <br />
                </span>
                <span v-if="slot.itemContent.content.icd9cmIds">
                  <span class="icd-tooltip-bold">ICD9CM: </span>{{ slot.itemContent.content.icd9cmIds }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="slot.itemContent.downloaded">
            <div v-if="slot.itemContent.downloaded.relationshipType === OMIM_RELATIONSHIP_TYPES.gene" class="omim-body">
              <h6 id="gene-phenotype-relationships" class="bold-title">Gene-Phenotype Relationships</h6>
              <div class="large-tbl">
                <table>
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Phenotype</th>
                      <th>Phenotype MIM number</th>
                      <th>Inheritance</th>
                      <th>Phenotype mapping key</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in slot.itemContent.downloaded.genemapTable" :key="item">
                      <td>{{ item.computedCytoLocation }}</td>
                      <td>{{ item.phenotypeDescription }}</td>
                      <td>
                        <span
                          class="related-mim-number"
                          @click="slot.itemContent.getRelated(item.associatedPhenotypeMimNumber)">
                          {{ item.associatedPhenotypeMimNumber }}
                        </span>
                      </td>
                      <td>{{ item.phenotypeInheritance }}</td>
                      <td>{{ item.phenotypeMapMethod }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              v-else-if="slot.itemContent.downloaded.relationshipType === OMIM_RELATIONSHIP_TYPES.phenotype"
              class="omim-body">
              <h6 id="phenotype-gene-relationships" class="bold-title">Phenotype-Gene Relationships</h6>
              <div class="large-tbl">
                <table>
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Phenotype</th>
                      <th>Phenotype MIM number</th>
                      <th>Inheritance</th>
                      <th>Phenotype mapping key</th>
                      <th>Gene/Locus</th>
                      <th>Gene/Locus MIM number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in slot.itemContent.downloaded.genemapTable" :key="item">
                      <td>{{ item.computedCytoLocation }}</td>
                      <td>{{ item.phenotypeDescription }}</td>
                      <td>
                        <span
                          class="related-mim-number"
                          @click="slot.itemContent.getRelated(item.associatedPhenotypeMimNumber)">
                          {{ item.associatedPhenotypeMimNumber }}
                        </span>
                      </td>
                      <td>{{ item.phenotypeInheritance }}</td>
                      <td>{{ item.phenotypeMapMethod }}</td>
                      <td v-if="item.approvedGeneSymbols">{{ item.approvedGeneSymbols }}</td>
                      <td v-else>{{ item.geneSymbols }}</td>
                      <td>
                        <span class="related-mim-number" @click="slot.itemContent.getRelated(item.mimNumber)">{{
                          item.mimNumber
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="phenotypic-synopsis-wrapper">
              <div class="phenotypic-synopsis-header">
                <div
                  v-if="slot.itemContent.downloaded.clinicalSynopsis"
                  id="clinical-synopsis"
                  class="clinical-synopsis-wrapper">
                  <span class="clinical-synopsis-information" @click="openDropDownContainer('synopsis')">
                    Clinical Synopsis
                    <img
                      src="@/assets/images/check-mark.png"
                      alt="check-mark"
                      :class="{ 'upside-down': currentDropdownInformation === 'synopsis' }" />
                  </span>
                </div>
                <div
                  v-if="slot.itemContent.downloaded.phenotypicSeriesTable.length"
                  id="phenotypic-series"
                  class="phenotypic-series-wrapper">
                  <span class="phenotypic-series-information" @click="openDropDownContainer('phenotypic')">
                    Phenotypic Series
                    <img
                      src="@/assets/images/check-mark.png"
                      alt="check-mark"
                      :class="{ 'upside-down': currentDropdownInformation === 'phenotypic' }" />
                  </span>
                </div>
              </div>

              <div v-if="currentDropdownInformation === 'synopsis'">
                <div class="card-body">
                  <InteractiveSynopsis
                    :synopsis="slot.itemContent.downloaded.clinicalSynopsis"
                    :original="true"></InteractiveSynopsis>
                </div>
              </div>

              <div v-if="currentDropdownInformation === 'phenotypic'">
                <div
                  v-for="element in slot.itemContent.downloaded.phenotypicSeriesTable"
                  :key="element"
                  class="card-body">
                  <div class="phenotype-subtitle">
                    <span>{{ element.title }}</span>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Phenotype</th>
                        <th>Inheritance</th>
                        <th>Phenotype mapping key</th>
                        <th>Phenotype MIM number</th>
                        <th>Gene/Locus</th>
                        <th>
                          Gene/Locus <br />
                          MIM Number
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="element.entries.length">
                      <tr v-for="item in element.entries" :key="item">
                        <td>{{ item.cytoLocation }}</td>
                        <td>{{ item.phenotypeDescription }}</td>
                        <td>{{ item.phenotypeInheritance }}</td>
                        <td>{{ item.phenotypeMappingKey }}</td>
                        <td>
                          <span
                            class="related-mim-number"
                            @click="slot.itemContent.getRelated(item.phenotypeMimNumber)"
                            >{{ item.phenotypeMimNumber }}</span
                          >
                        </td>
                        <td>{{ item.geneSymbols }}</td>
                        <td>
                          <span
                            class="related-mim-number"
                            @click="slot.itemContent.getRelated(item.geneLocusMimNumber)"
                            >{{ item.geneLocusMimNumber }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="paper-text-block">
              <!-- <span ref="firstTourStepOmim">{{ showPaperTour() }}</span> -->
              <InteractiveSectionList
                :nodes="slot.itemContent.downloaded.parsedPaperData.textSections"></InteractiveSectionList>
              <div
                v-if="slot.itemContent.downloaded.allelicVariants"
                id="allelic-variants"
                class="allelic-variants-wrapper">
                <span class="allelic-variants-header" @click="showAllelicVariants = !showAllelicVariants">
                  Allelic Variants
                  <img
                    src="@/assets/images/check-mark.png"
                    alt="check-mark"
                    :class="{ 'upside-down': showAllelicVariants }" />
                </span>
                <div v-if="showAllelicVariants" id="collapse-allelic-variants-block">
                  <div class="card-body">
                    <InteractiveSectionList
                      :nodes="slot.itemContent.downloaded.allelicVariants.parsedPaperData.textSections">
                    </InteractiveSectionList>
                  </div>
                </div>
              </div>

              <div id="references" class="references-wrapper">
                <h4>References</h4>
                <div v-for="(value, key) in slot.itemContent.downloaded.references" :key="key" class="references">
                  <div class="references-number">{{ key + 1 }}.</div>
                  <div class="references-main-block">
                    <p>
                      <span> {{ value.reference.authors }}&nbsp;</span>

                      <span class="reference-title"> {{ value.reference.title }}&nbsp;</span>

                      <span> {{ value.reference.source }} </span>

                      <span v-if="value.reference.pubmedID">
                        [PubMed:
                        <a class="pubmed-link" @click="loadPubMedRelatedPapers(value.reference.pubmedID)">
                          {{ value.reference.pubmedID }}</a
                        >]
                      </span>

                      <span v-if="value.reference.doi">
                        [<a target="_blank" :href="`https://doi.org/${value.reference.doi}`">Full Text</a>]
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <span v-if="slot.itemContent.downloaded.contributors">
                <div id="contributors">
                  <h4>Contributors</h4>

                  {{ slot.itemContent.downloaded.contributors.substr(0, 40) }}...
                  <span
                    v-if="slot.itemContent.downloaded.contributors"
                    class="contributors"
                    data-bs-toggle="collapse"
                    href="#collapse-contributors"
                    role="button"
                    aria-expanded="false"
                    aria-controls="#collapse-contributors">
                    See all contributors
                    <img src="@/assets/images/check-mark.png" alt="check-mark" />
                  </span>
                  <div class="row">
                    <div class="col">
                      <div id="collapse-contributors" class="collapse">
                        <div class="card-body">
                          {{ slot.itemContent.downloaded.contributors }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              <div id="creation-date" class="creation-date">
                <h4>Creation Date</h4>
                <div>
                  <p>{{ slot.itemContent.downloaded.creationDate }}</p>
                </div>
              </div>
              <span v-if="slot.itemContent.downloaded.editHistory">
                <div id="edit-history">
                  <h4>Edit History</h4>
                  {{ slot.itemContent.downloaded.editHistory.substr(0, 40) }}...
                  <span
                    v-if="slot.itemContent.downloaded.editHistory"
                    class="edit-history"
                    data-bs-toggle="collapse"
                    href="#collapse-edit-history"
                    role="button"
                    aria-expanded="false"
                    aria-controls="#collapse-edit-history">
                    See full edit history
                    <img src="@/assets/images/check-mark.png" alt="check-mark" />
                  </span>
                  <div class="row">
                    <div class="col">
                      <div id="collapse-edit-history" class="collapse">
                        <div class="card-body">
                          {{ slot.itemContent.downloaded.editHistory }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GeneticSearchResults>
</template>

<script setup>
import { ref, inject } from "vue";
import GeneticSearchResults from "../../geneticSearchResults/GeneticSearchResults";
import PaperSideMenu from "@/components/genetic-search/genetic-explorer-tools/PaperSideMenu";
import { OMIM_RELATIONSHIP_TYPES } from "@/utils/constants";
import InteractiveJsonParagraph from "@/components/genetic-search/genetic-explorer-tools/InteractiveJsonParagraph";
import { useInterfaceElementsStore } from "@/stores";
import InteractiveSynopsis from "@/components/genetic-search/genetic-explorer-tools/InteractiveSynopsis";
import InteractiveSectionList from "@/components/genetic-search/genetic-explorer-tools/InteractiveSectionList";
import { usePubMedRelatedPapersStore } from "@/stores";
import { storeToRefs } from "pinia";

const { requestRelatedPubMedPapers } = storeToRefs(usePubMedRelatedPapersStore());

import { convertDateFormat, getOmimTypeLetter, getOmimTypeClass } from "@/utils";
import { geneticExplorerService, analyticsService } from "@/services";

const showTooltip = ref(false);
const userInterfaceElements = useInterfaceElementsStore();
const currentDropdownInformation = ref(null);
const showAllelicVariants = ref(true);

const eventsEmitter = inject("emitter");

function getListFunction(searchTerms, pageIdx) {
  return geneticExplorerService.searchOmim(searchTerms, pageIdx);
}

function getSinglePaper(paperId) {
  currentDropdownInformation.value = null;
  return geneticExplorerService.getOmimDetailed(paperId);
}

function getRelatedPaper(paperId) {
  return geneticExplorerService.getOmimRelated(paperId);
}

function logPaperAction(paper) {
  analyticsService.logGeneticExplorerPaperAction({
    actionType: "paper-selected",
    paperType: "omim",
    paperTitle: paper.title,
    paperId: paper.mimNumber,
  });
}

function titlesPreprocess(titles) {
  return titles.split(";;");
}

function openDropDownContainer(containerName) {
  currentDropdownInformation.value = currentDropdownInformation.value === containerName ? "" : containerName;
}

function loadPubMedRelatedPapers(pmId) {
  requestRelatedPubMedPapers.value = { pmId: [pmId], origin: "OMIM" };
  eventsEmitter.emit("redirect-to-tab", "pubmed");
}
</script>
