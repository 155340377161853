<template>
  <div class="tab-wrapper">
    <span v-if="backToPhrasesButton" class="back-to-phrases-button" @click="backToPhrases">
      <img src="@/assets/images/close-open-menu.svg" alt="open-close" />
      <span> My Phrases</span>
    </span>
    <div class="tab-main-block">
      <div v-if="fetchingResults" class="d-flex text-secondary justify-content-center spinner-container">
        <div class="spinner-border" role="status"></div>
      </div>
      <span v-else-if="foundPapers.length > 0 && !selectedPaper">
        <div>
          <div class="relatedHeader">
            <h3>General Genetics Education Topics</h3>
          </div>
          <div
            v-for="(item, index) in foundPapers"
            :key="item.entry"
            class="search-list-element"
            @click="selectDataItemPaper(item)">
            <div class="element-number">
              <span>
                <span>{{ index + 1 }}:</span>
              </span>
            </div>
            <div class="element-content cursor-pointer">
              <span class="element-header">
                {{ item.title }}
              </span>
              <br />
              <span class="gray-auxiliary-information">
                <span>Date Created: </span>
                <span class="tabs-bold">{{ convertDateFormatForNotes(item.dateCreated) }}</span>
                <span>
                  <span> Date Modified: </span>
                  <span class="tabs-bold">{{ convertDateFormatForNotes(item.dateModified) }}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </span>
      <div v-else-if="selectedPaper" class="tab-paper">
        <span class="back-to-list-button" @click="backToList">
          <img src="@/assets/images/close-open-menu.svg" alt="open-close" />
          <span> Back</span>
        </span>
        <div class="paper-body-wrapper">
          <div class="tab-paper-body">
            <PaperSideMenu :parsed-data="selectedPaper.parsedPaperData.textSections"> </PaperSideMenu>
            <div class="tab-body-right" :class="{ 'shrunk-menu': userInterfaceElements.geneExplorerMenuShrunk }">
              <div class="paper-header">
                <span class="gray-auxiliary-information patient-ed-date-paper">
                  <span>Last Updated · </span>
                  <span class="tabs-bold">{{ convertDateFormatForNotes(selectedPaper.dateModified) }}</span>
                </span>
              </div>

              <div class="loaded-description">
                <div class="paper-text-block">
                  <InteractiveSectionList :nodes="selectedPaper.parsedPaperData.textSections"></InteractiveSectionList>
                </div>
              </div>
            </div>
          </div>

          <div ref="firstStepTour"></div>
        </div>
      </div>
      <span v-else-if="errorMessage" class="not-found"> {{ errorMessage }} </span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, shallowRef, watch } from "vue";
import { geneticExplorerService } from "@/services";
import PaperSideMenu from "@/components/genetic-search/genetic-explorer-tools/PaperSideMenu";
import { useInterfaceElementsStore } from "@/stores";
import { convertDateFormatForNotes } from "@/utils";
import InteractiveSectionList from "@/components/genetic-search/genetic-explorer-tools/InteractiveSectionList";
import { useRouter } from "vue-router";
import { preprocessGeneExplorerPaper } from "@/logic/utils";
import { useShepherd } from "vue-shepherd";

const router = useRouter();

const userInterfaceElements = useInterfaceElementsStore();

const foundPapers = shallowRef([]);
const selectedPaper = shallowRef(null);
const fetchingResults = ref(false);
const errorMessage = ref(null);

const backToPhrasesButton = ref(true);

const firstStepTour = ref(null);

const tour = useShepherd({
  defaultStepOptions: {
    classes: "first-tour-step",

    scrollTo: { behavior: "smooth", block: "center" },
  },
});

onMounted(async () => {
  fetchResults();
});

watch(selectedPaper, (newPaper) => {
  if (newPaper && !userInterfaceElements.userGuideTourInfo.first) {
    tour.addStep({
      id: "first-tour-step",
      title: "Click on sentence or paragraph",
      attachTo: {
        element: firstStepTour.value,
        on: "top",
      },
      buttons: [
        {
          text: "Okay",
          action() {
            tour.cancel();
            userInterfaceElements.setNewGuideTourData("first");
          },
        },
        {
          classes: "close-tour-step-button",
          text: "×",
          action() {
            tour.cancel();
            userInterfaceElements.setNewGuideTourData("first");
          },
        },
      ],
    });

    tour.start();
  }
});

async function fetchResults() {
  fetchingResults.value = true;
  try {
    const searchResults = await geneticExplorerService.fetchPatienEd();
    foundPapers.value = searchResults.pageItems;

    fetchingResults.value = false;
  } catch (error) {
    errorMessage.value = "No record found or something went wrong";
    fetchingResults.value = false;
  }
}

async function selectDataItemPaper(paper) {
  fetchingResults.value = true;
  backToPhrasesButton.value = false;
  const paperInfo = await geneticExplorerService.getPatientEdDetailed(paper.id);

  if (paperInfo.parsedPaperData) preprocessGeneExplorerPaper(paperInfo, "");
  selectedPaper.value = paperInfo;

  foundPapers.value = [];
  fetchingResults.value = false;
}

function backToList() {
  selectedPaper.value = null;
  fetchResults();
  backToPhrasesButton.value = true;
}

function backToPhrases() {
  router.push({ name: "my-phrases" });
}
</script>
