<template>
  <div class="genetic-explorer-search-bar" :class="{ 'border-radius-for-all': !isSearchDropdownVisible }">
    <div class="search-component-wrapper">
      <div class="selected-terms-wrapper">
        <div v-for="(item, index) in currentSearchTerms" :key="item" class="search-term-full-info">
          <search-term-title :selected-item="item" @delete-search-term="deleteTerm(item)"></search-term-title>
          <div class="added-single-item-tooltip">
            <search-term-title :selected-item="item" @delete-search-term="deleteTerm(item)"></search-term-title>
            <span class="search-term-full-info-content">
              <i class="small text-muted" v-html="sanitizeHtml(item.text, searchBarHtmlRules)"></i>
            </span>
          </div>
          <span v-if="index + 1 < currentSearchTerms.length" class="prefix-and">AND</span>
        </div>
      </div>
      <typeahead-input
        :placeholder="placeholder"
        :suggestions-provider="getSearchSuggestions"
        @select-item="addNewTerm">
        <template #list-item-text="slot">
          <span class="item-type" :class="slot.item.entryType">{{ slot.item.entryType.toUpperCase() }}</span>
          <span class="item-title">
            {{ slot.item.title }}
            <i class="small text-muted" v-html="sanitizeHtml(slot.item.text, searchBarHtmlRules)"></i>
          </span>
        </template>
      </typeahead-input>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import TypeaheadInput from "@/components/TypeaheadInput";
import SearchTermTitle from "@/components/genetic-search/search-bar-tools/SearchTermTitle";
import sanitizeHtml from "sanitize-html";
import { searchBarHtmlRules } from "@/utils/htmlSanitizeRules";

import { geneticExplorerService, analyticsService } from "@/services";
import { useInterfaceElementsStore, useGenSearchCriteriaStore } from "@/stores";

const { currentSearchTerms } = storeToRefs(useGenSearchCriteriaStore());
const { isSearchDropdownVisible } = storeToRefs(useInterfaceElementsStore());

const placeholder = "Start by searching for a gene, mutation or phenotype";

async function getSearchSuggestions(enteredText) {
  const suggestions = await geneticExplorerService.getGeneticSearchSuggestions(enteredText);
  // logging search bar suggestions result
  analyticsService.logGeneticExplorerSearchAction({
    actionType: "suggestions-requested",
    numSuggestionsReceived: suggestions.length,
    enteredText,
  });

  suggestions.unshift({
    title: enteredText,
    entryType: "text",
    text: "",
    id: enteredText.toLowerCase(),
  });

  suggestions.forEach((s) => {
    s.uid = s.entryType + "-" + s.id;
  });

  return suggestions;
}

function addNewTerm(item) {
  const currItems = currentSearchTerms.value;
  if (currItems.some((i) => i.uid === item.uid)) {
    return;
  }

  // logging user action
  analyticsService.logGeneticExplorerSearchAction({
    actionType: "term-selected",
    termTitle: item.title,
    termType: item.entryType,
    termId: item.id,
  });

  currentSearchTerms.value = [...currItems, item];
}

function deleteTerm(item) {
  currentSearchTerms.value = currentSearchTerms.value.filter((i) => i != item);
}
</script>

<style lang="sass">
.genetic-explorer-search-bar
  position: absolute
  width: inherit

  .search-component-wrapper
    display: flex
    flex-direction: row
    border: 1px solid $gray-400
    border-radius: 20px
    padding: 2px 4px 4px 10px
    background-color: $white

    .selected-terms-wrapper
      display: flex
      overflow: hidden

      &:hover
        overflow: visible


      .search-term-full-info
        display: inline-flex
        align-items: center
        margin-right: 5px
        position: relative

        &:hover
          z-index: 10
          border-radius: 20px
          cursor: pointer

        &:hover > .added-single-item-tooltip
          display: flex
          flex-direction: column

        .prefix-and
          color: $black
          margin: 0 8px
          font-weight: 500

        .added-single-item-tooltip
          display: none
          cursor: pointer
          border-radius: 10px
          border: 1px solid $gray-400
          box-shadow: 0px 5px 20px rgba(5, 46, 94, 0.1)
          padding: 3px 12px 12px 3px
          font-size: 12px
          color: $gray-700
          min-width: 360px
          background-color: white
          position: absolute
          left: -4px
          top: -3px

          .search-term-full-info-content
            padding: 10px 0 0 5px



  .search-dropdown-block
    width: inherit
    border-radius: 20px
    background: white
    top: 30px
    border-top: none

    .hint
      margin: 10px
      font-size: small

    ul
      padding-left: 12px

      li
        cursor: pointer
        padding: 5px 0

        &:hover
          background-color: $gray

        .item-type
          font-weight: 400
          font-size: 11px
          color: $white
          padding: 4px 10px
          border-radius: 12px

        .item-title
          margin-left: 11px
          color: $black
          font-size: 14px
          font-weight: 400

  .border-radius-for-all
    border-radius: 20px
</style>
