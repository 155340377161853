<template>
  <div class="main-page-wrapper">
    <div class="content-with-input">
      <h1 class="main-page-header">
        SmartCare: OMIM<span class="r-sign">&reg;</span>, GeneReviews<span class="r-sign">&reg;</span> and more
      </h1>
      <span class="description">How busy genetic providers document and collaborate </span>
      <div class="main-search-bar">
        <GeneticSearchBar />
      </div>
    </div>
  </div>
</template>

<script setup>
import GeneticSearchBar from "@/components/genetic-search/search-bar-tools/GeneticSearchBar";

import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useGenSearchCriteriaStore } from "@/stores";
import { watch } from "vue";

const { currentSearchTerms } = storeToRefs(useGenSearchCriteriaStore());
const router = useRouter();

watch(currentSearchTerms, (newVal) => {
  if (newVal.length > 0) router.push({ name: "smart-phrase" });
});
</script>

<style lang="sass" scoped>
.main-page-wrapper
  height: 100%
  background-color: $gray-100
  padding-top: 10%

  .content-with-input
    width: inherit
    display: flex
    flex-direction: column
    align-items: center
    color: $blue-700

    .main-page-header
      font-size: 56px
      font-family: 'Cambon'

      @media (max-width: 1500px)
        font-size: 50px

      .r-sign
        vertical-align: 18px
        font-size: 25px

    .description
      font-size: 34px
      margin-bottom: 25px

      @media (max-width: 1500px)
        font-size: 28px

    .main-search-bar
      width: 40%
</style>
