<template>
  <div>
    <div class="relatedHeader">
      <h3 v-if="props.dataListHeader">{{ props.dataListHeader }}</h3>
    </div>

    <div
      v-for="(item, index) in props.dataList"
      :key="item.entry"
      class="search-list-element"
      @click="
        {
          props.currentTab === 'clinicalTrials' || props.currentTab === 'clinVar'
            ? null
            : props.currentTab === 'pubMed'
            ? chooseSingleDataItem(item, false)
            : chooseSingleDataItem(item.entry);
        }
      ">
      <div class="element-number">
        <span>
          <span>{{ props.pagingOffset + index + 1 }}:</span>
        </span>
      </div>
      <slot name="list-view" :item-info="{ item: item, index: index }"></slot>
    </div>
    <hr v-if="props.dataListHeader" />
  </div>
</template>

<script setup>
const props = defineProps({
  dataList: {
    type: Object,
    required: true,
  },
  pagingOffset: {
    type: Number,
    default: 0,
  },
  currentTab: {
    type: String,
    required: true,
  },
  dataListHeader: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["chooseSingleDataItem"]);
const chooseSingleDataItem = (element) => emit("chooseSingleDataItem", element);
</script>
