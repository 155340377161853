<template>
  <div class="home-page">
    <div class="home-page-header">
      <div ref="secondTourStep" class="left-header">
        <router-link to="/" class="start-new-note"> Create New Phrase </router-link>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table table-bordered" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th class="note-title">MY PHRASES</th>
            <th>DATE CREATED</th>
            <th>DATE MODIFIED</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody v-if="preprocessNotesArray" class="first-table-child">
          <tr
            v-for="note in preprocessNotesArray"
            :key="note.id"
            class="first-table-child"
            @click="editSingleNote(note)">
            <td class="first-td">{{ note.title }}</td>
            <td>{{ convertDateFormatForNotes(note.dateCreated) }}</td>
            <td>{{ convertDateFormatForNotes(note.dateModified) }}</td>
            <td>
              <span :class="note.noteStatus">{{ note.noteStatus }}</span>
            </td>
            <div class="duplicate-delete-container">
              <span class="duplicate" @click.stop="duplicateNote(note.noteStatus, note.id)">
                <img src="@/assets/images/duplicate.svg" alt="duplicate" /> Duplicate
              </span>

              <div v-if="deleteInProcess" class="spinner-border spinner-border-sm" role="status"></div>
              <span
                v-else
                class="delete"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                @click.stop="askDeleteConfirmation(note.title, note.noteStatus, note.id)">
                <img src="@/assets/images/trash.svg" alt="trash" /> Delete
              </span>
            </div>
          </tr>
        </tbody>
      </table>
      <div v-if="!preprocessNotesArray" class="d-flex text-secondary justify-content-center spinner-container">
        <div class="spinner-border" role="status"></div>
      </div>
    </div>
    <div
      id="staticBackdrop"
      class="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="staticBackdropLabel" class="modal-title">Delete Confirmation</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure that you want to delete smart phrase <span>"{{ noteOnDeletion.noteTitle }}"</span>?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="deleteSingleNote(noteOnDeletion.noteStatus, noteOnDeletion.id)">
              Delete
            </button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, shallowRef, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { convertDateFormatForNotes } from "@/utils";
import { tryParseApiErrors } from "@/utils";
import { notesService } from "@/services";
import { useInterfaceElementsStore, usePhraseSearchCriteriaStore, useClinicalNoteEditorStore } from "@/stores";

import { useShepherd } from "vue-shepherd";
import { storeToRefs } from "pinia";

const router = useRouter();
const errors = ref([]);
const noteOnDeletion = ref({});
const allUserNotes = ref(null);
const { noteSaveProcess } = storeToRefs(useClinicalNoteEditorStore());

const preprocessNotesArray = shallowRef(null);
const deleteInProcess = ref(false);
const userInterfaceElements = useInterfaceElementsStore();

const { searchTermForPhrases } = storeToRefs(usePhraseSearchCriteriaStore());

const secondTourStep = ref(null);
const tour = useShepherd({
  defaultStepOptions: {
    classes: "second-tour-step",

    scrollTo: { behavior: "smooth", block: "center" },
  },
});

onMounted(() => {
  searchTermForPhrases.value = null;

  // End the process of saving a note before getting list of all notes
  if (!noteSaveProcess.value) {
    getAllNotes();
  } else {
    watch(noteSaveProcess, (newVal) => {
      if (!newVal) {
        getAllNotes();
      }
    });
  }

  if (!userInterfaceElements.userGuideTourInfo.second) {
    tour.addSteps([
      {
        id: "second-tour-step",
        title: "Start new phrase",
        attachTo: {
          element: secondTourStep.value,
          on: "top",
        },
        buttons: [
          {
            text: "Okay",
            action() {
              tour.cancel();
              userInterfaceElements.setNewGuideTourData("second");
            },
          },
          {
            classes: "close-tour-step-button",
            text: "×",
            action() {
              tour.cancel();
              userInterfaceElements.setNewGuideTourData("second");
            },
          },
        ],
      },
    ]);

    tour.start();
  }
});

async function getAllNotes() {
  allUserNotes.value = await notesService.getListOfNotes();

  preprocessNotesArray.value = preprocessArrayOfNotes(allUserNotes.value);
}

watch(searchTermForPhrases, async (newPhrase) => {
  const lowerCasePhrase = newPhrase.toLowerCase();
  if (lowerCasePhrase) {
    const arrayPhraseWithLowerCase = preprocessArrayOfNotes(allUserNotes.value);
    arrayPhraseWithLowerCase.forEach((i) => {
      i.title = i.title.toLowerCase();
      i.content = i.content.toLowerCase();
    });

    preprocessNotesArray.value = arrayPhraseWithLowerCase.filter(
      (i) => i.title.includes(lowerCasePhrase) || i.content.includes(lowerCasePhrase)
    );
  } else {
    preprocessNotesArray.value = preprocessArrayOfNotes(allUserNotes.value);
  }
});

function preprocessArrayOfNotes(notes) {
  const notesStatus = Object.keys(notes);

  let finalNotesArray = [];
  notesStatus.forEach((item, index) => {
    finalNotesArray[index] = notes[item].map((element) => ({ noteStatus: item, ...element }));
  });

  finalNotesArray = finalNotesArray[0].concat(finalNotesArray[1]);

  finalNotesArray.sort((newest, latest) => {
    return latest.dateModified.localeCompare(newest.dateModified);
  });

  return finalNotesArray;
}

async function duplicateNote(status, noteId) {
  try {
    if (status === "drafts") {
      const copiedNoteFromDraft = await notesService.createFromDraft(noteId);
      editSingleNote(copiedNoteFromDraft);
    } else {
      const copiedNoteFromCompleted = await notesService.createFromCompleted(noteId);
      editSingleNote(copiedNoteFromCompleted);
    }
    errors.value = [];
  } catch (error) {
    displayError(error);
  }
}

async function editSingleNote(note) {
  // Only completed clinical note has clinicalNoteId field
  const noteId = note.clinicalNoteId || note.id;
  router.push({ name: "smart-phrase", params: { noteId } });
}

function askDeleteConfirmation(title, status, noteId) {
  noteOnDeletion.value = { noteTitle: title, noteStatus: status, id: noteId };
}

async function deleteSingleNote(status, noteId) {
  try {
    deleteInProcess.value = true;

    if (status === "drafts") {
      await notesService.deleteDraftNote(noteId);
    } else {
      await notesService.deleteCompletedNote(noteId);
    }

    getAllNotes();
    deleteInProcess.value = false;
    errors.value = [];
  } catch (error) {
    displayError(error);
  }
}

function displayError(err) {
  // This result does not show anywhere for now, maybe it will be a pop-up on this page
  errors.value = tryParseApiErrors(err);
}
</script>

<style lang="sass" scoped>
@import "@/styles/components/_shepherd.scss"
@import "@/styles/components/_modal-window.scss"

@mixin button-shadow
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), -5px 0px 16px rgba(0, 0, 0, 0.08)

.home-page
  height: 100%
  background-color: $white
  padding-bottom: 20px

  .home-page-header
    display: flex
    justify-content: space-between
    padding: 28px 12px 28px 52px

    .left-header
      min-width: 150px
      display: flex

      a
        text-decoration: none
      .recently-added
        padding: 8px 15px
        border: 1px solid $gray-400
        border-radius: 6px
        background-color: $white

        &:hover
          @include button-shadow

      .button-divider
        width: 1px
        background: $gray-400
        margin: 0px 20px 0 20px

      .start-new-note
        color: $white
        font-weight: 600
        padding: 12px 13px
        border-radius: 8px
        background-color: $blue
        border: 1px solid $blue

        &:hover
          @include button-shadow

  .table-wrapper

    table
      background-color: $white
      bordare-collapse: collapse
      position: relative

      thead
        background-color: $gray-350
        border: 1px solid $gray-400
        border-left: none
        border-right: none
        color: $gray-900
        font-weight: 600

        tr

          th
            padding: 20px 0
            border: none

          .note-title
            padding-left: 60px

      tbody
        border-top: none
        padding: 8px 0 8px 20px

        tr
          cursor: pointer

          &:hover
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08)

          &:hover > .duplicate-delete-container
            display: flex
            justify-content: space-between
            color: $gray-900
            background-color: $white
            padding: 27px 30px
            @include button-shadow
            position: absolute
            right: 0

            .duplicate
              margin-right: 27px
          .duplicate-delete-container
            display: none

            .spinner-border-sm
              margin-top: 5px
            .duplicate:hover, .delete:hover
              font-weight: 500

          td
            border: none
            font-weight: 400
            text-align: left
            padding: 29px 0
            color: $black
            font-size: 14px
            font-weight: 400

            .drafts,
            .completed
              padding: 4px 7px
              border-radius: 4px

            .drafts
              background-color: $yellow-200
              color: $yellow-500

            .completed
              background-color: $green-100
              color: $green-500

          .first-td
            padding-left: 60px
            width: 45%
    .spinner-container
      margin-top: 15%

  .pagination-wrapper
    padding-right: 50px
</style>
