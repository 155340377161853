<template>
  <GuestUserLayout>
    <div class="col right-sign-block">
      <div class="form-wraper">
        <div class="p-5">
          <div class="text-start">
            <h1 class="h4 text-gray-900 mb-4">Password Reset</h1>
          </div>
          <div v-if="successPasswordChange">
            <div class="text-start text-success mb-5">
              <p>
                <i class="fa fa-circle-xmark fa-4x"></i>
              </p>
              <h5>Password reset successfully</h5>
              <br />
              <router-link :to="{ name: 'login' }" class="small text-underline-hover">
                <button type="submit" class="btn btn-primary btn-user">Login</button>
              </router-link>
            </div>
            <p class="mb-4">Check your emails for next steps.</p>
          </div>
          <div v-else class="user-form">
            <FormKit type="form" :actions="false" :errors="errors" @submit="updatePassword">
              <FormKit
                v-model="password"
                type="password"
                name="password"
                placeholder="Enter new password"
                :validation="INPUT_REQUIREMENTS.PASSWORD_REQUIRMENTS"
                :validation-messages="{
                  matches:
                    'Passwords must have at least: one symbol, one number, ' +
                    'one lowercase and one uppercase letter.',
                }" />
              <FormKit
                type="password"
                name="password_confirm"
                validation-label="Password confirmation"
                validation="required|confirm"
                placeholder="Repeat Password" />
              <div class="text-start">
                <router-link :to="{ name: 'login' }" class="small text-underline-hover">
                  Already have an account? Login!
                </router-link>
              </div>
              <div class="text-start">
                <router-link :to="{ name: 'register' }" class="small text-underline-hover">
                  Create account
                </router-link>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="submit" class="btn btn-primary btn-user">Create New Password</button>
              </div>
            </FormKit>
          </div>
        </div>
      </div>
    </div>
  </GuestUserLayout>
</template>

<script setup>
import { ref } from "vue";
import { INPUT_REQUIREMENTS } from "@/utils/constants";
import { useRoute } from "vue-router";
import GuestUserLayout from "@/layouts/GuestUser.vue";
import authService from "@/services/authService";
import { tryParseApiErrors } from "@/utils";

const route = useRoute();

const password = ref("");
const successPasswordChange = ref(false);
const errors = ref([]);
const failure = ref(false);

const updatePassword = async () => {
  try {
    await authService.passwordReset(password.value, route.params.token);
    successPasswordChange.value = true;
  } catch (error) {
    errors.value = tryParseApiErrors(error);
    failure.value = true;
  }
};
</script>

<style lang="sass" scoped>
.bg-password-recowery-image
  background-position: 35% 35%
</style>
