import { unref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useClinicalNoteEditorStore } from "@/stores";
import { analyticsService } from "@/services";

function logParagraphAction(actionInfo, clinicalNote) {
  analyticsService.logGeneticExplorerParagraphAction({ ...actionInfo, clinicalNote });
}

function addPositiveInteraction(interactionInfo) {
  const { clinicalNoteId } = storeToRefs(useClinicalNoteEditorStore());
  if (clinicalNoteId.value) {
    // save events immediately if there is a note id
    logParagraphAction(interactionInfo, clinicalNoteId.value);
  } else {
    // wait for clinical note saving to complete and then save the interaction
    let destroyWatcher = watch(clinicalNoteId, (newId) => {
      if (newId) {
        logParagraphAction(interactionInfo, newId);
      }

      destroyWatcher();
    });
  }
}

function addNegativeInteraction(interactionInfo) {
  const { clinicalNoteId } = unref(useClinicalNoteEditorStore());
  // save view events only if there is a note being edited
  if (clinicalNoteId) {
    logParagraphAction(interactionInfo, clinicalNoteId);
  }
}

function getActionInfo(parInfo, action, sentenceId) {
  return {
    actionType: action,
    paragraph: parInfo.id,
    sentenceHash: sentenceId,
  };
}

export default {
  paragraphUsed(parInfo) {
    addPositiveInteraction(getActionInfo(parInfo, "paragraph-copied"));
  },
  paragraphSentUsed(parInfo, sentenceId) {
    addPositiveInteraction(getActionInfo(parInfo, "sent-copied", sentenceId));
  },
  paragraphViewed(parInfo) {
    addNegativeInteraction(getActionInfo(parInfo, "paragraph-viewed"));
  },
  paragraphTextSelected() {
    throw "not implemented yet";
  },
};
