<template>
  <div class="genetic-explorer-search-bar">
    <div class="phrases-search-input">
      <div class="input-wrapper">
        <input
          id="phrasesSearchInput"
          v-model="searchTermForPhrases"
          type="text"
          placeholder="Start typing phrase title or content" />
        <label for="phrasesSearchInput">
          <span><img src="@/assets/images/search-glass.svg" alt="search" /></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { usePhraseSearchCriteriaStore } from "@/stores";

const { searchTermForPhrases } = storeToRefs(usePhraseSearchCriteriaStore());
</script>

<style lang="sass" scoped>
.genetic-explorer-search-bar
  display: flex
  flex-direction: row
  border: 1px solid $gray-400
  border-radius: 20px 20px 20px 20px
  padding: 2px 4px 4px 10px

  .phrases-search-input
    display: flex
    width: inherit
    flex-grow: 1
    flex-direction: column
    justify-content: space-between

    .input-wrapper
      display: flex

      input
        color: $gray-700
        font-size: 14px
        width: 100%
        margin-left: 10px
        outline: none
        border: none
        padding-left: 5px

      img
        cursor: pointer
</style>
