<template>
  <GuestUserLayout>
    <div class="col right-sign-block">
      <div class="form-wraper">
        <div v-if="!secondVerificationStep" class="p-5">
          <div class="text-start">
            <h1 class="h4 mb-4">Welcome to Lamar Health!</h1>
            <p>Let's get you logged in.</p>
          </div>
          <div class="user-form">
            <FormKit
              v-slot="{ state: { loading } }"
              v-model="credentials"
              type="form"
              :actions="false"
              :errors="errors"
              @submit="loginUser">
              <FormKit type="text" label="Email" name="email" placeholder="Email Address" validation="required|email" />
              <FormKit type="password" label="Password" name="password" placeholder="Password" validation="required" />
              <div class="text-start">
                <router-link :to="{ name: 'password-reset-request' }" class="small text-underline-hover">
                  Forgot password?
                </router-link>
              </div>
              <div class="text-start">
                <router-link :to="{ name: 'register' }" class="small text-underline-hover">Create account</router-link>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="submit" class="btn btn-primary btn-user">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span> Login
                </button>
              </div>
            </FormKit>
          </div>
        </div>

        <div v-else class="p-5">
          <div class="text-start">
            <h1 class="h4 text-gray-900 mb-4">2-step verification</h1>
          </div>
          <div class="user-form">
            <FormKit
              v-slot="{ state: { loading } }"
              v-model="credentials"
              type="form"
              :actions="false"
              :errors="errors"
              @submit="authStepUser">
              <FormKit
                v-model="code"
                type="number"
                name="code"
                placeholder="Type authentication code"
                :validation="INPUT_REQUIREMENTS.GOOGLE_AUTHENTICATE_REQUIREMENTS" />
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="submit" class="btn btn-primary btn-user">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span> Verify
                </button>
              </div>
            </FormKit>
            <hr />
            <div id="accordionFlushExample" class="accordion accordion-flush">
              <div class="accordion-item">
                <h2 id="flush-headingOne" class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne">
                    Don't have Authenticator app set up? Click here
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <span>Step 1: Download Google Authenticator App on your smartphone </span>
                    <br />
                    <a :href="AUXILIARY_URLS.GOOGLE_AUTHENTICATOR_FOR_ANDROID">Google Authenticator for Android</a>
                    <br />
                    <a :href="AUXILIARY_URLS.GOOGLE_AUTHENTICATOR_FOR_IOS">Google Authenticator for IOS</a>
                    <br />
                    <br />
                    <span>
                      Step 2: In the Google Authenticator App choose to add an accound and scan the QR bellow.
                    </span>
                    <br />
                    <qrcode-vue v-model="qrCode" :value="qrCode" level="H" />
                    <br />
                    <div class="setup-key-wrapper">
                      <span
                        class="setup-key-block"
                        data-bs-toggle="collapse"
                        href="#collapse-setup-key-block"
                        role="button"
                        aria-expanded="false"
                        aria-controls="#collapse-setup-key-block">
                        Can't scan QR? <img src="@/assets/images/check-mark.png" alt="check-mark" />
                      </span>
                      <div class="row">
                        <div class="col">
                          <div id="collapse-setup-key-block" class="collapse multi-collapse">
                            <span>
                              For manual setup click <img src="@/assets/images/copy-img.svg" alt="copy" /> to copy the
                              code below. <br />
                              In your Google Authenticator select option to enter Setup Key and paste the 32-digit key.
                            </span>
                            <br />
                            <div>
                              <input v-model="setupKey" type="text" size="38" class="setup-key" />
                              <button class="btn copy-setup-key" @click="copyToClipboard">
                                <img src="@/assets/images/copy-img.svg" alt="copy" />
                              </button>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span>
                      Step 3: Once the app is configured, please enter 6-digit verification code from Google
                      Authenticator in the field above.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GuestUserLayout>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { AUXILIARY_URLS, INPUT_REQUIREMENTS } from "@/utils/constants";
import { useAuthStore } from "@/stores";
import GuestUserLayout from "@/layouts/GuestUser.vue";
import QrcodeVue from "qrcode.vue";
import { tryParseApiErrors } from "@/utils";

import useClipboard from "vue-clipboard3";

const errors = ref([]);
const credentials = ref({});
const router = useRouter();
const authStore = useAuthStore();
const code = ref("");
const qrCode = ref("");
const setupKey = ref("");
const secondVerificationStep = ref(false);

const { toClipboard } = useClipboard();

const loginUser = async () => {
  try {
    let otpConfig = await authStore.login(credentials.value);
    if (otpConfig.secondStep) {
      secondVerificationStep.value = true;
      qrCode.value = otpConfig.otpAppUrl;
      setupKey.value = otpConfig.setupKey;
    } else {
      await authStore.loginStep2NotRequired();
      router.push({ name: "home" });
    }
    errors.value = [];
  } catch (error) {
    errors.value = tryParseApiErrors(error);
  }
};
const authStepUser = async () => {
  try {
    await authStore.loginStep2(code.value);
    router.push({ name: "home" });
  } catch (error) {
    errors.value = tryParseApiErrors(error);
  }
};

const copyToClipboard = async () => {
  try {
    await toClipboard(setupKey.value);
  } catch (error) {
    errors.value = tryParseApiErrors(error);
  }
};
</script>

<style lang="sass" scoped>
.bg-login-image
  background-size: cover

.setup-key-wrapper
  margin: 0

.setup-key-block
  color: $blue
  font-weight: 500

.setup-key
  border: none
  color: $green
  font-weight: 400

.copy-setup-key
  font-size: 15px
  border-radius: 6px
  font-weight: 500
  padding: 4px
  border: 1.5px solid $blue-700
  color: $blue-700
  background-color: $white
</style>
