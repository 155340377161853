<template>
  <GeneticSearchResults :get-list-function="getListFunction" :current-tab="'clinicalTrials'">
    <template #list-view="slot">
      <div class="element-content">
        <div v-if="slot.itemInfo.item.entry.briefTitle" class="element-header">
          <a target="_blank" :href="'https://clinicaltrials.gov/ct2/show/' + slot.itemInfo.item.entry.nctId">
            {{ slot.itemInfo.item.entry.briefTitle }}<i class="fa fa-external-link ms-1"></i>
          </a>
        </div>
        <div v-if="slot.itemInfo.item.entry.overallStatus" class="list-info green-auxiliary-information">
          <span>Status: </span>
          <span>{{ slot.itemInfo.item.entry.overallStatus }}</span>
        </div>

        <div class="conditions-interventions-wrapper">
          <div v-if="slot.itemInfo.item.entry.conditions.length" class="trials-info">
            <ul v-if="slot.itemInfo.item.entry.conditions.length < 6" class="info-list">
              <span class="gray-auxiliary-information">Conditions: </span>
              <li
                v-for="condition in slot.itemInfo.item.entry.conditions"
                :key="condition"
                class="clinical-trials-bold">
                {{ condition }}
              </li>
            </ul>
            <span v-else>
              <span
                class="conditions-long"
                data-bs-toggle="collapse"
                :href="`#collapse-conditions-long-block-${slot.itemInfo.index + 1}`"
                role="button"
                aria-expanded="false"
                :aria-controls="`#collapse-conditions-long-block-${slot.itemInfo.index + 1}`">
                Conditions
                <img src="@/assets/images/check-mark.png" alt="check-mark" />
              </span>
              <div class="row">
                <div class="col">
                  <div
                    :id="`collapse-conditions-long-block-${slot.itemInfo.index + 1}`"
                    class="collapse multi-collapse">
                    <div>
                      <ul class="info-list">
                        <li
                          v-for="condition in slot.itemInfo.item.entry.conditions"
                          :key="condition"
                          class="clinical-trials-bold">
                          {{ condition }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div v-if="slot.itemInfo.item.entry.interventions.length" class="trials-info">
            <ul v-if="slot.itemInfo.item.entry.interventions.length < 6" class="info-list">
              <span class="gray-auxiliary-information">Interventions: </span>
              <li
                v-for="intervention in slot.itemInfo.item.entry.interventions"
                :key="intervention"
                class="clinical-trials-bold">
                {{ intervention }}
              </li>
            </ul>
            <span v-else>
              <span
                class="interventions-long small-header"
                data-bs-toggle="collapse"
                :href="`#collapse-interventions-long-block-${slot.itemInfo.index + 1}`"
                role="button"
                aria-expanded="false"
                :aria-controls="`#collapse-interventions-long-block-${slot.itemInfo.index + 1}`">
                Interventions
                <img src="@/assets/images/check-mark.png" alt="check-mark" />
              </span>
              <div class="row">
                <div class="col">
                  <div
                    :id="`collapse-interventions-long-block-${slot.itemInfo.index + 1}`"
                    class="collapse multi-collapse">
                    <div>
                      <ul class="info-list">
                        <li
                          v-for="intervention in slot.itemInfo.item.entry.interventions"
                          :key="intervention"
                          class="clinical-trials-bold">
                          {{ intervention }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div v-if="slot.itemInfo.item.entry.eligibility">
          <span
            v-if="
              slot.itemInfo.item.entry.eligibility && slot.itemInfo.item.entry.eligibility.inclusionCriteria.length
            ">
            <span
              class="inclusion-information gray-auxiliary-information"
              data-bs-toggle="collapse"
              :href="`#collapse-inclusion-criteria-block-${slot.itemInfo.index + 1}`"
              role="button"
              aria-expanded="false"
              :aria-controls="`#collapse-inclusion-criteria-block-${slot.itemInfo.index + 1}`">
              Inclusion criteria
              <img src="@/assets/images/check-mark.png" alt="check-mark" />
            </span>
            <div class="row">
              <div class="col">
                <div
                  :id="`collapse-inclusion-criteria-block-${slot.itemInfo.index + 1}`"
                  class="collapse multi-collapse">
                  <div>
                    <ul class="info-list">
                      <li
                        v-for="inclusion in slot.itemInfo.item.entry.eligibility.inclusionCriteria"
                        :key="inclusion"
                        class="clinical-trials-bold">
                        {{ inclusion }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <span
            v-if="
              slot.itemInfo.item.entry.eligibility && slot.itemInfo.item.entry.eligibility.exclusionCriteria.length
            ">
            <span
              class="exclusion-information gray-auxiliary-information"
              data-bs-toggle="collapse"
              :href="`#collapse-exclusion-criteria-block-${slot.itemInfo.index + 1}`"
              role="button"
              aria-expanded="false"
              :aria-controls="`#collapse-exclusion-criteria-block-${slot.itemInfo.index + 1}`">
              Exclusion criteria
              <img src="@/assets/images/check-mark.png" alt="check-mark" />
            </span>
            <div class="row">
              <div class="col">
                <div
                  :id="`collapse-exclusion-criteria-block-${slot.itemInfo.index + 1}`"
                  class="collapse multi-collapse">
                  <div>
                    <ul class="info-list">
                      <li
                        v-for="exclusion in slot.itemInfo.item.entry.eligibility.exclusionCriteria"
                        :key="exclusion"
                        class="clinical-trials-bold">
                        {{ exclusion }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>

        <div v-if="slot.itemInfo.item.entry.locations.length" class="clinical-location-wrapper">
          <span
            class="location-information gray-auxiliary-information"
            data-bs-toggle="collapse"
            :href="`#collapse-locations-criteria-block-${slot.itemInfo.index + 1}`"
            role="button"
            aria-expanded="false"
            :aria-controls="`#collapse-locations-criteria-block-${slot.itemInfo.index + 1}`">
            Locations
            <img src="@/assets/images/check-mark.png" alt="check-mark" />
          </span>
          <div class="row">
            <div class="col">
              <div :id="`collapse-locations-criteria-block-${slot.itemInfo.index + 1}`" class="collapse multi-collapse">
                <div>
                  <ul class="info-list">
                    <li v-for="locationObj in slot.itemInfo.item.entry.locations" :key="locationObj">
                      <div v-if="locationObj.facility" class="clinical-trials-facility-status">
                        <div class="clinical-trials-facility">
                          {{ locationObj.facility }}
                        </div>
                        <div class="clinical-trials-location-status">
                          {{ locationObj.locationStatus }}
                        </div>
                      </div>
                      <span v-if="locationObj.location" class="clinical-trials-bold">
                        {{ locationObj.location }}
                        <br />
                      </span>
                      <span v-if="locationObj.contact" class="clinical-trials-bold">
                        {{ locationObj.contact }}
                        <br />
                      </span>
                      <span v-if="locationObj.principalInvestigator" class="clinical-trials-bold">
                        {{ locationObj.principalInvestigator }}
                        <br />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-list-container">
        <span class="right-list-container-save-button" @click="exportCitation(slot.itemInfo.item.entry, false)">
          Summarize & Cite
        </span>
      </div>
    </template>
  </GeneticSearchResults>
</template>

<script setup>
import { ref, inject } from "vue";

import GeneticSearchResults from "../../geneticSearchResults/GeneticSearchResults";
import { geneticExplorerService } from "@/services";

const citation = ref("");
const eventsEmitter = inject("emitter");

function getListFunction(searchTerms, pageIdx) {
  return geneticExplorerService.searchClinicalTrials(searchTerms, pageIdx);
}

function exportCitation(clinicalTrialsEntry, isSentence) {
  citation.value = `<p>Clinical trial <em>${
    clinicalTrialsEntry.briefTitle
  }</em> is currently recruiting participants.<br />
    Conditions considered: <em>${clinicalTrialsEntry.conditions.join("; ")}</em>.<br />
    Interventions: <em>${clinicalTrialsEntry.interventions.join("; ")}</em>.<br />
    (Clinical trial: <a href="https://clinicaltrials.gov/ct2/show/${clinicalTrialsEntry.nctId}">
      https://clinicaltrials.gov/ct2/show/${
        clinicalTrialsEntry.nctId
      }</a> OR search <a href="https://clinicaltrials.gov/">
      clinicaltrials.gov</a> for ${clinicalTrialsEntry.nctId})</p>`;

  const content = citation.value;
  eventsEmitter.emit("exporting-text-element", { content, isSentence });
}
</script>
