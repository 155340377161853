export const paragraphRules = {
  allowedTags: [
    "p",
    "span",
    "a",
    "em",
    "strong",
    "sup",
    "sub",
    "div",
    "table",
    "thead",
    "tbody",
    "tr",
    "td",
    "th",
    "ul",
    "li",
    "label",
    "caption",
    "img",
    "hr",
    "u",
    "br",
  ],
  allowedAttributes: {
    span: ["class", "id"],
    a: ["href", "target"],
    div: ["class", "id"],
    img: ["src", "type", "alt"],
  },
};

export const jsonParagraphRules = {
  allowedTags: ["span"],
  allowedAttributes: {
    span: ["class"],
  },
};

export const searchBarHtmlRules = {
  allowedTags: ["b"],
};

export const printingRichTextRules = {
  allowedTags: [],
};
