<template>
  <GuestUserLayout>
    <div class="col right-sign-block">
      <div class="form-wraper">
        <div class="p-5">
          <div class="text-center">
            <h2 class="mb-2 text-gray-800">Confirming your email</h2>
            <hr />
            <p v-if="loading" class="d-flex align-items-center">
              <strong>Please, wait...</strong>
              <span class="spinner-border ms-auto" role="status" aria-hidden="true"></span>
            </p>
            <div v-else-if="success">
              <div class="text-center text-success">
                <p>
                  <i class="fa fa-check-circle fa-4x"></i>
                </p>
                <h5>Email confirmed successfully</h5>
              </div>
              <p class="mb-4">
                You can
                <router-link :to="{ name: 'login' }">proceed with login</router-link>
              </p>
            </div>
            <div v-else-if="failure">
              <div class="text-center text-danger">
                <p>
                  <i class="fa fa-plus-circle fa-4x close-icon"></i>
                </p>
                <h5>Email confirmation failed</h5>
              </div>
              <ul>
                <li v-for="error in errors" :key="error" class="text-center text-danger">
                  {{ error }}
                </li>
              </ul>
              <p class="mb-4">Please, contact us or try again later</p>
              <div class="text-center">
                <router-link :to="{ name: 'login' }" class="small text-underline-hover">
                  Already have an account? Login!
                </router-link>
              </div>
              <div class="text-center">
                <router-link :to="{ name: 'register' }" class="small text-underline-hover">
                  Create account
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GuestUserLayout>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import authService from "@/services/authService";
import GuestUserLayout from "@/layouts/GuestUser.vue";
import { tryParseApiErrors } from "@/utils";

const loading = ref(false);
const success = ref(true);
const failure = ref(false);
const route = useRoute();
const errors = ref([]);

onMounted(async () => {
  try {
    await authService.confirmEmail(route.params.user_id, route.params.token);
    success.value = true;
  } catch (error) {
    errors.value = tryParseApiErrors(error);
    failure.value = true;
  }
  loading.value = false;
});
</script>

<style lang="sass" scoped>
.close-icon
  transform: rotate(45deg)
</style>
