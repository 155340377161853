import api from "@/services/api";

const { get, post } = api.getPrefixedMethods("genetics");

export const geneticExplorerService = {
  getOmimDetailed(mimNumber) {
    return get(`omim-detailed/${mimNumber}`);
  },
  getOmimRelated(mimNumber) {
    return get(`omim-related/${mimNumber}`);
  },
  searchOmim(searchTerms, pageInd) {
    return post(`omim-search/p/${pageInd}`, searchTerms);
  },
  searchClinVar(searchTerms, pageInd) {
    return post(`clinvar-search/p/${pageInd}`, searchTerms);
  },
  searchClinicalTrials(searchTerms, pageInd) {
    return post(`clinical-trials-search/p/${pageInd}`, searchTerms);
  },
  searchGeneReviews(searchTerms, pageInd) {
    return post(`genereview-search/p/${pageInd}`, searchTerms);
  },
  fetchPatienEd() {
    return get(`general-patient-ed/`);
  },
  getPatientEdDetailed(paperId) {
    return get(`general-patient-ed-detailed/${paperId}`);
  },
  getGeneReviewDetailed(geneReviewId) {
    return get(`genereview-detailed/${geneReviewId}`);
  },
  getGeneticSearchSuggestions(query) {
    return get("search-bar-suggestions", { params: { term: query } });
  },
  searchPubMedGuidelines(searchTerms) {
    return post("pubmed-guidelines-search", searchTerms);
  },
  searchPubMed(pubMedSearchInfo, pageInd) {
    return post(`pubmed-search/p/${pageInd}`, pubMedSearchInfo);
  },
  getPubMedDetailed(pmcId) {
    return get(`pubmed-detailed/${pmcId}`);
  },
  getPubMedRelated(relatedPubMedPapers) {
    return post("pubmed-related", relatedPubMedPapers);
  },
};
