<template>
  <div class="typeahead-wrapper">
    <div class="typeahead">
      <div class="input-wrapper">
        <input
          id="searchInput"
          v-model="typedText"
          type="text"
          :placeholder="props.placeholder"
          :queryMatches="props.queryMatches"
          @keyup="selectItemByEnter"
          @blur="cleanInputAndHideDropdown"
          @keyup.esc="cleanInputAndHideDropdown" />
        <label for="searchInput">
          <span><img src="@/assets/images/search-glass.svg" alt="search" /></span>
        </label>
      </div>
      <div v-if="isSearchDropdownVisible" class="search-dropdown-block">
        <div v-if="loading" class="hint">Loading...</div>
        <div v-else>
          <div v-if="inputTooShort" class="hint">Enter at least {{ minTermLen }} characters for autocomplete</div>
          <ul v-else>
            <li v-for="item in suggestions" :key="item.id" @click="selectItem(item)">
              <slot name="list-item-text" :item="item"></slot>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, shallowRef, watch, computed } from "vue";
import { useInterfaceElementsStore } from "@/stores";
import { storeToRefs } from "pinia";
import debounce from "lodash.debounce";

const { isSearchDropdownVisible } = storeToRefs(useInterfaceElementsStore());

const minTermLen = 3;
const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
  suggestionsProvider: {
    type: Function,
    required: true,
  },
});

const suggestions = shallowRef([]);
const loading = ref(false);
const typedText = ref("");
const inputTooShort = computed(() => typedText.value.length < minTermLen);

const emit = defineEmits(["selectItem"]);

function selectItemByEnter(event) {
  if ((event.code === "Enter" || event.code === 76) && !inputTooShort.value) {
    const itemChoosenByEnterClick = {
      entryType: "text",
      id: typedText.value,
      text: "",
      title: typedText.value,
      uid: `text-${typedText.value}`,
    };
    selectItem(itemChoosenByEnterClick);
  }
}

const selectItem = (item) => {
  emit("selectItem", item);
  isSearchDropdownVisible.value = false;
  typedText.value = "";
};

async function reloadSuggestions() {
  const newText = typedText.value;

  if (newText) {
    isSearchDropdownVisible.value = true;

    if (!inputTooShort.value) {
      loading.value = true;
      suggestions.value = await props.suggestionsProvider(newText);
      loading.value = false;
    }
  } else {
    suggestions.value = [];
    isSearchDropdownVisible.value = false;
  }
}

function cleanInputAndHideDropdown() {
  setTimeout(() => {
    isSearchDropdownVisible.value = false;
    typedText.value = "";
  }, 300);
}

watch(typedText, debounce(reloadSuggestions, 500));
</script>

<style lang="sass" scoped>

.typeahead-wrapper
  border-radius: 20px
  width: inherit
  flex-grow: 1

  .typeahead
    display: flex
    width: 100%
    flex-direction: column
    justify-content: space-between

    .input-wrapper
      display: flex

      input
        color: $gray-700
        font-size: 14px
        width: 100%
        margin-left: 10px
        outline: none
        border: none
        padding-left: 5px
        border-radius: 0px

        &:focus
          min-width: 175px

      img
        cursor: pointer

    .search-dropdown-block
      max-height: 30vh
      overflow: auto
</style>
