import api from "@/services/api";

const { get, post, put, delete: del } = api.getPrefixedMethods("eli8");

export const eli8Service = {
  getPublishedNotes() {
    return get("published-notes");
  },
  getNoteForSimplification(noteId, isUserInput = null) {
    return get(`simplify/${noteId}`, { params: { text: isUserInput } });
  },
  startNoteSimplification(noteId, sentences, isUserInput = null) {
    return post(`simplify/${noteId}`, sentences, { params: { text: isUserInput } });
  },
  startUserInputSimplification(data) {
    return post(`simplify-user-input/`, data);
  },
  getSimplificationForReview(noteId, isUserInput = null) {
    return get(`review/${noteId}`, { params: { text: isUserInput } });
  },
  reviewSimplification(noteId, data) {
    return post(`review/${noteId}`, data);
  },
  updateReviewedSentence(noteId, data) {
    return put(`review/${noteId}`, data);
  },
  getSimplifiedNote(simplifiedNoteId, isUserInput = null) {
    return get(`simplified/${simplifiedNoteId}`, { params: { text: isUserInput } });
  },
  updateSimplifiedNoteContent(simplifiedNoteId, data, isUserInput = null) {
    return put(`simplified/${simplifiedNoteId}`, data, { params: { text: isUserInput } });
  },
  deleteSimplifiedNote(simplifiedNoteId, isUserInput = null) {
    return del(`simplified/${simplifiedNoteId}`, { params: { text: isUserInput } });
  },
};
