<template>
  <GeneticSearchResults
    :get-list-function="getListFunction"
    :get-single-paper="getSinglePaper"
    :log-paper-action="logPaperAction"
    :current-tab="'publicPhrases'">
    <template #list-view="slot">
      <div class="element-content cursor-pointer">
        <span class="element-header">{{ slot.itemInfo.item.entry.title }}</span>
        <br />
        <span class="tabs-bold">
          {{ slot.itemInfo.item.entry.noteAuthor }}
        </span>
        <br />
        <span class="gray-auxiliary-information">
          <span> Date Modified: </span>
          <span class="tabs-bold">{{ convertDateFormatForNotes(slot.itemInfo.item.entry.dateModified) }}</span>
        </span>
        <br />
        <span v-if="slot.itemInfo.item.entry.searchTermsUsed">
          <span class="gray-auxiliary-information"> Search Terms Used by Author: </span>
          <span class="tabs-bold">{{ preprocessSearchTermsUsed(slot.itemInfo.item.entry.searchTermsUsed) }} </span>
        </span>
      </div>
      <div class="right-list-container">
        <span>
          <img src="@/assets/images/blue-arrow.svg" alt="arrow" class="public-phrase-arrow" />
          {{ slot.itemInfo.item.entry.usedAsSrc }}
        </span>
      </div>
    </template>

    <template #paper-view="slot">
      <div class="tab-body-right" :class="{ 'shrunk-menu': userInterfaceElements.geneExplorerMenuShrunk }">
        <div class="paper-header">
          <span class="blue-auxiliary-information">
            <InteractiveJsonParagraph :paragraph="slot.itemContent.content.title"> </InteractiveJsonParagraph>
          </span>
          <span class="paper-autor">
            {{ slot.itemContent.content.noteAuthor }}
          </span>
          <br />
          <span>
            <span class="gray-auxiliary-information"> Date Modified: </span>
            <span>
              {{ convertDateFormatForNotes(slot.itemContent.content.dateModified) }}
            </span>
          </span>
        </div>

        <div v-if="slot.itemContent.downloaded" class="loaded-description">
          <div class="paper-text-block">
            <InteractiveSectionList
              :nodes="slot.itemContent.downloaded.parsedPaperData.textSections"></InteractiveSectionList>
          </div>
        </div>
      </div>
    </template>
  </GeneticSearchResults>
</template>

<script setup>
import GeneticSearchResults from "../../geneticSearchResults/GeneticSearchResults.vue";
import { notesService, analyticsService } from "@/services";
import { convertDateFormatForNotes } from "@/utils";
import { useInterfaceElementsStore } from "@/stores";
import InteractiveJsonParagraph from "@/components/genetic-search/genetic-explorer-tools/InteractiveJsonParagraph";
import InteractiveSectionList from "@/components/genetic-search/genetic-explorer-tools/InteractiveSectionList";

const userInterfaceElements = useInterfaceElementsStore();

function getListFunction(searchTerms, pageIdx) {
  return notesService.searchPeerNotes(searchTerms, pageIdx);
}

function getSinglePaper(paperId) {
  return notesService.fetchPeerNote(paperId);
}

function logPaperAction(paper) {
  analyticsService.logGeneticExplorerPaperAction({
    actionType: "paper-selected",
    paperType: "phrase",
    paperTitle: paper.title,
    paperId: paper.id,
  });
}

function preprocessSearchTermsUsed(searchTermsUsed) {
  return searchTermsUsed
    .split(";;")
    .filter((element) => element !== "")
    .join("; ");
}
</script>
