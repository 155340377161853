<template>
  <GeneticSearchResults
    :get-list-function="getListFunction"
    :get-single-paper="getSinglePaper"
    :log-paper-action="logPaperAction"
    :current-tab="'geneReviews'">
    <template #list-view="slot">
      <div class="element-content cursor-pointer">
        <span class="element-header">
          <span class="first-term-letter phenotype"> P </span>
          {{ slot.itemInfo.item.entry.title }}; {{ slot.itemInfo.item.entry.shortname }}
        </span>
        <br />
        <span v-for="author in slot.itemInfo.item.entry.authors" :key="author.name" class="gray-auxiliary-information">
          {{ author.name }};
        </span>
        <br />
        <span>
          <span class="green-auxiliary-information">Matching terms: </span>
          <span v-for="term in slot.itemInfo.item.srcTerm" :key="term.title" class="green-auxiliary-information">
            {{ term.title }};
          </span>
        </span>
        <br />
        <span class="gray-auxiliary-information">
          <span>Date Created: </span>
          <span class="tabs-bold">{{ convertDateFormat(slot.itemInfo.item.entry.dateCreated) }}</span>
          <span v-if="slot.itemInfo.item.entry.dateModified">
            <span> Date Modified: </span>
            <span class="tabs-bold">{{ convertDateFormat(slot.itemInfo.item.entry.dateModified) }}</span>
          </span>
        </span>
      </div>
    </template>

    <template #paper-view="slot">
      <PaperSideMenu
        v-if="slot.itemContent.downloaded"
        :parsed-data="slot.itemContent.downloaded.parsedPaperData.textSections">
      </PaperSideMenu>
      <div class="tab-body-right" :class="{ 'shrunk-menu': userInterfaceElements.geneExplorerMenuShrunk }">
        <div class="paper-header">
          <span class="paper-tab-title bold-title">
            <InteractiveJsonParagraph
              :paragraph="slot.itemContent.content.title + '; ' + slot.itemContent.content.shortname">
            </InteractiveJsonParagraph>
          </span>
          <span class="big-gray-auxiliary-information">
            {{ slot.itemContent.content.synonyms }}
          </span>
          <br />
          <div class="authors">
            <span v-for="author in slot.itemContent.content.authors" :key="author.name" class="author">
              {{ author.name }};
            </span>
          </div>
          <span
            class="blue-auxiliary-information"
            data-bs-toggle="collapse"
            href="#collapse-authors-block"
            role="button"
            aria-expanded="false"
            aria-controls="#collapse-authors-block">
            Author Information
            <img src="@/assets/images/check-mark.png" alt="check-mark" />
          </span>
          <div class="row">
            <div class="col">
              <div id="collapse-authors-block" class="collapse multi-collapse collapse-authors-block">
                <div class="card-body">
                  <ul v-for="author in slot.itemContent.content.authors" :key="author.name">
                    <li class="collapse-author-header">{{ author.name }}</li>
                    <li>{{ author.affiliation }}</li>
                    <li v-if="author.email">
                      Email: <a :href="`mailto:${author.email}`">{{ author.email }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <span class="gray-auxiliary-information">
            Date Created:
            <span class="black-auxiliary-information">{{
              convertDateFormat(slot.itemContent.content.dateCreated)
            }}</span>
            Date Modified:
            <span class="black-auxiliary-information">{{
              convertDateFormat(slot.itemContent.content.dateModified)
            }}</span>
          </span>
        </div>
        <div v-if="slot.itemContent.downloaded" class="loaded-description">
          <div class="paper-text-block">
            <InteractiveSectionList
              :nodes="slot.itemContent.downloaded.parsedPaperData.textSections"></InteractiveSectionList>
          </div>
        </div>
      </div>
    </template>
  </GeneticSearchResults>
</template>

<script setup>
import GeneticSearchResults from "../../geneticSearchResults/GeneticSearchResults";
import { convertDateFormat } from "@/utils";
import { geneticExplorerService, analyticsService } from "@/services";
import PaperSideMenu from "@/components/genetic-search/genetic-explorer-tools/PaperSideMenu";
import { useInterfaceElementsStore } from "@/stores";
import InteractiveJsonParagraph from "@/components/genetic-search/genetic-explorer-tools/InteractiveJsonParagraph";
import InteractiveSectionList from "@/components/genetic-search/genetic-explorer-tools/InteractiveSectionList";

const userInterfaceElements = useInterfaceElementsStore();

function getListFunction(searchTerms, pageIdx) {
  return geneticExplorerService.searchGeneReviews(searchTerms, pageIdx);
}

function getSinglePaper(paperId) {
  return geneticExplorerService.getGeneReviewDetailed(paperId);
}

function logPaperAction(paper) {
  analyticsService.logGeneticExplorerPaperAction({
    actionType: "paper-selected",
    paperType: "genereviews",
    paperTitle: paper.title,
    paperId: paper.nbkId,
  });
}
</script>
