<template>
  <div class="notes-wrapper">
    <span :to="{ name: 'printing-simplified-note' }" target="_blank" class="printer-img">
      <img src="@/assets/images/printer.svg" alt="print" @click="printSimplifiedNoteResult" />
    </span>
    <div class="notes-body" :class="{ 'max-note-height': !clinicalNoteId }">
      <div class="modal" tabindex="-1" :class="{ 'modal-window-state': showModalInfo }">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">{{ noteProblemInfo }}</h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModalWindow()"></button>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModalWindow()">
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <span class="d-flex notes-builder-img-block">
          <span>
            <img src="@/assets/images/note-paper.svg" alt="paper-note" />
          </span>
          <input
            id="notes-title"
            v-model="clinicalNoteTitle"
            type="text"
            class="notes-title"
            :class="{ 'red-border': clinicalNoteTitle.length < 1 }"
            placeholder="Name your phrase something searchable"
            @change="clinicalNoteId.value && saveClinicalNoteDraft" />
        </span>
        <span class="printer-img-block"><img src="@/assets/images/printer.svg" alt="printer" /></span>
      </div>
      <div class="editor-container">
        <ckeditor v-model="clinicalNoteContent" :config="editorConfig" :editor="editorBuild" @ready="onEditorReady">
        </ckeditor>
        <div class="watermark">
          <span v-if="clinicalNoteContent" class="watermark-content"> Made with Lamar Health </span>
        </div>
      </div>
      <div
        v-if="route.params.noteId"
        class="form-check"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="notes with PHI can not be saved">
        <input id="flexCheckDefault" class="form-check-input" type="checkbox" checked @click="changePhiStatus" />
        <label class="form-check-label" :class="{ 'red-label': !noPhiConfirmed }" for="flexCheckDefault">
          I confirm no PHI has been added to this text.
        </label>
      </div>
    </div>
    <div class="notes-footer">
      <div v-if="errors.length < 1 && noteSaveProcess" class="text-secondary justify-content-center spinner-container">
        <span class="spinner-border spinner-border-sm" role="status"></span>
        <span class="spinner-title">{{ noteSaveProcess }}</span>
      </div>
      <div v-if="errors.length > 0" class="errors-container">
        <span v-for="error in errors" :key="error" class="error">{{ error }}</span>
      </div>

      <div class="notes-buttons">
        <div v-if="route.params.noteId" class="action-notes-buttons">
          <button v-if="publishedNoteId && phraseContentUpdated && noPhiConfirmed" class="notes-update-button">
            Saved to My Phrases!
          </button>
          <button v-else-if="noPhiConfirmed" class="notes-save-button" @click="publishClinicalNote(true)">Save</button>
          <button
            v-if="publishedNoteId && phraseContentUpdated === true && noPhiConfirmed"
            class="notes-copy-button"
            :class="{ 'copied-to-ehr': copyButtonText === 'Copied! Paste (Ctrl+V) in your EHR' }"
            @click="copyNoteTextToClipboard">
            <img v-if="copyButtonText === 'Copy to EHR'" src="@/assets/images/copy-img.svg" alt="copy" />
            <img
              v-else-if="copyButtonText === 'Copied! Paste (Ctrl+V) in your EHR'"
              src="@/assets/images/white-copy-img.svg"
              alt="copy" />
            {{ copyButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, watch, onMounted, onUnmounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { tryParseApiErrors } from "@/utils";

import { notesService } from "@/services";
import { useClinicalNoteEditorStore, useGenSearchCriteriaStore, usePubMedRelatedPapersStore } from "@/stores";
import { ckeditor, editorBuild, editorConfig } from "@/utils/ckeditor";

const router = useRouter();
const route = useRoute();
const { clinicalNoteId, noteSaveProcess } = storeToRefs(useClinicalNoteEditorStore());
const { currentSearchTerms } = storeToRefs(useGenSearchCriteriaStore());
const { requestRelatedPubMedPapers } = storeToRefs(usePubMedRelatedPapersStore());

const errors = ref([]);

const publishedNoteId = ref(null);
const clinicalNoteContent = ref("");
const clinicalNoteTitle = ref("");
const clinicalNoteDateModified = ref(null);
const eventsEmitter = inject("emitter");
const copyButtonText = ref("Copy to EHR");
const phraseContentUpdated = ref(true);
const phraseStatus = ref(null);
const showModalInfo = ref(false);
const noteProblemInfo = ref(null);
const autoSaveJobInterval = ref(false);
const noPhiConfirmed = ref(true);

const lastSavedContent = ref(null);
const lastSavedTitle = ref(null);

const phraseContent = ref("");
const watermarkContent = ref("");

onMounted(async () => {
  localStorage.removeItem("printingTitle");
  localStorage.removeItem("printingBody");
  if (route.params.noteId) {
    const noteInfo = await notesService.fetchNoteForEditing(route.params.noteId);
    const { id, title, content, completed } = noteInfo;
    clinicalNoteId.value = id;
    clinicalNoteContent.value = content;
    clinicalNoteTitle.value = title;
    publishedNoteId.value = (completed || {}).id;
    clinicalNoteDateModified.value = completed ? completed.dateModified : null;

    phraseStatus.value = publishedNoteId.value && clinicalNoteId.value ? "published" : "draft";
    if (phraseStatus.value === "draft") phraseContentUpdated.value = false;

    nextTick(() => {
      watch([clinicalNoteContent, clinicalNoteTitle], ([newContent, newTitle]) => {
        if (newContent || newTitle) {
          if (!autoSaveJobInterval.value) startAutoSaveJob();
        }
      });
    });
  } else {
    createNoteOnEdit();
  }

  eventsEmitter.on("exporting-text-element", textExportHandler);
});

onBeforeRouteLeave(() => {
  if (window.event.clientY && phraseStatus.value) {
    if (clinicalNoteTitle.value === "" && phraseContentUpdated.value === false) {
      noteProblemInfo.value = "Please add a title and click on save button";
      showModalInfo.value = true;
      return false;
    } else if (clinicalNoteTitle.value !== "" && phraseContentUpdated.value === false) {
      noteProblemInfo.value = "Please save your note";
      showModalInfo.value = true;
      return false;
    } else if (clinicalNoteTitle.value === "") {
      noteProblemInfo.value = "Please add a title to your note";
      showModalInfo.value = true;
      return false;
    }
  } else if (!window.event.clientY && clinicalNoteTitle.value === "" && phraseStatus.value) {
    clinicalNoteTitle.value = "Untitled Phrase";
  }

  if (clinicalNoteId.value) {
    noteSaveProcess.value = "Saving";
    saveClinicalNoteDraft();
  }
});

onUnmounted(() => {
  clearInterval(autoSaveJobInterval.value);
  clinicalNoteId.value = null;
  currentSearchTerms.value = [];
  requestRelatedPubMedPapers.value = [];
  eventsEmitter.off("exporting-text-element", textExportHandler);
});

function changePhiStatus() {
  noPhiConfirmed.value = !noPhiConfirmed.value;
}

function createNoteOnEdit() {
  let destroyNoteCreationWatcher = watch(clinicalNoteContent, async (newVal) => {
    if (newVal) {
      destroyNoteCreationWatcher();
      // user has wrote something but the note is not created yet. let's create it
      try {
        // set default title if user didn't specify anything
        if (!clinicalNoteTitle.value) {
          clinicalNoteTitle.value = "Untitled Phrase";
        }

        const response = await notesService.createDraftNote({
          title: clinicalNoteTitle.value,
          content: clinicalNoteContent.value,
        });

        clinicalNoteId.value = response.id;

        startAutoSaveJob();

        errors.value = [];

        router.push({ params: { noteId: clinicalNoteId.value }, replace: true });
      } catch (error) {
        displayError(error);
      }
    }
  });
}

async function publishClinicalNote(stayOnThePage) {
  if (!clinicalNoteTitle.value) {
    showModalInfo.value = true;
    noteProblemInfo.value = "Please add a title and click on save button";
    return;
  }
  try {
    let info = {
      title: clinicalNoteTitle.value,
      content: clinicalNoteContent.value,
      searchTermsUsed: concatSearchTermTitles(currentSearchTerms.value),
    };

    updateLastSaved();
    noteSaveProcess.value = "Saving";

    if (publishedNoteId.value) {
      await notesService.republishNote(publishedNoteId.value, info);
    } else {
      const response = await notesService.publishNote(clinicalNoteId.value, info);
      clinicalNoteId.value = response.clinicalNoteId;
      publishedNoteId.value = response.id;
      clinicalNoteDateModified.value = response.dateModified;
    }

    noteSaveProcess.value = null;
    errors.value = [];
    copyButtonText.value ? copyButtonText.value : (copyButtonText.value = "Copy to EHR");
    phraseContentUpdated.value = true;
    // redirect to my-phrases
    // TODO: we should also add some success notification
    if (!stayOnThePage) {
      router.push({ name: "my-phrases" });
    }
  } catch (error) {
    displayError(error);
  }
}

function updateLastSaved() {
  // This function helps to know if the "Save" button has already been clicked and gives to know that autoSaveJobInterval is redundant
  lastSavedContent.value = clinicalNoteContent.value;
  lastSavedTitle.value = clinicalNoteTitle.value;
}

function startAutoSaveJob() {
  autoSaveJobInterval.value = setInterval(() => {
    const newContent = clinicalNoteContent.value;
    const newTitle = clinicalNoteTitle.value;

    if (newContent !== lastSavedContent.value || newTitle !== lastSavedTitle.value) {
      noteSaveProcess.value = "Autosaving";

      updateLastSaved();

      phraseContentUpdated.value = false;
      copyButtonText.value = "Copy to EHR";

      saveClinicalNoteDraft();
    }
  }, 5000);
}

async function saveClinicalNoteDraft(spinnerText = null) {
  if (clinicalNoteId.value) {
    if (spinnerText) noteSaveProcess.value = spinnerText;
    try {
      await notesService.autosavingDraft(clinicalNoteId.value, {
        title: clinicalNoteTitle.value,
        content: clinicalNoteContent.value,
        searchTermsUsed: concatSearchTermTitles(currentSearchTerms.value),
      });
      errors.value = [];
      noteSaveProcess.value = null;
    } catch (error) {
      noteSaveProcess.value = null;
      displayError(error);
    }
  }
}

function displayError(err) {
  errors.value = tryParseApiErrors(err);
}

// ---------------- paragraphs events handling ----------------
function textExportHandler({ content, isSentence }) {
  content = isSentence ? `<span>${content}&nbsp;</span>` : `<br/>${content}<span>&nbsp;</span>`;

  try {
    insertContentWithFocus(content);
  } catch (error) {
    displayError(error);
  }
}

// ---------------- Concatenate search term titles ------------------

function concatSearchTermTitles(searchTerms) {
  if (searchTerms.length) {
    return searchTerms.map(({ title }) => title).join();
  } else {
    return "";
  }
}

// ---------------- CKEditor setup ------------------
let editorInstance = null;

function onEditorReady(editor) {
  editorInstance = editor;
}

function insertContentWithFocus(newContent) {
  const viewFragment = editorInstance.data.processor.toView(newContent);
  const modelFragment = editorInstance.data.toModel(viewFragment);

  editorInstance.model.insertContent(modelFragment, editorInstance.editing.view.focus());

  const startPosition = editorInstance.model.document.selection.getFirstPosition();

  const range = editorInstance.editing.view.createRange(startPosition);
  const selection = editorInstance.editing.view.createSelection(range);

  editorInstance.editing.view.scrollToTheSelection(selection);
}

// ---------------- Copy to Clipboard ---------------

const copyNoteTextToClipboard = async () => {
  if (!clinicalNoteContent.value) {
    copyButtonText.value = "Nothing to copy";

    return;
  }
  copyButtonText.value = "Copying in progress...";
  try {
    // Referencing the parent of the element whose elements are necessary to copy.
    phraseContent.value = editorInstance.sourceElement.nextSibling.lastChild;
    watermarkContent.value = document.querySelector(".watermark");

    phraseContent.value.append(watermarkContent.value);

    const range = new Range();

    const startIndex = 0;
    const selection = window.getSelection();

    range.setStart(phraseContent.value, startIndex);
    range.setEnd(phraseContent.value, phraseContent.value.children.length);

    selection.removeAllRanges();
    selection.addRange(range);
    phraseContent.value.firstChild.scrollTop = 0;
    document.execCommand("copy");

    copyButtonText.value = "Copied! Paste (Ctrl+V) in your EHR";
  } catch (error) {
    displayError(error);
  }
};

function printSimplifiedNoteResult() {
  const noteSentences = { richText: clinicalNoteContent.value };

  localStorage.setItem("printingTitle", clinicalNoteTitle.value);
  localStorage.setItem("printingBody", JSON.stringify(noteSentences));
  const printingRoute = router.resolve({ name: "printing-smart-phrase" });
  window.open(printingRoute.href);
}

// function

function closeModalWindow() {
  showModalInfo.value = false;
  if (clinicalNoteTitle.value === "") {
    addFocusForTitleInput();
  }
}

function addFocusForTitleInput() {
  document.getElementById("notes-title").focus();
}
</script>

<style lang="sass" scoped>
@import url("@/styles/components/_ckeditor.scss")
@import url("@/styles/components/_modal-window.scss")

.notes-wrapper
  position: relative
  display: flex
  height: calc(100% + 72px)
  flex-direction: column
  width: 33%
  padding: 20px 20px 20px 20px
  background-color: $white
  border-left: 1px solid $gray-400

  @media (max-width: 1600px)
    width: 40%

  .printer-img
    position: absolute
    cursor: pointer
    right: 11px
    top: 63px
    z-index: 1

  .notes-body
    height: 86%

    @media (max-height: 1000px)
      height: 85%

    @media (max-height: 855px)
      height: 83%

    @media (max-height: 655px)
      height: 75%

    .notes-builder-img-block
      font-size: 20px
      font-weight: 500
      color: $blue-700
      flex: 1

      .notes-title
        margin: 1px 0 0 8px
        flex: 1
        border: 1px solid $gray-500
        border-radius: 6px
        padding: 0px 0px 2px 8px

        &::placeholder
          font-size: 16px
          color: $blue-700
          font-weight: 600

      .red-border

        border-color: $red !important

    .printer-img-block
      display: none
      cursor: pointer

    .editor-container
      height: inherit
      margin-top: 15px

    .form-check
      margin-top: 20px
      .red-label
        color: $red

  .max-note-height
    height: calc(100% + 50px)

  .watermark
    border: 1px solid $gray-400
    border-radius: 0px 0px 5px 5px
    border-top: 0
    padding: 15px 0 0 12px
    min-height: 40px

    .watermark-content
      opacity: 0.6



  .notes-footer
    display: flex
    align-items: center
    flex-direction: column
    justify-content: space-around
    width: 100%
    height: 14%

    @media (max-height: 1000px)
      height: 15%

    @media (max-height: 855px)
      height: 17%

    @media (max-height: 660px)
      height: 25%

    .spinner-container
      height: 10%

    .notes-buttons
      width: inherit
      margin-top: 40px

      .action-notes-buttons
        width: inherit

        .notes-update-button,
        .notes-save-button,
        .copied-to-ehr
          font-size: 15px
          border-radius: 6px
          padding: 10px 0 10px 0
          font-weight: 500
          color: $white
          border: none
          width: 100%

        .notes-save-button
          background-color: $blue

        .notes-update-button,
        .copied-to-ehr
          background-color: $green-450
          cursor: default

    .notes-copy-button
      font-size: 15px
      border-radius: 6px
      font-weight: 500
      padding: 10px 0 10px 0
      width: 100%
      margin-top: 16px
      border: 1.5px solid $blue-700
      color: $blue-700
      background-color: $white

    .spinner-container
      position: absolute
      margin-top: 5px
      left: 45%

      .spinner-title
        text-align: center
        margin: -1px 0 0 8px
        color: $gray-600
        font-size: 12px

    .errors-container
      position: absolute
      padding: 0 10%
      margin-top: 5px
      color: $red

      @media (max-width: 1440px)
        padding: 0 12%
        font-size: 14px

      @media (max-width: 1160px)
        padding: 0 11%
        font-size: 13px
</style>
