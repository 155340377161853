import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import ParagraphButtonUI from "@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui";

const ckeditor = CKEditor.component;
const editorConfig = {
  placeholder: "1. Select an article  2. Click on (+) symbol",
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    LinkPlugin,
    ParagraphPlugin,
    ParagraphButtonUI,
    Alignment,
    Font,
    Strikethrough,
    Underline,
    Heading,
    HeadingButtonsUI,
  ],
  toolbar: [
    "paragraph",
    "heading2",
    "heading3",
    "italic",
    "bold",
    "underline",
    "strikethrough",
    "link",
    "fontColor",
    "alignment:left",
    "alignment:center",
    "alignment:right",
    "alignment:justify",
  ],
};

const editorBuild = ClassicEditor;
export { ckeditor, editorBuild, editorConfig };
