<template>
  <header class="border-bottom static-top">
    <div class="d-flex justify-content-between">
      <div class="d-flex header-left">
        <div class="header-menu-wrapper" @click="sideMenuIsCollapsed = !sideMenuIsCollapsed">
          <span class="full-menu" :class="{ 'shrunk-menu': sideMenuIsCollapsed }"></span>
        </div>
        <div class="header-logo-wrapper">
          <router-link :to="{ name: 'home' }" class="nav-link">
            <img src="@/assets/images/header-logo.svg" alt="logo" />
          </router-link>
        </div>
      </div>

      <div class="header-center">
        <PhrasesSearchBar v-if="route.name === 'my-phrases'" />
        <GeneticSearchBar v-else-if="route.name === 'smart-phrase'" />
      </div>

      <div class="d-flex header-right"></div>
    </div>
  </header>
</template>

<script setup>
import { useInterfaceElementsStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import GeneticSearchBar from "@/components/genetic-search/search-bar-tools/GeneticSearchBar";
import PhrasesSearchBar from "./genetic-search/search-bar-tools/PhrasesSearchBar";

const route = useRoute();

const { sideMenuIsCollapsed } = storeToRefs(useInterfaceElementsStore());
</script>

<style lang="sass" scoped>

header
  position: sticky
  top: 0px
  z-index: 10
  background-color: $white
  border-bottom: 1px solid $gray-300 !important
  padding: 11px 24px 15px 15px


  .header-center
    width: 800px
    position: relative

  .header-left

    .header-menu-wrapper
      cursor: pointer
      display: flex
      align-items: center

      .full-menu

        &::before
          content: url("@/assets/images/burger-menu.svg")

      .shrunk-menu::before
        content: url("@/assets/images/burger-menu.svg")
    .header-logo-wrapper
      margin-left: 15px

      .nav-link
        padding: 0

    @media (max-width: $layout-breakpoint-medium)
      .header-logo-wrapper
        display: none

  .header-right

    .header-notiffications-wrapper
      height: 32px
      width: 32px
      display: flex
      align-items: center
      justify-content: center

      img
        cursor: pointer

    .header-initials-wrapper
      border-radius: 25px
      color: $white
      background-color: $blue
      height: 34px
      width: 34px

      a
        color: $white
        font-size: 12px

        &:after
          display: none

      ul li a
        color: $blue-700
    .header-search, .header-notiffications-wrapper, .header-initials-wrapper
      margin-right: 22px
</style>
