<template>
  <div class="extra-information">
    <div class="clinical-nav-tabs">
      <ul class="navigation">
        <li>
          <button class="tab-links" :class="{ 'active-tab': activeTab === 'omim' }" @click="activeTab = 'omim'">
            OMIM
          </button>
        </li>
        <li>
          <button
            class="tab-links"
            :class="{ 'active-tab': activeTab === 'genereviews' }"
            @click="activeTab = 'genereviews'">
            GeneReviews
          </button>
        </li>
        <li>
          <button class="tab-links" :class="{ 'active-tab': activeTab === 'pubmed' }" @click="activeTab = 'pubmed'">
            PubMed
          </button>
        </li>
        <li>
          <button class="tab-links" :class="{ 'active-tab': activeTab === 'clinvar' }" @click="activeTab = 'clinvar'">
            ClinVar
          </button>
        </li>
        <li>
          <button
            class="tab-links"
            :class="{ 'active-tab': activeTab === 'clinicaltrials' }"
            @click="activeTab = 'clinicaltrials'">
            Clinical Trials
          </button>
        </li>
        <li>
          <button
            class="tab-links"
            :class="{ 'active-tab': activeTab === 'publicphrases' }"
            @click="activeTab = 'publicphrases'">
            Public Phrases
          </button>
        </li>
        <li>
          <button
            class="tab-links"
            :class="{ 'active-tab': activeTab === 'patiented' }"
            @click="activeTab = 'patiented'">
            PatientEd
          </button>
        </li>
      </ul>
    </div>

    <div class="genes-interactive-information">
      <div class="tab-content">
        <div id="omim" class="content-tab" :class="{ 'active-content-tab': activeTab === 'omim' }">
          <OmimTab></OmimTab>
        </div>

        <div id="genereviews" class="content-tab" :class="{ 'active-content-tab': activeTab === 'genereviews' }">
          <GeneReviewsTab></GeneReviewsTab>
        </div>

        <div id="pubmed" class="content-tab" :class="{ 'active-content-tab': activeTab === 'pubmed' }">
          <PubMedTab></PubMedTab>
        </div>

        <div id="clinvar" class="content-tab" :class="{ 'active-content-tab': activeTab === 'clinvar' }">
          <ClinVarTab></ClinVarTab>
        </div>

        <div id="clinicaltrials" class="content-tab" :class="{ 'active-content-tab': activeTab === 'clinicaltrials' }">
          <ClinicalTrialsTab></ClinicalTrialsTab>
        </div>

        <div id="publicphrases" class="content-tab" :class="{ 'active-content-tab': activeTab === 'publicphrases' }">
          <PublicPhrasesTab></PublicPhrasesTab>
        </div>

        <div id="patiented" class="content-tab" :class="{ 'active-content-tab': activeTab === 'patiented' }">
          <PatientEdTab></PatientEdTab>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";

import GeneReviewsTab from "./genetic-explorer-tabs/tabs/geneReviewsTab/GeneReviewsTab";
import OmimTab from "./genetic-explorer-tabs/tabs/omimTab/OmimTab";
import PubMedTab from "./genetic-explorer-tabs/tabs/pubMedTab/PubMedTab";
import ClinVarTab from "./genetic-explorer-tabs/tabs/clinVarTab/ClinVarTab";
import ClinicalTrialsTab from "./genetic-explorer-tabs/tabs/clinicalTrialsTab/ClinicalTrialsTab";
import PublicPhrasesTab from "./genetic-explorer-tabs/tabs/publicPhrasesTab/PublicPhrasesTab";
import PatientEdTab from "./genetic-explorer-tabs/tabs/patientEdTab/PatientEdTab";

const eventsEmitter = inject("emitter");
const activeTab = ref("omim");

onMounted(() => {
  eventsEmitter.on("redirect-to-tab", switchTab);
});

function switchTab(tabId) {
  // use data-bs-target to get the tab
  activeTab.value = tabId;
}
</script>
