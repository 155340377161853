<template>
  <GeneticSearchResults :get-list-function="getListFunction" :current-tab="'clinVar'">
    <template #list-view="slot">
      <div class="element-content">
        <div class="clinvar-header-wrapper">
          <div class="left-clinvar-header">
            <div class="element-header">
              <a target="_blank" :href="'https://www.ncbi.nlm.nih.gov/clinvar/variation/' + slot.itemInfo.item.uid">
                {{ slot.itemInfo.item.title }}<i class="fa fa-external-link ms-1"></i>
              </a>
            </div>
            <div class="list-info green-auxiliary-information">
              <span>Matching terms: </span>
              <span> {{ slot.itemInfo.item.matchingTerm }}; </span>
            </div>
            <div class="list-info">
              <span class="gray-auxiliary-information">Interpretation: </span>
              <span class="dark-extra-big-auxiliary-information">{{ slot.itemInfo.item.interpretation }}</span>
            </div>

            <div class="list-info">
              <span class="gray-auxiliary-information">Review Status: </span>
              <span v-for="star in visualizeReviewStatus(slot.itemInfo.item.reviewStatus)" :key="star">
                <i class="fa fa-star ms-1 gold-star"></i>
              </span>
              <span
                v-for="star in 4 - visualizeReviewStatus(slot.itemInfo.item.reviewStatus)"
                :key="star"
                class="gray-auxiliary-information">
                <i class="fa fa-star-o ms-1"></i>
              </span>
              <span>&emsp;{{ slot.itemInfo.item.reviewStatus }}</span>
            </div>
            <div class="list-info">
              <span class="gray-auxiliary-information">Last Evaluated: </span>
              <span class="tabs-bold">{{ convertDateFormat(slot.itemInfo.item.lastEvaluated) }}</span>
            </div>
            <div class="list-info">
              <span class="gray-auxiliary-information">Description: </span>
              <span class="dark-gray-auxiliary-information">{{ slot.itemInfo.item.description }}</span>
            </div>
          </div>
          <div class="right-list-container">
            <span class="right-list-container-save-button" @click="exportCitation(slot.itemInfo.item, false)">
              Summarize & Cite
            </span>
          </div>
        </div>

        <div class="clinical-assertions-wrapper">
          <div class="clinical-assertions-header">
            <span v-if="slot.itemInfo.item.submittedInterpretations.length" class="submitted-interpretations-wrapper">
              <span
                data-bs-toggle="collapse"
                :href="`#submitted-interpretations-${slot.itemInfo.index + 1}`"
                role="button"
                aria-expanded="false"
                :aria-controls="`#submitted-interpretations-${slot.itemInfo.index + 1}`"
                class="drop-down-information collapsed">
                View Interpretations
              </span>
            </span>

            <span v-if="slot.itemInfo.item.functionalConsequence.length" class="functional-evidence-wrapper">
              <span
                data-bs-toggle="collapse"
                :href="`#functional-evidence-${slot.itemInfo.index + 1}`"
                role="button"
                aria-expanded="false"
                :aria-controls="`#functional-evidence-${slot.itemInfo.index + 1}`"
                class="drop-down-information collapsed">
                Functional Evidence
              </span>
            </span>

            <span
              v-if="slot.itemInfo.item.otherNames.length || slot.itemInfo.item.proteinChange.length"
              class="other-names-wrapper">
              <span
                data-bs-toggle="collapse"
                :href="`#other-names-${slot.itemInfo.index + 1}`"
                role="button"
                aria-expanded="false"
                :aria-controls="`#other-names-${slot.itemInfo.index + 1}`"
                class="drop-down-information collapsed">
                Other Names
              </span>
            </span>
          </div>

          <div class="collapse-wrapper">
            <div :id="`submitted-interpretations-${slot.itemInfo.index + 1}`" class="collapse multi-collapse">
              <table class="clinvar-info clinvar-table">
                <thead>
                  <tr>
                    <th>Interpretation</th>
                    <th>Review Status</th>
                    <th>Condition (inheritance)</th>
                    <th>Submitted By</th>
                    <th>PubMed References</th>
                  </tr>
                </thead>
                <tbody v-for="(interpretation, idx) in slot.itemInfo.item.submittedInterpretations" :key="idx">
                  <tr>
                    <td>
                      {{ interpretation.interpretation }}
                      <span v-if="interpretation.lastEvaluated">
                        <br />
                        ({{ convertDateFormat(interpretation.lastEvaluated) }})
                      </span>
                    </td>
                    <td>
                      <div class="table-stars-wrapper">
                        <span v-for="star in visualizeReviewStatus(interpretation.reviewStatus)" :key="star">
                          <i class="fa fa-star ms-1 gold-star"></i>
                        </span>
                        <span v-for="star in 4 - visualizeReviewStatus(interpretation.reviewStatus)" :key="star">
                          <i class="fa fa-star-o ms-1"></i>
                        </span>
                        {{ interpretation.reviewStatus }}
                      </div>
                    </td>
                    <td>
                      {{ interpretation.condition }}
                      <span v-if="interpretation.inheritance">
                        <br />
                        ({{ interpretation.inheritance }})
                      </span>
                    </td>
                    <td>{{ interpretation.submitter }}</td>
                    <td>
                      <span v-if="interpretation.supportingPublications.length">
                        <span class="pubmed-link-description">
                          <span>{{ interpretation.supportingPublications.length }} citation</span>
                          <span v-if="interpretation.supportingPublications.length > 1">s</span>
                        </span>

                        <a class="pubmed-link" @click="loadPubMedRelatedPapers(interpretation.supportingPublications)">
                          View in PubMed Tab<i class="fa fa-external-link ms-1"></i>
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="interpretation.comment">
                    <td colspan="5">
                      <span
                        v-if="interpretation.comment.length"
                        class="drop-down-information collapsed"
                        data-bs-toggle="collapse"
                        :href="`#collapse-interptetation-comment-${slot.itemInfo.index + 1}-${idx}`"
                        role="button"
                        aria-expanded="false"
                        :aria-controls="`#collapse-interptetation-comment-${slot.itemInfo.index + 1}-${idx}`">
                        See comment
                      </span>
                      <div class="row">
                        <div class="col">
                          <div
                            :id="`collapse-interptetation-comment-${slot.itemInfo.index + 1}-${idx}`"
                            class="collapse multi-collapse collapse-consequence-comment">
                            <div>
                              {{ interpretation.comment }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div :id="`functional-evidence-${slot.itemInfo.index + 1}`" class="collapse multi-collapse">
              <table class="clinvar-info clinvar-table">
                <thead>
                  <tr>
                    <th>Functional Consequence</th>
                    <th>Submitted By</th>
                    <th>PubMed References</th>
                  </tr>
                </thead>
                <tbody v-for="(consequence, idx) in slot.itemInfo.item.functionalConsequence" :key="idx">
                  <tr>
                    <td>{{ consequence.functionalConsequence }}</td>
                    <td>{{ consequence.submitter }}</td>
                    <td>
                      <span v-if="consequence.supportingPublications.length">
                        <span class="pubmed-link-description">
                          <span>{{ consequence.supportingPublications.length }} citation</span>
                          <span v-if="consequence.supportingPublications.length > 1">s</span>
                        </span>

                        <a
                          class="pubmed-link"
                          @click.stop="loadPubMedRelatedPapers(consequence.supportingPublications)">
                          View in PubMed Tab<i class="fa fa-external-link ms-1"></i>
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="consequence.supportingComment">
                    <td colspan="3">
                      <span
                        v-if="consequence.supportingComment.length"
                        data-bs-toggle="collapse"
                        class="drop-down-information collapsed"
                        :href="`#collapse-consequence-comment-${slot.itemInfo.index + 1}-${idx}`"
                        role="button"
                        aria-expanded="false"
                        :aria-controls="`#collapse-consequence-comment-${slot.itemInfo.index + 1}-${idx}`">
                        See supporting comment
                      </span>
                      <div class="row">
                        <div class="col">
                          <div
                            :id="`collapse-consequence-comment-${slot.itemInfo.index + 1}-${idx}`"
                            class="collapse multi-collapse collapse-consequence-comment">
                            <div>
                              {{ consequence.supportingComment }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div :id="`other-names-${slot.itemInfo.index + 1}`" class="collapse multi-collapse">
              <table class="clinvar-info clinvar-table equal-width-columns">
                <thead>
                  <tr>
                    <th>Protein Change</th>
                    <th>Other Names</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ renderArray(slot.itemInfo.item.proteinChange) }}</td>
                    <td>{{ renderArray(slot.itemInfo.item.otherNames) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GeneticSearchResults>
</template>

<script setup>
import { ref, inject } from "vue";
import GeneticSearchResults from "../../geneticSearchResults/GeneticSearchResults.vue";
import { convertDateFormat, renderArray } from "@/utils/index.js";
import { geneticExplorerService } from "@/services";
import { usePubMedRelatedPapersStore } from "@/stores";
import { storeToRefs } from "pinia";

const { requestRelatedPubMedPapers } = storeToRefs(usePubMedRelatedPapersStore());

function getListFunction(searchTerms, pageIdx) {
  return geneticExplorerService.searchClinVar(searchTerms, pageIdx);
}

const eventsEmitter = inject("emitter");
const citation = ref("");

function exportCitation(clinVarEntry, isSentence) {
  citation.value = `<p>The patient presented with the mutation ${clinVarEntry.title}. 
    Based on the available evidence this is interpreted to be ${clinVarEntry.interpretation}.
    (ClinVar: <a href="https://www.ncbi.nlm.nih.gov/clinvar/variation/${clinVarEntry.uid}">
    https://www.ncbi.nlm.nih.gov/clinvar/variation/${clinVarEntry.uid}</a>)</p>`;

  const content = citation.value;
  eventsEmitter.emit("exporting-text-element", { content, isSentence });
}

function loadPubMedRelatedPapers(pmId) {
  requestRelatedPubMedPapers.value = { pmId: pmId, origin: "ClinVar" };
  eventsEmitter.emit("redirect-to-tab", "pubmed");
}

function visualizeReviewStatus(reviewStatus) {
  switch (reviewStatus) {
    case "criteria provided, single submitter":
      return 1;
    case "criteria provided, conflicting interpretations":
      return 1;
    case "criteria provided, multiple submitters, no conflicts":
      return 2;
    case "reviewed by expert panel":
      return 3;
    case "practice guideline":
      return 4;
    default:
      return 0;
  }
}
</script>
