<template>
  <div class="main-window">
    <PortalHeader />
    <div id="wrapper" class="page-wrapper">
      <div
        :class="{ shrunk: sideMenuIsCollapsed }"
        class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion">
        <ul id="accordionSidebar" class="menu-wrapper">
          <li
            v-if="authManager.loggedIn"
            class="user-greetings-block"
            :class="{ 'user-greetings-collapsed': sideMenuIsCollapsed }">
            <div class="d-flex">
              <span class="avatar">{{ getFirstLetter(firstName) }}{{ getFirstLetter(lastName) }}</span>
            </div>
            <span class="user-name" :class="{ 'hide-user-name': sideMenuIsCollapsed }">
              Hello, <br />
              <b>{{ firstName }} {{ lastName }}</b>
            </span>
          </li>
          <li>
            <div class="divider"></div>
          </li>
          <li class="nav-item" @click:class="active">
            <router-link :to="{ name: 'my-phrases' }" class="nav-link">
              <img src="@/assets/images/dashboard-sidebar.svg" alt="dashboard" />
              <span>My Phrases</span>
            </router-link>
          </li>

          <li class="nav-item" @click:class="active">
            <router-link :to="{ name: 'home' }" class="nav-link">
              <img src="@/assets/images/pencil-and-paper.svg" alt="new phrase" />
              <span>Create New Phrase</span>
            </router-link>
          </li>

          <li class="nav-item" @click:class="active">
            <router-link :to="{ name: 'eli8' }" class="nav-link">
              <img src="@/assets/images/pencil-and-paper.svg" alt="patien education" />
              <span>Patient Education</span>
            </router-link>
          </li>

          <li>
            <div class="divider"></div>
          </li>
          <li v-if="authManager.loggedIn" class="nav-item login">
            <a class="nav-link" @click="logoutUser">
              <img src="@/assets/images/logout.svg" alt="" />
              <span>Sign out</span>
            </a>
          </li>
          <li v-else class="nav-item">
            <router-link :to="{ name: 'login' }" class="nav-link">
              <img src="@/assets/images/logout.svg" alt="" />
              <span>Login</span>
            </router-link>
          </li>

          <li class="action-menu-block">
            <div class="action-menu-buttons">
              <a
                href="https://docs.google.com/forms/d/1YW86-UbM2ptuv5A7Ow9ZEIiE3t-hVdhIxpJ-eJvX-J4/edit?ts=6362a314"
                target="_blank">
                <img src="@/assets/images/grey-envelop.svg" alt="envelop" /> <span>Feedback</span>
              </a>
            </div>
          </li>
        </ul>

        <ul class="agreement-and-policy">
          <li class="nav-item" @click:class="active">
            <router-link target="_blank" :to="{ name: 'user-agreement' }" class="nav-link">
              <img src="@/assets/images/user_agreement.svg" alt="user-agreement" />
              <span>End User Agreement</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link target="_blank" :to="{ name: 'privacy-policy' }" class="nav-link">
              <img src="@/assets/images/privacy_policy.svg" alt="privacy-policy" />
              <span>Privacy Policy</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div id="content-wrapper" class="d-flex flex-column content-wrapper">
        <div id="content" class="content">
          <slot></slot>
        </div>
        <footer class="footer">
          <span>
            Copyright &copy; LamarHealth {{ currentYear }}
            <small class="opacity-50">{{ appVersion }}</small>
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useInterfaceElementsStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores";
import { getFirstLetter } from "@/utils";
import PortalHeader from "@/components/PortalHeader.vue";
import { useRouter } from "vue-router";
import { onUpdated } from "vue";

const appVersion = process.env.VUE_APP_VERSION;
const router = useRouter();

const { sideMenuIsCollapsed } = storeToRefs(useInterfaceElementsStore());

const authManager = useAuthStore();

const { firstName, lastName } = authManager.userInfo;

const currentYear = new Date().getFullYear();

async function logoutUser() {
  await authManager.logout();
  window.location.reload();
}

onUpdated(async () => {
  if (!authManager.loggedIn) router.push({ name: "login" });
});
</script>

<style lang="sass" scoped>

.page-wrapper
  position: inherit
  height: calc(100% - 52px)

  @media (max-height: 650px)
    height: 100%

  @media (max-height: 595px)
    height: calc(100% + 30px)
  .navbar-nav
    padding-top: 16px
    justify-content: space-between

    &.shrunk

      img
        margin-left: 0 !important

      span
        margin-left: 0 !important


  .menu-wrapper,
  .agreement-and-policy
    padding-left: 0
    margin-bottom: 0

    .user-greetings-block
      display: flex
      align-items: center
      padding-left: 27px
      margin-bottom: 15px

      .avatar
        display: flex
        justify-content: center
        align-items: center
        border-radius: 25px
        color: #fff
        background-color: $blue
        height: 34px
        width: 34px

      .hide-user-name
        display: none

      .user-name
        margin-left: 12px
        color: $gray-300

    .user-greetings-collapsed
      display: flex
      align-items: center
      flex-direction: row
      align-content: center
      justify-content: center
      padding-left: 0

    .divider
      margin: 0 auto
      height: 1px
      background-color: $gray-850
      width: 80%

    .action-menu-block
      padding-top: 22px
      color: $gray-300

      .action-menu-buttons
        display: flex
        flex-direction: column
        align-items: center
        width: 90%
        margin: 0 auto
        text-align: center

        a
          color: $gray-300
          font-size: 14px
          width: inherit
          text-decoration: none
          border: 1px solid $gray-300
          border-radius: 6px
          padding: 6px
          margin-top: 12px

    .nav-item
      cursor: pointer

      &:hover
        background-color: $gray-800
        display: flex
        align-items: center

        &:before
          position: absolute
          content: url("@/assets/images/hr-before-menu-items.svg")

      .nav-link
        padding: 12px

        span, img
          margin-left: 16px


  .active
    background-color: $gray-800

  .content-wrapper
    height: 100%
    justify-content: space-between

    .content
      height: calc(100% - 44px)
      overflow-y: scroll

    .footer
      display: flex
      justify-content: center
      padding: 10px 0
      background: $gray-100

  .bg-gradient-primary
      background-color: $gray-900 !important
      background-image: none
</style>
