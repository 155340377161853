<template>
  <div class="tab-wrapper">
    <span v-if="backToPhrasesButton" class="back-to-phrases-button" @click="backToPhrases">
      <img src="@/assets/images/close-open-menu.svg" alt="open-close" />
      <span> My Phrases</span>
    </span>
    <div class="tab-main-block">
      <span
        v-if="props.currentTab === 'clinVar' && !currentSearchTerms.length"
        class="d-flex text-secondary justify-content-center mt-3 call-to-action">
        Enter a mutation and select a gene OR select variant from search bar suggestions.
      </span>

      <ResultsPaper
        v-if="selectedPaper"
        :selected-data-item="selectedPaper"
        :get-single-paper="props.getSinglePaper"
        :current-tab="props.currentTab"
        @choose-data-related-paper="selectDataRelatedPaper"
        @back-to-list="selectDataItemPaper">
        <template #paper-view="slot">
          <slot name="paper-view" :item-content="slot.itemContent"></slot>
        </template>
      </ResultsPaper>
      <div v-else-if="newPageIsLoading" class="d-flex text-secondary justify-content-center spinner-container">
        <div class="spinner-border" role="status"></div>
      </div>
      <span v-else>
        <ResultsList
          v-if="relatedPapers"
          :data-list="relatedPapers"
          :data-list-header="relatedPapersOrigin"
          :current-tab="props.currentTab"
          @choose-single-data-item="selectDataItemPaper">
          <template #list-view="slot">
            <slot name="list-view" :item-info="slot.itemInfo"></slot>
          </template>
        </ResultsList>
        <ResultsList
          v-if="addOnPapers && pagingOffset === 0"
          :data-list="addOnPapers"
          :data-list-header="addOnPapersHeader"
          :paging-offset="pagingOffset"
          :current-tab="props.currentTab"
          @choose-single-data-item="selectDataItemPaper">
          <template #list-view="slot">
            <slot name="list-view" :item-info="slot.itemInfo"></slot>
          </template>
        </ResultsList>
        <ResultsList
          :data-list="foundPapers"
          :paging-offset="pagingOffset"
          :current-tab="props.currentTab"
          @choose-single-data-item="selectDataItemPaper">
          <template #list-view="slot">
            <slot name="list-view" :item-info="slot.itemInfo"></slot>
          </template>
        </ResultsList>
      </span>
      <span v-if="currentSearchTerms.length && !foundPapers.length && !newPageIsLoading" class="not-found">
        No record found. Try editing your search criteria.
      </span>
    </div>
    <div v-if="foundPapers.length > 0 && !selectedPaper" class="pagination-wrapper">
      <nav aria-label="Page navigation">
        <paginate
          v-model="currentPage"
          :page-count="numPages"
          :page-range="5"
          :click-handler="switchPage"
          :container-class="'pagination'"
          :prev-text="'‹'"
          :next-text="'›'">
        </paginate>
      </nav>
    </div>
    <div
      v-if="props.currentTab === 'omim' || props.currentTab === 'geneReviews'"
      class="genes-interactive-information-footer">
      <span v-if="props.currentTab === 'omim'">
        Copyright &copy; 1966 – {{ new Date().getFullYear() }}, Johns Hopkins University. All rights reserved.
      </span>
      <span v-if="props.currentTab === 'geneReviews'">
        Copyright &copy; 1993 – {{ new Date().getFullYear() }}, University of Washington. All rights reserved.
      </span>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, shallowRef, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import Paginate from "vuejs-paginate-next";

import { useGenSearchCriteriaStore, usePubMedRelatedPapersStore } from "@/stores";

import ResultsPaper from "./ResultsPaper";
import ResultsList from "./ResultsList";

const props = defineProps({
  getListFunction: {
    type: Function,
    required: true,
  },
  getSinglePaper: {
    type: Function,
    default: () => null,
  },
  getRelatedPaper: {
    type: Function,
    default: () => null,
  },
  getAddOnPapers: {
    type: Function,
    default: () => null,
  },
  getRelatedPapersList: {
    type: Function,
    default: () => null,
  },
  logPaperAction: {
    type: Function,
    default: () => null,
  },
  currentTab: {
    type: String,
    required: true,
  },
});

const { currentSearchTerms } = storeToRefs(useGenSearchCriteriaStore());
const { requestRelatedPubMedPapers } = storeToRefs(usePubMedRelatedPapersStore());

const router = useRouter();

const foundPapers = shallowRef([]);
const addOnPapers = shallowRef([]);
const relatedPapers = shallowRef([]);
const relatedPapersOrigin = ref("");
const selectedPaper = shallowRef(null);
const searchResultsInfo = shallowRef(null);
const newPageIsLoading = ref(false);
const currentPage = ref(1);
const numPages = ref(0);
const backToPhrasesButton = ref(true);
const pagingOffset = ref(0);

const addOnPapersHeader = ref(null);

onMounted(async () => {
  if (currentSearchTerms.value.length > 0) {
    fetchResults(currentSearchTerms.value);
    fetchAddOnPapers(currentSearchTerms.value);
  }
});

watch(currentSearchTerms, async (searchTerms) => {
  if (searchTerms.length) {
    fetchResults(searchTerms);
  } else {
    newPageIsLoading.value = false;
    foundPapers.value = [];
    relatedPapers.value = [];
    relatedPapersOrigin.value = "";
    selectedPaper.value = null;
    backToPhrasesButton.value = true;
    numPages.value = 0;
    currentPage.value = 1;
    requestRelatedPubMedPapers.value = { pmId: [], origin: "" };
  }
});

watch(currentSearchTerms, async (searchTerms) => {
  if (searchTerms.length) {
    fetchAddOnPapers(searchTerms);
  } else {
    addOnPapers.value = [];
    addOnPapersHeader.value = null;
  }
});

watch(requestRelatedPubMedPapers, async (relatedPubMedPapers) => {
  if (relatedPubMedPapers.pmId.length) {
    fetchRelatedPapers(relatedPubMedPapers);
  } else {
    relatedPapers.value = [];
    relatedPapersOrigin.value = "";
  }
});

async function fetchResults(searchTerms) {
  newPageIsLoading.value = true;
  pagingOffset.value = 0;
  const searchResults = await props.getListFunction(searchTerms, 0);
  if (currentSearchTerms.value.length > 0) {
    foundPapers.value = searchResults.pageItems;
    searchResultsInfo.value = searchResults.searchResultsInfo;
    numPages.value = searchResultsInfo.value.numPages;
    selectedPaper.value = null;
    currentPage.value = 1;
    newPageIsLoading.value = false;
  }
}

async function fetchAddOnPapers(searchTerms) {
  addOnPapers.value = await props.getAddOnPapers(searchTerms);
  if (addOnPapers.value && addOnPapers.value.length) {
    addOnPapersHeader.value = "ACMG Guidelines in PubMed Central";
  }
  selectedPaper.value = null;
}

async function switchPage(pageNum) {
  newPageIsLoading.value = true;
  const pageIdx = pageNum - 1;
  let newPageResults = null;
  if (props.currentTab === "pubMed") {
    const lastSearchInfo = searchResultsInfo.value;
    newPageResults = await props.getListFunction(lastSearchInfo, pageIdx);
  } else {
    newPageResults = await props.getListFunction(currentSearchTerms.value, pageIdx);
  }
  searchResultsInfo.value = newPageResults.searchResultsInfo;
  pagingOffset.value = pageIdx * searchResultsInfo.value.pageSize;
  foundPapers.value = newPageResults.pageItems;
  selectedPaper.value = null;
  newPageIsLoading.value = false;
}

async function fetchRelatedPapers(relatedPapersInfo) {
  newPageIsLoading.value = true;
  const relatedPapersResults = await props.getRelatedPapersList(relatedPapersInfo);
  if (relatedPapersResults) {
    relatedPapersOrigin.value = relatedPapersResults.origin + " Related Publications";
    relatedPapers.value = relatedPapersResults.pageItems;
  }
  newPageIsLoading.value = false;
}

function selectDataItemPaper(paper) {
  backToPhrasesButton.value = false;
  selectedPaper.value = paper;

  if (paper) {
    props.logPaperAction(paper);
  } else {
    backToPhrasesButton.value = true;
  }
}

async function selectDataRelatedPaper(paperId) {
  let paper = await props.getRelatedPaper(paperId);
  selectDataItemPaper(paper);
}

function backToPhrases() {
  router.push({ name: "my-phrases" });
}
</script>
