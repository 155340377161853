<template>
  <GeneticSearchResults
    :get-list-function="getListFunction"
    :get-add-on-papers="getAddOnPapers"
    :get-single-paper="getSinglePaper"
    :get-related-papers-list="getRelatedPapersList"
    :log-paper-action="logPaperAction"
    :current-tab="'pubMed'">
    <template #list-view="slot">
      <div class="element-content">
        <span class="tab-paper-header">
          <span class="element-header">{{ slot.itemInfo.item.title }}</span>
        </span>

        <span class="block-span">
          <span v-if="slot.itemInfo.item.authorsShort && slot.itemInfo.item.authorsShort.length > 0">
            <span v-for="author in slot.itemInfo.item.authorsShort.slice(0, 5)" :key="author">{{ author }}, </span>
            <span> et al.</span>
          </span>
          <span v-else-if="slot.itemInfo.item.authors">
            <span v-for="author in slot.itemInfo.item.authors.slice(0, 3)" :key="author">
              {{ authorNamePreprocess(author) }},
            </span>
            <span> et al.</span>
          </span>
        </span>
        <span v-if="slot.itemInfo.item.abstract" class="block-span">
          {{
            slot.itemInfo.item.abstract.length > 256
              ? slot.itemInfo.item.abstract.substring(0, 256) + "..."
              : slot.itemInfo.item.abstract
          }}
        </span>
        <span class="citation">{{ slot.itemInfo.item.citation }}</span>

        <span class="pmcid block-span">
          <span v-if="slot.itemInfo.item.pmId">
            <span class="gray-auxiliary-information">PMID: </span>
            <span>{{ slot.itemInfo.item.pmId }}</span>
          </span>
          <span v-if="slot.itemInfo.item.pmcId" class="orange-auxiliary-information"> &emsp;Free PMC Article </span>
          <span
            v-if="slot.itemInfo.item.publicationType && slot.itemInfo.item.publicationType.length"
            class="green-auxiliary-information">
            &emsp;{{ publicationTypePreprocess(slot.itemInfo.item.publicationType) }}
          </span>
        </span>
      </div>
    </template>

    <template #paper-view="slot">
      <PaperSideMenu
        v-if="slot.itemContent.downloaded && slot.itemContent.downloaded.parsedPaperData"
        :parsed-data="slot.itemContent.downloaded.parsedPaperData.textSections">
      </PaperSideMenu>
      <div class="tab-body-right" :class="{ 'shrunk-menu': userInterfaceElements.geneExplorerMenuShrunk }">
        <div class="paper-header">
          <div class="tab-paper-header">
            <div class="right-tab-paper-header-info">
              <span class="paper-mim-number">
                {{ slot.itemContent.content.citation }}
              </span>
            </div>
            <div class="pmcid">
              <span v-if="slot.itemContent.content.pmcId">
                <span class="gray-auxiliary-information">PMCID: </span>
                <span>{{ slot.itemContent.content.pmcId }}</span>
                <br />
              </span>
              <span v-if="slot.itemContent.content.pmId">
                <span class="gray-auxiliary-information">PMID: </span>
                <span>{{ slot.itemContent.content.pmId }}</span>
              </span>
            </div>
          </div>
        </div>
        <br />
        <div class="bold-title">
          <InteractiveJsonParagraph :paragraph="slot.itemContent.content.title"> </InteractiveJsonParagraph>
        </div>

        <span v-for="author in slot.itemContent.content.authors" :key="author">
          {{ authorNamePreprocess(author) }};
        </span>

        <div
          v-if="slot.itemContent.content.authorAffiliation && slot.itemContent.content.authorAffiliation.length > 0"
          class="author-information">
          <span
            class="blue-auxiliary-information"
            data-bs-toggle="collapse"
            href="#collapse-authors-block"
            role="button"
            aria-expanded="false"
            aria-controls="#collapse-authors-block">
            Author Information <img src="@/assets/images/check-mark.png" alt="check-mark" />
          </span>
          <div class="row">
            <div class="col">
              <div id="collapse-authors-block" class="collapse multi-collapse">
                <div class="card-body">
                  <ul v-for="(author, index) in slot.itemContent.content.authors" :key="author">
                    <li class="collapse-author-header black-auxiliary-information">
                      {{ authorNamePreprocess(author) }}
                    </li>
                    <li v-if="slot.itemContent.content.authorAffiliation[index]">
                      {{ processAuthorAffiliation(slot.itemContent.content.authorAffiliation[index]) }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div v-if="slot.itemContent.downloaded">
          <span v-if="slot.itemContent.downloaded.parsedPaperData">
            <InteractiveSectionList
              :nodes="slot.itemContent.downloaded.parsedPaperData.textSections"
              :paper-type="paperType"
              :paper-id="slot.itemContent.content.pmcId">
            </InteractiveSectionList>
          </span>
          <span v-else>
            <InteractiveJsonSectionList
              :nodes="slot.itemContent.downloaded.jsonPaperData"
              :citation-text="citationText">
            </InteractiveJsonSectionList>
          </span>
        </div>
        <div v-else>
          <span v-if="slot.itemContent.content.abstract">
            <span class="section-title section-title-break">Abstract</span>
            <InteractiveJsonParagraph :paragraph="slot.itemContent.content.abstract" :citation-text="citationText">
            </InteractiveJsonParagraph>
          </span>
        </div>

        <div v-if="slot.itemContent.failure && slot.itemContent.content.pmcId" class="call-to-action">
          <span>
            Something went wrong and we couldn't load the paper. Click
            <a target="_blank" :href="'https://www.ncbi.nlm.nih.gov/pmc/articles/' + slot.itemContent.content.pmcId">
              here <i class="fa fa-external-link ms-1"></i>
            </a>
            to view in PubMed Central.
          </span>
        </div>
        <div
          v-else-if="slot.itemContent.failure && !slot.itemContent.content.pmcId && slot.itemContent.content.pmId"
          class="call-to-action">
          <span>
            Something went wrong and we couldn't load the paper. Click
            <a target="_blank" :href="'https://pubmed.ncbi.nlm.nih.gov/' + slot.itemContent.content.pmId">
              here <i class="fa fa-external-link ms-1"></i>
            </a>
            to view in PubMed.
          </span>
        </div>
        <div
          v-else-if="!slot.itemContent.downloaded && !slot.itemContent.content.pmcId && slot.itemContent.content.pmId"
          class="call-to-action">
          <span>
            No free full text currently available. Click
            <a target="_blank" :href="'https://pubmed.ncbi.nlm.nih.gov/' + slot.itemContent.content.pmId">
              here <i class="fa fa-external-link ms-1"></i>
            </a>
            to view in PubMed.
          </span>
        </div>
        <div
          v-else-if="!slot.itemContent.downloaded && !slot.itemContent.failure && slot.itemContent.content.pmcId"
          class="d-flex text-secondary justify-content-center spinner-container">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </template>
  </GeneticSearchResults>
</template>

<script setup>
import GeneticSearchResults from "../../geneticSearchResults/GeneticSearchResults.vue";
import { ref } from "vue";
import { geneticExplorerService, analyticsService } from "@/services";
import { authorNamePreprocess } from "@/utils";
import { useInterfaceElementsStore } from "@/stores";
import PaperSideMenu from "@/components/genetic-search/genetic-explorer-tools/PaperSideMenu";
import InteractiveJsonParagraph from "@/components/genetic-search/genetic-explorer-tools/InteractiveJsonParagraph";
import InteractiveSectionList from "@/components/genetic-search/genetic-explorer-tools/InteractiveSectionList";
import InteractiveJsonSectionList from "@/components/genetic-search/genetic-explorer-tools/InteractiveJsonSectionList";

const userInterfaceElements = useInterfaceElementsStore();
const citationText = ref("");

function getListFunction(searchTerms, pageIdx) {
  return geneticExplorerService.searchPubMed(searchTerms, pageIdx);
}

function getSinglePaper(paperId) {
  return geneticExplorerService.getPubMedDetailed(paperId);
}

function getRelatedPapersList(relatedPapers) {
  return geneticExplorerService.getPubMedRelated(relatedPapers);
}

function logPaperAction(paper) {
  analyticsService.logGeneticExplorerPaperAction({
    actionType: "paper-selected",
    paperType: paper.pmcId ? "pmc" : "pubmed",
    paperTitle: paper.title,
    paperId: paper.pmcId ? paper.pmcId : paper.pmId,
  });
  citationText.value = paper.pmId ? ` (PMID: ${paper.pmId})` : ` (PMCID: ${paper.pmcId})`;
}

function getAddOnPapers(searchTerms) {
  return geneticExplorerService.searchPubMedGuidelines(searchTerms);
}

const paperType = "pubmed";

const publicationTypeMap = {
  Review: "Review",
  "Systematic Review": "Systematic Review",
  "Meta-Analysis": "Meta-Analysis",
  "Practice Guideline": "Practice Guideline",
  Guideline: "Guideline",
  "Consensus Development Conference": "Guideline",
  "Consensus Development Conference, NIH": "Guideline",
  "Clinical Study": "Clinical Trial",
  "Clinical Trial": "Clinical Trial",
  "Clinical Trial, Phase I": "Clinical Trial",
  "Clinical Trial, Phase II": "Clinical Trial",
  "Clinical Trial, Phase III": "Clinical Trial",
  "Clinical Trial, Phase IV": "Clinical Trial",
  "Clinical Trial Protocol": "Clinical Trial",
  "Adaptive Clinical Trial": "Clinical Trial",
  "Controlled Clinical Trial": "Clinical Trial",
  "Randomized Controlled Trial": "Clinical Trial",
  "Pragmatic Clinical Trial": "Clinical Trial",
};

function publicationTypePreprocess(publicationType) {
  // return value of PublicationTypeMap if key is in publicationType
  for (const key in publicationTypeMap) {
    if (publicationType.includes(key)) {
      return publicationTypeMap[key];
    }
  }
}

// add spaces between lowercase and uppercase letters, unless abbreviation
// remove hash prefix if author has multiple affiliations
function processAuthorAffiliation(string) {
  if (string.startsWith("grid.")) {
    string = string.substring(32);
  }
  string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
  string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  return string;
}
</script>
