<template>
  <Waypoint @change="viewChange">
    <div class="paragraph-content-wrapper">
      <div class="export-btn-wrapper">
        <div class="export-paragraph-btn" @click="exportParagraph">
          <div class="plus-img-container">
            <img src="@/assets/images/blue-cross.svg" alt="add-paragraph" />
          </div>
          <span class="paragraph-hint">Copy</span>
        </div>
      </div>
      <div
        class="paragraph-content"
        @click="exportSentence"
        v-html="sanitizeHtml(displayContent, paragraphRules)"></div>
    </div>
  </Waypoint>
</template>

<script setup>
import { inject } from "vue";
import analyticsManager from "@/logic/analytics/geneExplorerStatsManager.js";
import { Waypoint } from "vue-waypoint";
import sanitizeHtml from "sanitize-html";
import { paragraphRules } from "@/utils/htmlSanitizeRules";

const sentenceExp = /<lh-interactive-sentence([^>]+)>(.*?)<\/lh-interactive-sentence>/g;
const tableExp = /<\/?table.*?>/g;
const idAttrExp = /(?<=\Whash=['"]?)\w+/g;
const linkHrefExp = /<a[^>]* href=["|'](.*?)["|']/g;
const imgSrcExp = /<img[^>]* src=["|'](.*?)["|']/g;
const redirectUrl = "https://www.ncbi.nlm.nih.gov";
const s3StorageUrl = "https://lamarhealth-portal-public.s3.amazonaws.com/media";
const contentAddedCls = "content-added";

let paragraphDisplayed = false;

const props = defineProps({
  paragraph: {
    type: Object,
    required: true,
    writable: false,
  },
  paperType: {
    type: String,
    default() {
      return "";
    },
  },
  paperId: {
    type: String,
    default() {
      return "";
    },
  },
});

const eventsEmitter = inject("emitter");

// eslint-disable-next-line vue/no-setup-props-destructure
const srcContent = props.paragraph.content;
const displayContent = srcContent
  .replace(sentenceExp, (_, tagsMatch, contentMatch) => {
    const id = tagsMatch.match(idAttrExp);
    return `<span class="interactive-sentence" id="${id[0]}">${contentMatch}</span>&nbsp;`;
  })
  .replace(linkHrefExp, (_, redirectLink) => {
    if (redirectLink.startsWith("#")) {
      return `<a href=${redirectLink}`;
    } else if (redirectLink.startsWith("http")) {
      return `<a target='_blank' href="${redirectLink}"`;
    } else {
      return `<a target='_blank' href="${redirectUrl}${redirectLink}"`;
    }
  })
  .replace(imgSrcExp, (_, fileLocation) => {
    if (props.paperId.length > 0 && props.paperType.length > 0) {
      return `<img src="${s3StorageUrl}/${props.paperType}/${props.paperId}/${fileLocation}"`;
    }
    return `<img src="${redirectUrl}${fileLocation}"`;
  });

const exportContent = srcContent.match(tableExp) ? getTableMatrix(srcContent) : srcContent.replace(sentenceExp, "$2");

function viewChange(waypointState) {
  const goingVisible = waypointState.going == "IN";
  if (!paragraphDisplayed && goingVisible) {
    analyticsManager.paragraphViewed(props.paragraph);
  }
  paragraphDisplayed = goingVisible;
}

function exportParagraph(event) {
  exportText(exportContent, false);
  analyticsManager.paragraphUsed(props.paragraph);
  event.target.classList.add(contentAddedCls);
}

function exportSentence(event) {
  const clickTarget = event.target;
  //if element has not nearest parent we add element on which was event target
  const exportEl = clickTarget.closest(".interactive-sentence") || clickTarget;

  if (clickTarget.tagName.toLowerCase() !== "a") {
    exportText(exportEl.innerHTML, true);
    analyticsManager.paragraphSentUsed(props.paragraph, exportEl.id);
    event.target.classList.add(contentAddedCls);
  }
}

function exportText(content, isSentence) {
  content += props.paragraph.citationText;
  eventsEmitter.emit("exporting-text-element", { content, isSentence });
}

function getTableMatrix(content) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/xml");
  const table = doc.querySelector("table");
  const rows = table.querySelectorAll("tr");
  const ths = table.querySelectorAll("th");
  const thsText = Array.from(ths).map((th) => (th.children.length ? stringifyNestedNodes(th) : th.textContent));
  const rowsCells = thsText.length ? Array.from(rows).slice(1) : Array.from(rows);
  const rowsText = rowsCells.map((row, rowIndex) => {
    const tds = row.querySelectorAll("td");
    const tdsText = Array.from(tds).map((td, tdIndex) => {
      const rowspan = td.getAttribute("rowspan");
      const colspan = td.getAttribute("colspan");
      const text = td.children.length ? stringifyNestedNodes(td) : td.textContent;
      if (rowspan && rowspan > 1) {
        td.setAttribute("rowspan", 1);
        for (let i = 1; i < rowspan; i++) {
          const nextRow = rows[i + rowIndex + 1];
          const emptyCell = document.createElement("td");
          emptyCell.textContent = text;
          nextRow.insertBefore(emptyCell, nextRow.children[tdIndex]);
        }
      }
      if (colspan && colspan > 1) {
        td.setAttribute("colspan", 1);
        for (let i = 1; i < colspan; i++) {
          const emptyCell = document.createElement("td");
          emptyCell.textContent = text;
          const nextCell = tds[tdIndex + i];
          if (!nextCell) {
            row.appendChild(emptyCell);
          } else {
            nextCell.parentNode.insertBefore(emptyCell, nextCell);
          }
        }
      }
      return thsText[tdIndex] ? `${thsText[tdIndex]} - ${text}` : text;
    });
    return tdsText.join("<br />");
  });
  return rowsText.map((row, index) => `${index + 1}. ${row}`).join("<br />");
}

function stringifyNestedNodes(node) {
  const text = [];
  node.querySelectorAll("sup").forEach((n) => {
    const text = n.textContent;
    n.textContent = `[${text}]`;
  });
  node.childNodes.forEach(function check(child) {
    if (child.nodeType === Node.TEXT_NODE) {
      text.push(child.nodeValue.trim());
    }
    child.childNodes.forEach(check);
  });
  return text.join(" ");
}
</script>

<style lang="sass">
$add-text-highlite: $blue-200

.paragraph-content-wrapper
  display: flex
  justify-content: space-between
  position: relative
  margin-bottom: 12px

  .export-btn-wrapper
    width: 8%

    .export-paragraph-btn
      display: flex
      position: absolute
      background: $white
      align-items: center
      border-radius: 25px
      cursor: pointer

      .plus-img-container
        padding: 10.5px
        display: flex
        justify-content: center
        align-items: center

      .paragraph-hint
        display: none
        pointer-events: none
        margin-left: 0px
        color: $blue
        padding-right: 10px
        font-size: 14px
        font-weight: 500

    &:hover .paragraph-hint
      display: block

    &:hover + .paragraph-content .interactive-sentence
      background-color: $add-text-highlite
      border-radius: 2px

  .paragraph-content
    width: 92%

  .interactive-sentence
    cursor: pointer

    &:hover
      background-color: $add-text-highlite
      border-radius: 2px

    &:hover::after
      margin-top: -13px
      position: absolute
      color: $blue
      content: url("@/assets/images/add-sentence.svg")
</style>
