<template>
  <div class="sentences-reviewer-wrapper">
    <span class="reviewer-title">Edit or Approve the highlighted simplification.</span>

    <textarea
      id="sentenceArea1"
      v-model="currentEditableSentence"
      placeholder="Simplification text suggestion"
      class="classic-text-area"></textarea>

    <div class="reviewer-buttons-wrapper">
      <button @click="chooseNextId('prev')">Prev</button>
      <div class="choose-sentence">
        <span class="sentence-status" @click="switchSentenceStatus">{{ sentenceStatus }}</span>
        <label class="switch">
          <input
            type="checkbox"
            class="original-simplified-checkbox"
            :class="{ checked: sentenceStatus === 'original' }"
            @click="switchSentenceStatus" />
          <span class="slider round"></span>
        </label>
      </div>
      <button @click="chooseNextId('next')">Next</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { eli8Service } from "@/services";
const props = defineProps({
  simplificationResult: {
    type: Object,
    required: true,
  },
  originalSentences: {
    type: Object,
    required: true,
  },
  isUserInput: {
    type: Boolean,
    required: true,
  },
  simplifiedResultId: {
    type: Number,
    required: true,
  },
});

const selectedSentenceId = ref(0);
const currentEditableSentence = ref(null);
const sentenceStatus = ref(null);

let quantityOfSentences = Object.keys(props.simplificationResult.sentences).length;

onMounted(() => {
  currentEditableSentence.value = props.simplificationResult.sentences[selectedSentenceId.value];
  sentenceStatus.value = "simplified";
});

function switchSentenceStatus() {
  sentenceStatus.value = sentenceStatus.value === "simplified" ? "original" : "simplified";

  if (sentenceStatus.value === "simplified") {
    currentEditableSentence.value = props.simplificationResult.sentences[selectedSentenceId.value];
  } else {
    currentEditableSentence.value = props.originalSentences.sentences[selectedSentenceId.value];
  }
}

const emit = defineEmits(["switcherToNewSentence", "sentenceContentUpdater", "switcherToFinalCheckPage"]);
const switcherToNewSentence = (newId) => emit("switcherToNewSentence", newId);
const sentenceContentUpdater = (newContent) => emit("sentenceContentUpdater", newContent);
const switcherToFinalCheckPage = (reviewedAllSentences) => emit("switcherToFinalCheckPage", reviewedAllSentences);

function chooseNextId(direction) {
  if (direction === "next" && selectedSentenceId.value < quantityOfSentences - 1) {
    saveEditedSentence();
    ++selectedSentenceId.value;
    sentenceStatus.value = "simplified";
  } else if (direction === "prev" && selectedSentenceId.value > 0) {
    --selectedSentenceId.value;
    sentenceStatus.value = "simplified";
  } else if (direction === "next" && selectedSentenceId.value >= quantityOfSentences - 1) {
    saveEditedSentence();
    sentenceStatus.value = null;
    switcherToFinalCheckPage(true);
  }
  sentenceContentUpdater(currentEditableSentence.value);
}

async function saveEditedSentence() {
  const data = {
    editedSentence: currentEditableSentence.value,
    sentenceId: selectedSentenceId.value,
    isUserInput: props.isUserInput,
  };
  await eli8Service.updateReviewedSentence(props.simplifiedResultId, data);
}

watch(selectedSentenceId, (newId) => {
  switcherToNewSentence(newId);

  currentEditableSentence.value = props.simplificationResult.sentences[newId];

  scrollingInOriginalBlock();
  scrollingInPredictedBlock();
});

function scrollingInOriginalBlock() {
  let originalSentence = document.getElementById("original-sentence-" + selectedSentenceId.value);

  originalSentence.parentNode.parentNode.scrollTop = originalSentence.offsetTop;
}

function scrollingInPredictedBlock() {
  let predictedSentence = document.getElementById("predicted-sentence-" + selectedSentenceId.value);

  predictedSentence.parentNode.parentNode.parentNode.scrollTop = predictedSentence.offsetTop;
}
</script>

<style lang="sass" scoped>
.sentences-reviewer-wrapper
    width: inherit
    box-shadow: 0px 5px 20px rgba(5, 46, 94, 0.1)
    border-radius: 16px
    padding: 20px 25px

    .reviewer-title
      display: block
      color: $blue-700
      font-size: 20px
      font-weight: 500
      margin-bottom: 15px

    .classic-text-area
      width: 100%
      min-height: 190px
      border: 2px solid $gray-350
      border-radius: 8px
      padding: 15px
      font-size: 20px
      color: $black
      margin-bottom: 10px

    .reviewer-buttons-wrapper
      display: flex
      position: relative
      justify-content: flex-end

      & :not(:last-child)
        margin-right: 29px

      button
        padding: 11px 25px
        background: $blue
        border: none
        color: $white
        border-radius: 8px


    .choose-sentence
      display: flex
      flex-direction: column
      align-items: center
      position: relative

      .sentence-status
        margin: 25px 0 0 0
        position: absolute
        color: $gray-800
        font-weight: bold
        cursor: pointer


      .switch
        position: relative
        display: inline-block
        width: 60px
        height: 26px

        .original-simplified-checkbox
          opacity: 0
          width: 0
          height: 0

        .checked + .slider
            background-color: $gray-450

        .checked + .slider:before
            transform: translateX(34px)


        .slider
          position: absolute
          cursor: pointer
          top: 0
          left: 0
          right: 0
          background-color: $green
          bottom: 0
          transition: .4s

          &:before
            position: absolute
            content: ""
            height: 18px
            width: 18px
            left: 4px
            bottom: 4px
            background-color: white
            transition: .4s

        .round
          border-radius: 34px

          &:before
            border-radius: 50%
</style>
