<template>
  <div class="added-term-title">
    <span class="first-term-letter" :class="entryType">{{ firstTermLetter }}</span>
    <span class="term-title">{{ title }}</span>
    <span class="delete-search-term" @click="emit('deleteSearchTerm')"
      ><img src="@/assets/images/delete-selected-single-term.svg" alt="delete"
    /></span>
  </div>
</template>

<script setup>
import { unref } from "vue";

const props = defineProps({
  selectedItem: {
    type: Object,
    required: true,
  },
});

const { entryType, title } = unref(props.selectedItem);
const firstTermLetter = entryType.substr(0, 1).toUpperCase();

const emit = defineEmits(["deleteSearchTerm"]);
</script>

<style lang="sass" scoped>

.added-term-title
  display: inline-flex
  align-items: center

  .first-term-letter
    display: flex
    align-items: center
    justify-content: center
    font-size: 11px
    color: $white
    height: 25px
    width: 25px
    border-radius: 50px

  .term-title
    display: inline-block
    font-size: 14px
    font-weight: 400
    margin-left: 8px

  .delete-search-term
    display: flex
    justify-content: center
    align-items: center
    margin-left: 7px
</style>
