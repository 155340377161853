<template>
  <div class="tab-paper">
    <span class="back-to-list-button" @click="backToList">
      <img src="@/assets/images/close-open-menu.svg" alt="open-close" />
      <span> Back</span>
    </span>
    <div class="paper-body-wrapper">
      <div class="tab-paper-body">
        <slot
          name="paper-view"
          :item-content="{
            content: props.selectedDataItem,
            downloaded: fullPaperInfo,
            getRelated: chooseDataRelatedPaper,
            failure: failure,
          }"></slot>
      </div>
      <div
        v-if="!fullPaperInfo && !failure && props.currentTab !== 'pubMed'"
        class="d-flex text-secondary justify-content-center spinner-container">
        <div class="spinner-border" role="status"></div>
      </div>
      <div ref="firstStepTour"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUpdated, ref, shallowRef, watch } from "vue";
import { preprocessGeneExplorerPaper } from "@/logic/utils";
import { useInterfaceElementsStore } from "@/stores";
import { useShepherd } from "vue-shepherd";

const props = defineProps({
  selectedDataItem: {
    type: Object,
    required: true,
  },
  getSinglePaper: {
    type: Function,
    default: () => null,
  },
  getRelatedPaper: {
    type: Function,
    default: () => null,
  },
  currentTab: {
    type: String,
    required: true,
  },
});

const userInterfaceElements = useInterfaceElementsStore();
const firstStepTour = ref(null);

const fullPaperInfo = shallowRef(null);
const failure = ref(false);

const paperId = ref(null);
let paperInfo = null;
let preprocessInfo = null;

const tour = useShepherd({
  defaultStepOptions: {
    classes: "first-tour-step",

    scrollTo: { behavior: "smooth", block: "center" },
  },
});

onMounted(async () => {
  setSelectedPaperId();

  if (!userInterfaceElements.userGuideTourInfo.first) {
    tour.addStep({
      id: "first-tour-step",
      title: "Click on sentence or paragraph",
      attachTo: {
        element: firstStepTour.value,
        on: "top",
      },
      buttons: [
        {
          text: "Okay",
          action() {
            tour.cancel();
            userInterfaceElements.setNewGuideTourData("first");
          },
        },
        {
          classes: "close-tour-step-button",
          text: "×",
          action() {
            tour.cancel();
            userInterfaceElements.setNewGuideTourData("first");
          },
        },
      ],
    });

    tour.start();
  }
});

onUpdated(async () => {
  setSelectedPaperId();
});

watch(paperId, async (newId) => {
  fullPaperInfo.value = null;
  getFullPaperInfo(newId);
});

function setSelectedPaperId() {
  switch (props.currentTab) {
    case "geneReviews":
      paperId.value = props.selectedDataItem.nbkId;
      break;

    case "omim":
      paperId.value = props.selectedDataItem.mimNumber;
      break;

    case "publicPhrases":
      paperId.value = props.selectedDataItem.id;
      break;

    case "pubMed":
      paperId.value = props.selectedDataItem.pmcId;
      break;
  }
}

function preprocessSelectedPaper() {
  switch (props.currentTab) {
    case "geneReviews":
      return ` (GeneReviews: https://www.ncbi.nlm.nih.gov/books/${props.selectedDataItem.nbkId})`;

    case "omim":
      return ` (OMIM: ${props.selectedDataItem.mimNumber})`;

    case "publicPhrases":
      return "";

    case "pubMed":
      return props.selectedDataItem.pmId
        ? ` (PMID: ${props.selectedDataItem.pmId})`
        : ` (PMCID: ${props.selectedDataItem.pmcId})`;

    default:
      return null;
  }
}

async function getFullPaperInfo(newId) {
  preprocessInfo = preprocessSelectedPaper();
  try {
    paperInfo = await props.getSinglePaper(newId);

    if (paperInfo.parsedPaperData) preprocessGeneExplorerPaper(paperInfo, preprocessInfo);
    fullPaperInfo.value = paperInfo;
  } catch (error) {
    failure.value = true;
  }
}

const emit = defineEmits(["backToList", "chooseDataRelatedPaper"]);
const backToList = () => emit("backToList");
const chooseDataRelatedPaper = (element) => emit("chooseDataRelatedPaper", element);
</script>
