<template>
  <div class="eli8-page">
    <div class="eli8-page-header">
      <CustomSimplifier></CustomSimplifier>
      <div class="left-header">
        <h4>Or select an existing phrase to simplify</h4>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table table-bordered" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th class="paper-title">MY PHRASES</th>
            <th>DATE CREATED</th>
            <th>DATE MODIFIED</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody class="first-table-child">
          <tr v-for="note in preprocessNotesArray" :key="note" class="first-table-child" @click="openNote(note)">
            <td class="first-td">{{ note.title }}</td>
            <td>{{ convertDateFormatForNotes(note.dateCreated) }}</td>
            <td>{{ convertDateFormatForNotes(note.dateModified) }}</td>
            <td>
              <span
                v-if="note.noteStatus === 'simplified' || note.noteStatus === 'simplifiedUserInput'"
                class="simplified">
                Simplified
              </span>
              <span v-else class="simplify-me">Simplify Me</span>
            </td>
            <div v-if="note.noteStatus !== 'simplifyMe'" class="delete-container">
              <div v-if="deleteInProcess" class="spinner-border spinner-border-sm" role="status"></div>
              <span
                v-else
                class="delete"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                @click.stop="askDeleteConfirmation(note.title, note.noteStatus, note.id)">
                <img src="@/assets/images/trash.svg" alt="trash" /> Delete
              </span>
            </div>
          </tr>
        </tbody>
      </table>
      <div v-if="!preprocessNotesArray" class="d-flex text-secondary justify-content-center spinner-container">
        <div class="spinner-border" role="status"></div>
      </div>
    </div>
    <div
      id="staticBackdrop"
      class="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="staticBackdropLabel" class="modal-title">Delete Confirmation</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure that you want to delete smart phrase <span>"{{ noteOnDeletion.noteTitle }}"</span>?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="deleteSingleNote(noteOnDeletion.noteStatus, noteOnDeletion.id)">
              Delete
            </button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, shallowRef, onMounted } from "vue";
import { useRouter } from "vue-router";
import { convertDateFormatForNotes } from "@/utils";

import { eli8Service } from "@/services";
import CustomSimplifier from "./CustomSimplifier.vue";

const router = useRouter();
const allNotes = shallowRef(null);
const preprocessNotesArray = shallowRef(null);

const noteOnDeletion = ref({});
const deleteInProcess = ref(false);

onMounted(() => {
  getAllNotes();
});

async function getAllNotes() {
  allNotes.value = await eli8Service.getPublishedNotes();

  preprocessNotesArray.value = preprocessArrayOfNotes(allNotes.value);
}

function openNote(note) {
  if (note.noteStatus === "simplified") {
    if (note.reviewed === true) {
      router.push({ name: "simplified", params: { noteId: note.id } });
    } else {
      router.push({ name: "review-simplification", params: { noteId: note.clinicalNoteId } });
    }
  } else if (note.noteStatus === "simplifiedUserInput") {
    if (note.reviewed === true) {
      router.push({ name: "simplified-user-input", params: { noteId: note.id } });
    } else {
      router.push({ name: "review-simplification-user-input", params: { noteId: note.id } });
    }
  } else if (note.noteStatus === "simplifyMeUserInput") {
    router.push({ name: "simplify-draft-user-input", params: { noteId: note.id } });
  } else {
    router.push({ name: "simplify", params: { noteId: note.id } });
  }
}

function preprocessArrayOfNotes(notes) {
  const notesStatus = Object.keys(notes);

  let finalNotesArray = [];
  notesStatus.forEach((item, index) => {
    finalNotesArray[index] = notes[item].map((element) => ({ noteStatus: item, ...element }));
  });

  finalNotesArray = finalNotesArray[0].concat(finalNotesArray[1], finalNotesArray[2], finalNotesArray[3]);

  finalNotesArray.sort((newest, latest) => {
    return latest.dateModified.localeCompare(newest.dateModified);
  });

  return finalNotesArray;
}

function askDeleteConfirmation(title, status, noteId) {
  noteOnDeletion.value = { noteTitle: title, noteStatus: status, id: noteId };
}

async function deleteSingleNote(status, noteId) {
  try {
    deleteInProcess.value = true;

    if (status === "simplifiedUserInput" || status === "simplifyMeUserInput") {
      await eli8Service.deleteSimplifiedNote(noteId, true);
    } else {
      await eli8Service.deleteSimplifiedNote(noteId);
    }

    getAllNotes();
    deleteInProcess.value = false;
    errors.value = [];
  } catch (error) {
    displayError(error);
  }
}

function displayError(err) {
  // This result does not show anywhere for now, maybe it will be a pop-up on this page
  errors.value = tryParseApiErrors(err);
}
</script>

<style lang="sass" scoped>
@import "@/styles/components/_modal-window.scss"
@mixin button-shadow
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), -5px 0px 16px rgba(0, 0, 0, 0.08)

.eli8-page
  height: 100%
  padding-bottom: 20px
  background-color: $blue-700

  .eli8-page-header
    color: $white
    background-color: $blue-700
    width: inherit
    padding: 28px 12px 28px 52px

    .left-header
      width: inherit

      h4
        font-size: 25px
        font-weight: bold

  .table-wrapper

    table
      background-color: $white
      bordare-collapse: collapse
      position: relative

      thead
        background-color: $gray-350
        border: 1px solid $gray-400
        border-left: none
        border-right: none
        color: $gray-900
        font-weight: 600

        tr

          th
            padding: 20px 0
            border: none

          .paper-title
            padding-left: 60px

      tbody
        border-top: none
        padding: 8px 0 8px 20px

        tr
          cursor: pointer
          background-color: $blue-700
          color: $white
          font-weight: 400

          &:hover
            background-color: $white
            color: $blue-700

          &:hover > .delete-container
            display: flex
            justify-content: space-between
            color: $gray-900
            background-color: $white
            padding: 27px 30px
            @include button-shadow
            position: absolute
            right: 0

          .delete-container
            display: none

            .spinner-border-sm
              margin-top: 5px
            .delete:hover
              font-weight: 500

          td
            border: none

            text-align: left
            padding: 29px 0
            font-size: 14px
            font-weight: 400

            .simplify-me,
            .simplified
              padding: 4px 7px
              border-radius: 4px

            .simplify-me
              background-color: $yellow-200
              color: $yellow-500

            .simplified
              background-color: $green-100
              color: $green-500

          .first-td
            padding-left: 60px
            width: 45%
    .spinner-container
      margin: 15%
</style>
