import api from "@/services/api";

const { get, post, put, delete: del } = api.getPrefixedMethods("clinical-notes");

export const notesService = {
  getListOfNotes() {
    return get("");
  },
  createDraftNote(data) {
    return post("", data);
  },
  searchPeerNotes(searchTerms, pageInd) {
    return post(`search-peer-notes/p/${pageInd}`, searchTerms);
  },
  publishNote(draft_id, data) {
    return post(`draft/${draft_id}/complete`, data);
  },
  republishNote(completed_id, data) {
    return post(`completed/${completed_id}/edit`, data);
  },
  autosavingDraft(draft_id, data) {
    return put(`draft/${draft_id}`, data);
  },
  deleteDraftNote(draft_id) {
    return del(`draft/${draft_id}`);
  },
  deleteCompletedNote(completed_id) {
    return del(`completed/${completed_id}`);
  },
  fetchNoteForEditing(draft_id) {
    return get(`draft/${draft_id}`);
  },
  fetchPeerNote(peer_note_id) {
    return get(`peer-note/${peer_note_id}`);
  },
  createFromDraft(draft_id) {
    return post(`create-from-draft/${draft_id}`);
  },
  createFromCompleted(completed_id) {
    return post(`create-from-completed/${completed_id}`);
  },
};
