<template>
  <div class="paragraph-content-wrapper">
    <div class="export-btn-wrapper">
      <div class="export-paragraph-btn" @click="exportParagraph">
        <div class="plus-img-container">
          <img src="@/assets/images/blue-cross.svg" alt="add-paragraph" />
        </div>
        <span class="paragraph-hint">Copy</span>
      </div>
    </div>
    <div
      class="paragraph-content"
      @click="exportSentence"
      v-html="sanitizeHtml(displayContent, jsonParagraphRules)"></div>
  </div>
</template>

<script setup>
import { inject } from "vue";
import sanitizeHtml from "sanitize-html";
import { jsonParagraphRules } from "@/utils/htmlSanitizeRules";

const sentenceExp =
  /(?=[^])(?:\P{Sentence_Terminal}|\p{Sentence_Terminal}(?!['"`\p{Close_Punctuation}\p{Final_Punctuation}\s]))*(?:\p{Sentence_Terminal}+['"`\p{Close_Punctuation}\p{Final_Punctuation}]*|$)/guy;
const contentAddedCls = "content-added";

const props = defineProps({
  paragraph: {
    type: String,
    required: true,
    writable: false,
  },
  citationText: {
    type: String,
    default() {
      return "";
    },
  },
});

const eventsEmitter = inject("emitter");

// eslint-disable-next-line vue/no-setup-props-destructure
const srcContent = props.paragraph;
const displayContent = srcContent
  .match(sentenceExp)
  .map((sentence) => {
    return sentence.length ? `<span class="interactive-sentence">${sentence}</span>&nbsp;` : "";
  })
  .join("");

const exportContent = srcContent;

function exportParagraph(event) {
  exportText(exportContent, false);
  event.target.classList.add(contentAddedCls);
}

function exportSentence(event) {
  const clickTarget = event.target;
  //if element has not nearest parent we add element on which was event target
  const exportEl = clickTarget.closest(".interactive-sentence") || clickTarget;

  if (clickTarget.tagName.toLowerCase() !== "a") {
    exportText(exportEl.innerHTML, true);
    event.target.classList.add(contentAddedCls);
  }
}

function exportText(content, isSentence) {
  content += props.citationText;
  eventsEmitter.emit("exporting-text-element", { content, isSentence });
}
</script>

<style lang="sass" scoped>
$add-text-highlite: $blue-200

.paragraph-content-wrapper
  display: flex
  justify-content: space-between
  position: relative
  margin-bottom: 12px

  .export-btn-wrapper
    .export-paragraph-btn
      background: $white
      display: flex
      position: absolute
      align-items: center
      border-radius: 25px
      cursor: pointer

      .plus-img-container
        padding: 10.5px
        display: flex
        justify-content: center
        align-items: center

      .paragraph-hint
        display: none
        pointer-events: none
        margin-left: 0px
        color: $blue
        padding-right: 10px
        font-size: 14px
        font-weight: 500

    &:hover .paragraph-hint
      display: block

    &:hover + .paragraph-content .interactive-sentence
      background-color: $add-text-highlite
      border-radius: 2px

  .paragraph-content
    width: 92%

  .interactive-sentence
    cursor: pointer

    &:hover
      background-color: $add-text-highlite
      border-radius: 2px

    &:hover::after
      margin-top: -13px
      position: absolute
      color: $blue
      content: url("@/assets/images/add-sentence.svg")
</style>
