export function uid() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function cyrb53Hash(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function preprocessGeneExplorerPaper(paper, citationText) {
  let sectionCounter = 0;

  function preprocessSectionsChildren(contentBlocks) {
    contentBlocks.forEach((contentBlock) => {
      sectionCounter += 1;

      if (contentBlock.nodeType === "section") {
        contentBlock.id = sectionCounter + contentBlock.title.replace(/ /g, "");
        preprocessSectionsChildren(contentBlock.children);
      } else {
        contentBlock.citationText = citationText;
      }
    });
  }

  preprocessSectionsChildren(paper.parsedPaperData.textSections);
  if (paper.allelicVariants) preprocessSectionsChildren(paper.allelicVariants.parsedPaperData.textSections);
  if (paper.clinicalSynopsis) paper.clinicalSynopsis.citationText = citationText;
}
