<template>
  <div class="simplificator-wrapper">
    <div class="simplificator-header">
      <span class="back-to-list-button" @click="backToEli8Dashboard">
        <img src="@/assets/images/close-open-menu.svg" alt="open-close" />
        <span> Back</span>
      </span>
    </div>
    <div class="simplificator-body">
      <div class="left-phrase-block">
        <span class="phrase-description">Original Text</span>
        <div class="notes-name">
          <h4>{{ getTitle() }}</h4>
        </div>
        <div
          class="text-area-original"
          :class="{ 'text-area-new-height': isReviewRoute(route) && !allSentencesReviewed }">
          <div v-if="Object.keys(props.simplifiedResult).length > 0">
            <span
              v-for="(sentence, index) in props.simplifiedResult.originalSentences.sentences"
              :id="'original-sentence-' + index"
              :key="index"
              class="sentence"
              :class="{
                'editting-sentence': +index === sentenceOnReview && isReviewRoute(route) && !allSentencesReviewed,
              }">
              {{ sentence }}
              <br />
            </span>
          </div>
          <div v-else>
            <span v-for="(sentence, index) in props.note.originalSentences.sentences" :key="index" class="sentence">
              {{ sentence }}
              <br />
            </span>
          </div>
        </div>
        <button
          v-if="Object.keys(props.simplifiedResult).length < 1"
          :disabled="!props.note.isWithinTokenLimit || Object.keys(props.note.originalSentences.sentences).length < 1"
          class="simplify-button"
          @click="sendNoteToSimplification">
          <span v-if="props.simplificationProgress && !props.failure">Simplifying...</span>
          <span v-else>Simplify</span>
        </button>
      </div>
      <div class="right-phrase-block">
        <span class="phrase-description">Simplified text for patient</span>
        <br />
        <div class="notes-name">
          <h4>{{ getTitle() }} (Simplified)</h4>
          <span
            v-if="isSimplifiedRoute(route)"
            :to="{ name: 'printing-simplified-note' }"
            target="_blank"
            class="printer-img">
            <img src="@/assets/images/printer.svg" alt="print" @click="printSimplifiedNoteResult" />
          </span>
        </div>
        <div
          v-if="!isSimplifiedRoute(route)"
          id="text-area"
          class="text-area-predicted"
          :class="{ 'text-area-new-height': isReviewRoute(route) && !allSentencesReviewed }">
          <div
            v-if="!props.note.isWithinTokenLimit && !Object.keys(props.simplifiedResult).length > 0"
            class="simplification-info red-message">
            <span class="bold-simplification-info">Simplification limit reached.</span>
            <span> This is reset at the beginning of each month. </span>
          </div>
          <div v-if="props.failure" class="simplification-info red-message">
            <span class="bold-simplification-info">Simplification failed.</span>
            <span>
              Something went wrong and we couldn't start simplification. Please, contact us or try again later.
            </span>
          </div>
          <div v-else-if="props.simplificationProgress" class="simplification-info">
            <span class="bold-simplification-info">Simplification in progress...</span>
            <span>
              Average wait time = about 10 seconds per sentence. Page will automatically refresh. You can close this
              page at any time. A notification will be sent to your email once the job is complete.
            </span>
          </div>
          <div v-else-if="Object.keys(props.simplifiedResult).length > 0">
            <span>
              <span
                v-for="(sentence, index) in props.simplifiedResult.combinedSentences.sentences"
                :id="'predicted-sentence-' + index"
                :key="index"
                class="sentence"
                :class="{
                  'editting-sentence': +index === sentenceOnReview && isReviewRoute(route) && !allSentencesReviewed,
                }">
                {{ sentence }}
                <br />
              </span>
            </span>
          </div>
          <div v-if="isSimplifiedRoute(route) || isReviewRoute(route)" class="watermark">
            <span> Made with Lamar Health </span>
          </div>
        </div>
        <div v-else-if="isSimplifiedRoute(route)" class="editor-container">
          <ckeditor
            v-model="richSimplifiedSentences"
            :config="editorConfig"
            :editor="editorBuild"
            @ready="onEditorReady">
          </ckeditor>
          <div class="watermark">
            <span> Made with Lamar Health </span>
          </div>

          <div v-if="noteSaveProcess" class="text-secondary justify-content-center spinner-container">
            <span class="spinner-border spinner-border-sm" role="status"></span>
          </div>
        </div>

        <span v-if="Object.keys(props.simplifiedResult).length > 0" class="save-print-btn-wrapper">
          <button
            v-if="isReviewRoute(route) && allSentencesReviewed"
            class="save-button"
            @click="saveSimplifiedNoteResult">
            <span v-if="props.saveCopyProgress">Saving...</span>
            <span v-else>Save</span>
          </button>
          <button
            v-if="isSimplifiedRoute(route)"
            class="copy-button"
            :class="{ 'copied-button': copiedToEhr }"
            @click="copySimplifiedNoteResult()">
            <img v-if="copyButtonText === 'Copy to EHR'" src="@/assets/images/copy-img.svg" alt="copy to EHR" />
            {{ copyButtonText }}
          </button>
        </span>
      </div>
    </div>
    <SentencesReviewer
      v-if="isReviewRoute(route) && !allSentencesReviewed"
      :original-sentences="props.simplifiedResult.originalSentences"
      :simplification-result="props.simplifiedResult.combinedSentences"
      :is-user-input="isReviewUserInput(route)"
      :simplified-result-id="
        isReviewUserInput(route) ? props.simplifiedResult.id : props.simplifiedResult.clinicalNoteId
      "
      @switcher-to-new-sentence="sentenceSwitcher"
      @sentence-content-updater="writeNewContentToSentence"
      @switcher-to-final-check-page="showFinalCheckPage">
    </SentencesReviewer>
  </div>
</template>

<script setup>
import SentencesReviewer from "./SentencesReviewer.vue";
import { ref, watch, nextTick, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ckeditor, editorBuild, editorConfig } from "@/utils/ckeditor";
import { tryParseApiErrors } from "@/utils";
import { eli8Service } from "@/services";

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
  simplifiedResult: {
    type: Object,
    required: true,
  },
  simplificationProgress: {
    type: Boolean,
    default: false,
  },
  failure: {
    type: Boolean,
    default: false,
  },
  saveCopyProgress: {
    type: Boolean,
    default: false,
  },
  simplifiedSentences: {
    type: Object,
    default() {
      return {};
    },
  },
  content: {
    type: String,
    default: "",
  },
});

const router = useRouter();
const route = useRoute();

const noteSaveProcess = ref(false);
const errors = ref([]);
const sentenceOnReview = ref(0);
const allSentencesReviewed = ref(false);
const richSimplifiedSentences = ref("");
const copiedToEhr = ref(false);
const autoSaveJobInterval = ref(null);
const copyButtonText = ref("Copy to EHR");

nextTick(() => {
  if (isSimplifiedRoute(route)) {
    rewriteRichCollection();
  }
});

watch(
  () => props.simplifiedSentences,
  () => {
    rewriteRichCollection();
  }
);

watch(richSimplifiedSentences, (newContent) => {
  if (newContent) {
    if (!autoSaveJobInterval.value) startAutoSaveJob();
  }
});

onUnmounted(() => {
  clearInterval(autoSaveJobInterval.value);
});

function rewriteRichCollection() {
  if (props.content) {
    richSimplifiedSentences.value = props.content;
  } else {
    for (let key in props.simplifiedSentences.sentences) {
      richSimplifiedSentences.value += `<p>${props.simplifiedSentences.sentences[key]}</p>`;
    }
  }
}

function startAutoSaveJob() {
  let lastSavedContent = null;

  autoSaveJobInterval.value = setInterval(() => {
    const newContent = richSimplifiedSentences.value;

    if (newContent !== lastSavedContent) {
      noteSaveProcess.value = true;
      lastSavedContent = newContent;
      copyButtonText.value = "Copy to EHR";

      saveNoteWithRichText();
    }
  }, 5000);
}

async function saveNoteWithRichText() {
  copiedToEhr.value = false;
  const noteId = props.simplifiedResult.id;
  const data = { content: richSimplifiedSentences.value };

  try {
    await eli8Service.updateSimplifiedNoteContent(noteId, data, isUserInputRoute(route));
  } catch (error) {
    noteSaveProcess.value = false;
    displayError(error);
  }

  noteSaveProcess.value = false;
}

function displayError(err) {
  errors.value = tryParseApiErrors(err);
}

function backToEli8Dashboard() {
  router.push({ name: "eli8" });
}

// ---------------- CKEditor setup ------------------
let editorInstance = null;

function onEditorReady(editor) {
  editorInstance = editor;
}

const emit = defineEmits(["sendNoteToSimplification", "saveSimplifiedNoteResult", "newSentenceContent"]);
const sendNoteToSimplification = () => emit("sendNoteToSimplification");
const saveSimplifiedNoteResult = () => emit("saveSimplifiedNoteResult");
const newSentenceContent = (newContent, sentenceId) => emit("newSentenceContent", newContent, sentenceId);

function printSimplifiedNoteResult() {
  const noteSentences = { richText: richSimplifiedSentences.value };

  localStorage.setItem("printingTitle", getTitle());
  localStorage.setItem("printingBody", JSON.stringify(noteSentences));
  const printingRoute = router.resolve({ name: "printing-smart-phrase" });
  window.open(printingRoute.href);
}

const getTitle = () => {
  if (Object.keys(props.note).length > 0) {
    return props.note.title;
  } else {
    return props.simplifiedResult.title.split(" (Simplified)")[0];
  }
};

function sentenceSwitcher(newSentenceId) {
  sentenceOnReview.value = newSentenceId;
}

function isReviewUserInput(route) {
  return route.name === "review-simplification-user-input";
}

function isReviewRoute(route) {
  return route.name === "review-simplification" || route.name === "review-simplification-user-input";
}

function isSimplifiedRoute(route) {
  return route.name === "simplified" || route.name === "simplified-user-input";
}

function isUserInputRoute(route) {
  return route.name === "simplified-user-input" || route.name === "review-simplification-user-input";
}

function writeNewContentToSentence(newContent) {
  newSentenceContent(newContent, sentenceOnReview.value);
}

function showFinalCheckPage(reviewedAllSentences) {
  allSentencesReviewed.value = reviewedAllSentences;
}

async function copySimplifiedNoteResult() {
  let simplifiedTextNode = document.getElementsByClassName("ck-content");

  if (!simplifiedTextNode[0].lastChild.textContent) {
    copyButtonText.value = "Nothing to copy";

    return;
  }
  copyButtonText.value = "Copying in progress...";

  try {
    let phraseContent = editorInstance.sourceElement.nextSibling.lastChild;
    let watermarkContent = document.querySelector(".watermark");

    phraseContent.append(watermarkContent);

    const range = new Range();

    const startIndex = 0;
    const selection = window.getSelection();

    range.setStart(phraseContent, startIndex);
    range.setEnd(phraseContent, phraseContent.children.length);

    selection.removeAllRanges();
    selection.addRange(range);
    phraseContent.firstChild.scrollTop = 0;
    document.execCommand("copy");

    copyButtonText.value = "Copied! Paste (Ctrl+V) in your EHR";
    copiedToEhr.value = true;
  } catch (error) {
    displayError(error);
  }
}
</script>

<style lang="sass" scoped>
.simplificator-wrapper
  height: inherit
  display: flex
  flex-direction: column
  .simplificator-header
      padding: 5px 0 5px 26px

      .back-to-list-button
          cursor: pointer
          z-index: 5

  .simplificator-body
      border-top: 1px solid $gray-400
      display: flex
      flex-direction: row

      .watermark-after
        position: absolute
        bottom: 0px
        left: 10px

      .left-phrase-block
          border-right: 1px solid $gray-400

      .left-phrase-block,
      .right-phrase-block
          width: 50%
          padding: 24px 50px 0px 50px

          .editor-container
            .spinner-container
              display: flex
              position: relative

              .spinner-border-sm
                position: absolute
                top: 7px

          .phrase-description
              color: $blue
              display: inline-block
              font-size: 16px
              font-weight: 500
              margin-bottom: 20px

          .notes-name
                display: flex
                justify-content: space-between

                h4
                    margin-bottom: 30px
                    color: $blue-700
                    font-size: 20px

                .printer-img
                  margin-top: -10px
                  display: inline-block

                  img
                    cursor: pointer

          .text-area-original,
          .text-area-predicted
              position: relative
              display: flex
              flex-direction: column
              justify-content: space-between
              width: 100%
              padding: 7px 12px
              border: 1px solid $gray-400
              border-radius: 9px
              height: 60vh
              overflow-y: auto

              .sentence
                color: $black

              .editting-sentence
                background-color: $blue-200
                border-radius: 2px

              .red-message
                color: $red !important
              .simplification-info
                width: 85%
                text-align: center
                margin: 30% auto
                color: $black
                font-size: 15px

                .bold-simplification-info
                  font-weight: 600
                  display: block
                  margin-bottom: 20px

          .text-area-new-height
            height: 30vh
          .simplify-button
              margin: 40px 0px
              float: right
              padding: 11px 42px
              color: $white
              font-size: 15px
              font-weight: 600
              border: none
              border-radius: 8px
              background-color: $blue

              &:disabled
                pointer-events: none
                opacity: 0.65

          .save-print-btn-wrapper
            display: flex
            flex-direction: column
            width: 80%
            margin: 30px auto

            .save-button,
            .copy-button
              width: inerit
              font-size: 15px
              font-weight: 600
              border-radius: 8px
              padding: 11px 0

            .save-button
              background-color: $blue
              border: 1.5px solid $blue
              color: $white
              margin-bottom: 12px

            .copy-button
              margin-top: 30px
              border: 1.5px solid $blue-700
              color: $blue-700

            .copied-button
              margin-top: 60px
              background-color: $green
              border-color: $green
              color: $white
</style>
