<template>
  <div class="main-window">
    <div class="header">
      <img src="@/assets/images/header-logo.svg" alt="logo" />
    </div>
    <div class="row guest-content-wrapper">
      <div class="left-sign-block" :class="{ 'hide-left-block': hideLeftSignBlock }">
        <div class="left-sign-block-content">
          <img src="@/assets/images/white-logo.svg" alt="logo" />
          <p class="slogan">We are here you to let you help in the journey of genetics. Save time, improve care.</p>
          <div>
            <small class="opacity-50">{{ appVersion }}</small>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
const appVersion = process.env.VUE_APP_VERSION;

defineProps({
  hideLeftSignBlock: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="sass">

body
  background-color: $gray-100


  .header
    padding: 16px 0 16px 24px
    background-color: $white
    border-bottom: 1px solid $gray-200

  .guest-content-wrapper
    --bs-gutter-x: 0
    height: 94vh

    .hide-left-block
      position: absolute
      visibility: hidden

    .left-sign-block
      background-color: $blue-700
      display: flex !important
      align-items: center
      width: 40%
      background-image: url("@/assets/images/bg-login-page.png")

      .left-sign-block-content
        width: 60%
        margin-left: 64px

        img
          width: 300px

        .slogan
          font-size: 14px
          margin-top: 32px
          color: $white


    .right-sign-block
      background-color: $gray
      padding: 0 10px
      display: flex
      align-items: center
      justify-content: center


      .h4
        color: $blue-700 !important
        font-weight: 600
        font-size: 26px

      p
        font-size: 14px

      .formkit-label
        color: $blue-700
        font-weight: 400
        font-size: 14px

      .formkit-inner
        background-color: $gray-50
        border-radius: 12px
        font-size: 14px
        font-weight: 400

        &::after
          opacity: 0.6

        .formkit-input
          color: $gray-600


          &::placeholder
            color: $gray-600


      [data-invalid] .formkit-inner
        border: 1.5px solid $red
        box-shadow: 0 0 0 1px $red


      .form-wraper
        position: relative
        width: 60%
        background-color: $white
        box-shadow: 0px 5px 20px rgba(5, 46, 94, 0.1)
        border-radius: 16px

      .p-5
        padding: 60px !important
        max-width: 460px

      .text-underline-hover
        color: $blue
        font-size: 14px

      .login-manipulations
        margin-top: 20px
        width: 60%

      .btn-user
        position: absolute
        right: 25px
        bottom: 60px
        margin-top: 25px
        font-size: 14px
        font-weight: 500
        color: $white
        border-radius: 12px
        padding: 16px 18px
        background-color: $blue-700

        &:hover
          box-shadow: 0px 5px 20px rgba(5, 46, 94, 0.3)
</style>
