<template>
  <div class="eli8-paper">
    <div v-if="!noteLoaded" class="d-flex text-secondary justify-content-center spinner-container">
      <div class="spinner-border" role="status"></div>
    </div>
    <PhraseSimplifier
      v-if="noteLoaded"
      :note="note"
      :simplification-progress="simplificationProgress"
      :failure="failure"
      :simplified-result="simplifiedResult"
      :save-copy-progress="saveCopyProgress"
      :content="content"
      :simplified-sentences="simplifiedSentences"
      @send-note-to-simplification="sendNoteToSimplification"
      @save-simplified-note-result="saveSimplifiedNoteResult"
      @new-sentence-content="sentenceContentUpdater"></PhraseSimplifier>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import PhraseSimplifier from "./PhraseSimplifier";
import { eli8Service } from "@/services";

const noteLoaded = ref(false);
const simplificationProgress = ref(false);
const saveCopyProgress = ref(false);
const noteId = ref(null);
const checkerForNoteSimplifier = ref(null);
const newSimplifiedNote = ref({});
const note = ref({});
const simplifiedSentences = ref({});
const content = ref(null);
const failure = ref(false);

const route = useRoute();
const router = useRouter();

const simplifiedResult = ref({});

onMounted(async () => {
  noteId.value = route.params.noteId;
  localStorage.removeItem("printingTitle");
  localStorage.removeItem("printingBody");
  if (route.name === "simplified") {
    simplifiedResult.value = await eli8Service.getSimplifiedNote(noteId.value);
    simplifiedSentences.value = simplifiedResult.value.simplifiedSentences;
    content.value = simplifiedResult.value.content;
  } else if (route.name === "simplified-user-input") {
    simplifiedResult.value = await eli8Service.getSimplifiedNote(noteId.value, true);
    simplifiedSentences.value = simplifiedResult.value.simplifiedSentences;
  } else if (route.name === "review-simplification") {
    simplifiedResult.value = await eli8Service.getSimplificationForReview(noteId.value);

    if (simplifiedResult.value.reviewed) {
      simplifiedSentences.value = simplifiedResult.value.simplifiedSentences;
      router.push({ name: "simplified", params: { noteId: simplifiedResult.value.id } });
    }
  } else if (route.name === "review-simplification-user-input") {
    simplifiedResult.value = await eli8Service.getSimplificationForReview(noteId.value, true);

    if (simplifiedResult.value.reviewed) {
      simplifiedSentences.value = simplifiedResult.value.simplifiedSentences;

      router.push({ name: "simplified-user-input", params: { noteId: simplifiedResult.value.id } });
    }
  } else if (route.name === "simplify-user-input") {
    note.value = await eli8Service.getNoteForSimplification(noteId.value, true);
    simplificationProgress.value = note.value.isWithinTokenLimit;
  } else if (route.name === "simplify-draft-user-input") {
    note.value = await eli8Service.getNoteForSimplification(noteId.value, true);
  } else {
    note.value = await eli8Service.getNoteForSimplification(noteId.value);
  }

  noteLoaded.value = true;
});

onUnmounted(() => {
  clearInterval(checkerForNoteSimplifier.value);
});

async function sendNoteToSimplification() {
  const isUserInput = route.name === "simplify-draft-user-input";
  try {
    simplificationProgress.value = true;
    await eli8Service.startNoteSimplification(noteId.value, note.value, isUserInput);
  } catch (error) {
    failure.value = true;
  }
}

watch(simplificationProgress, () => {
  if (simplificationProgress.value) {
    checkerForNoteSimplifier.value = setInterval(getSimplificationForReview, 10000);
  } else {
    clearInterval(checkerForNoteSimplifier.value);
  }
});

async function getSimplificationForReview() {
  const isUserInput = route.name === "simplify-user-input" || route.name === "simplify-draft-user-input";
  simplifiedResult.value = await eli8Service.getSimplificationForReview(noteId.value, isUserInput);

  if (simplifiedResult.value.predictedSentences) {
    if (isUserInput) {
      router.push({ name: "review-simplification-user-input", params: { noteId: noteId.value, isUserInput } });
    } else {
      router.push({ name: "review-simplification", params: { noteId: noteId.value, isUserInput } });
    }
    simplificationProgress.value = false;
  }
}

async function saveSimplifiedNoteResult() {
  saveCopyProgress.value = true;
  const isUserInput = route.name === "review-simplification-user-input";
  const data = {
    reviewedSentences: simplifiedResult.value.combinedSentences,
    isUserInput,
  };
  newSimplifiedNote.value = isUserInput
    ? await eli8Service.reviewSimplification(simplifiedResult.value.id, data)
    : await eli8Service.reviewSimplification(simplifiedResult.value.clinicalNoteId, data);
  simplifiedSentences.value = newSimplifiedNote.value.simplifiedSentences;
  saveCopyProgress.value = false;

  if (isUserInput) {
    router.push({ name: "simplified-user-input", params: { noteId: simplifiedResult.value.id } });
  } else {
    router.push({ name: "simplified", params: { noteId: simplifiedResult.value.id } });
  }
}

function sentenceContentUpdater(newContent, sentenceId) {
  simplifiedResult.value.combinedSentences.sentences[sentenceId] = newContent;
}
</script>

<style lang="sass" scoped>
.eli8-paper
  height: auto
  background-color: $white
  .spinner-container
    padding-top: 30vh
</style>
