import { createApp } from "vue";
import { createPinia } from "pinia";
import "bootstrap";
import { plugin as formkitPlugin, defaultConfig as formkitCfg } from "@formkit/vue";
import mitt from "mitt";
import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag-next";
import Shortkey from "vue3-shortkey";
import App from "@/App.vue";
import router from "@/router";

import "@formkit/themes/genesis";
import "font-awesome/scss/font-awesome.scss";
import "./styles/main.scss";

createApp(App)
  .use(createPinia())
  .use(router)
  .use(Shortkey)
  .use(formkitPlugin, formkitCfg)
  .use(createHead())
  .use(VueGtag, {
    property: {
      id: "G-FWYYK95M0X",
    },
  })
  .provide("emitter", mitt())
  .mount("#app");
