<template>
  <div class="paper-menu" :class="{ 'shrunk-genes-menu': geneExplorerMenuShrunk }">
    <div class="paper-menu-wrapper">
      <ul>
        <li class="close-side-menu" @click="geneExplorerMenuShrunk = !geneExplorerMenuShrunk">
          <img src="@/assets/images/side-search-menu-close.svg" alt="close" />
        </li>
        <li v-for="(element, index) in props.parsedBeforeData" :key="index">
          <span v-if="element" @click="scrollToElement(relevantIdBuilder(index))">{{ index }}</span>
        </li>
        <li v-for="item in props.parsedData" :key="item.id">
          <span @click="scrollToElement(item.id)">{{ item.title }}</span>
        </li>
        <li v-for="(element, index) in props.parsedAfterData" :key="index">
          <span v-if="element" @click="scrollToElement(relevantIdBuilder(index))">{{ index }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useInterfaceElementsStore } from "@/stores";

const { geneExplorerMenuShrunk } = storeToRefs(useInterfaceElementsStore());

const props = defineProps({
  parsedBeforeData: {
    type: Object,
    default() {
      return [];
    },
  },
  parsedData: {
    type: Object,
    required: true,
  },
  parsedAfterData: {
    type: Object,
    default() {
      return [];
    },
  },
});

function relevantIdBuilder(str) {
  const relevantId = str.includes(" ") ? str.toLowerCase().replace(` `, `-`) : str.toLowerCase();
  return relevantId;
}

function scrollToElement(id) {
  document.getElementById(id).scrollIntoView({
    block: "start",
    behavior: "smooth",
  });
}
</script>

<style lang="sass" scoped>

.paper-menu
  width: 15%
  padding: 0px 3px 15px 24px
  border-right: 1px solid $gray-400
  font-size: 13px

  .paper-menu-wrapper
    height: 82vh
    overflow: auto
    overflow-wrap: anywhere
    position: sticky
    top: 24px

    ul
      list-style: none
      padding-left: 0

    li
      cursor: pointer
      font-weight: 500

      &:not(:first-child)
        margin-top: 16px

      span
        text-decoration: none
        color: $gray-1000

        &:hover
          color: $blue

    .close-side-menu
      margin-left: -10px
      cursor: pointer

.paper-menu.shrunk-genes-menu
  width: 10%
  padding: 0px 0px 15px 24px

  li:not(:first-child)
    display: none


@media (max-width: 1640px)
  .paper-menu
    padding: 0px 3px 15px 7px

  .paper-menu.shrunk-genes-menu
    padding: 0px 0px 15px 7px
</style>
