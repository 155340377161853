<template>
  <div class="synopsis-wrapper">
    <div v-if="props.original" class="export-paragraph-btn" @click="exportSynopsis(synopsisViewForEditor, false)">
      <span class="paragraph-hint">Copy</span>
      <div class="plus-img-container">
        <img src="@/assets/images/blue-cross.svg" alt="add-paragraph" />
      </div>
    </div>

    <div class="synopsis-main-content">
      <div v-for="value in newSynopsis" :key="value" class="section">
        <span v-if="value.clinicalFeaturesList">
          <span class="title">{{ value.title }}</span>
          <ul>
            <li v-for="element in value.clinicalFeaturesList" :key="element" class="single-element">
              <span v-if="element.text">
                {{ element.text }}
                <a target="_blank" :href="`${element.linkBody}`">{{ element.linkText }} </a>)
              </span>
              <span v-else>
                {{ element }}
              </span>
            </li>
          </ul>
        </span>

        <span v-else-if="value.clinicalFeatures" class="with-sub-title">
          <span class="title">{{ value.title }}</span>
          <InteractiveSynopsis :synopsis="value.clinicalFeatures" :original="false"></InteractiveSynopsis>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, onMounted } from "vue";

const synopsisView = ref("");
const newSynopsis = ref([]);
const linkInSynopsis = ref(false);
const synopsisLinkContent = ref({});

const linkExp = /(?<=\[)(.*?);(.*?)(?=\])/g;
const dirtyLinkExp = /(?<=\[)(.*?)(?=\])/g;
const removeExp = /(?=\[)(.*?)+/g;

const eventsEmitter = inject("emitter");

const props = defineProps({
  synopsis: {
    type: Object,
    required: true,
  },
  original: {
    type: Boolean,
    required: true,
  },
});

onMounted(() => {
  newSynopsis.value = props.synopsis.map(function preprocessClinicalFeaturesElement(element) {
    if (element.clinicalFeaturesList) {
      return preprocessClinicalFeaturesList(element);
    } else {
      let features = [];
      for (let feature in element.clinicalFeatures) {
        features += preprocessClinicalFeaturesList(element.clinicalFeatures[feature]);
      }
      return {
        title: element.title,
        clinicalFeatures: features,
        ...element,
      };
    }
  });
});

function preprocessClinicalFeaturesList(element) {
  return {
    title: element.title,
    clinicalFeaturesList: element.clinicalFeaturesList.map(function updateSynopsisLinks(listElement) {
      checkStringForLinkContent(listElement);
      if (linkInSynopsis.value >= 0) {
        createLinksContent(listElement);
        return {
          text: listElement.replace(removeExp, ""),
          linkBody: synopsisLinkContent.value.linkBody,
          linkText: synopsisLinkContent.value.linkText,
        };
      }
      return listElement;
    }),
  };
}

function checkStringForLinkContent(string) {
  linkInSynopsis.value = string.search(dirtyLinkExp);
}

function createLinksContent(element) {
  element.replace(linkExp, (_, linkBody, linkText) => {
    synopsisLinkContent.value = { linkBody: linkBody, linkText: linkText };
  });
}

function exportSynopsis(content, isSentence) {
  eventsEmitter.emit("exporting-text-element", { content, isSentence });
}

const synopsisViewForEditor = synopsisViewBuilder(props.synopsis);

function synopsisViewBuilder(synopsis) {
  for (let item in synopsis) {
    if (synopsis[item].clinicalFeaturesList) {
      synopsisView.value += `<p><b>${synopsis[item].title}</b>: `;
      for (let element in synopsis[item].clinicalFeaturesList) {
        checkStringForLinkContent(synopsis[item].clinicalFeaturesList[element]);
        if (linkInSynopsis.value < 0) {
          synopsisView.value += `${synopsis[item].clinicalFeaturesList[element]}; `;
        } else {
          createLinksContent(synopsis[item].clinicalFeaturesList[element]);
          synopsisView.value += `${synopsis[item].clinicalFeaturesList[element].replace(removeExp, "")}
          <a target="_blank" href="${synopsisLinkContent.value.linkBody}">${synopsisLinkContent.value.linkText}</a>); `;
        }
      }
      synopsisView.value += `</p>`;
    } else if (synopsis[item].clinicalFeatures) {
      synopsisView.value += `<p><b>${synopsis[item].title}</b></p>`;
      synopsisViewBuilder(synopsis[item].clinicalFeatures);
    }
  }
  if (synopsis.citationText) synopsisView.value += synopsis.citationText;
  return synopsisView;
}
</script>

<style lang="sass" scoped>
.synopsis-wrapper
  padding: 5px 20px 0 15px
  justify-content: space-between
  position: relative
  margin-bottom: 12px

  .export-paragraph-btn
    background: $gray
    display: flex
    position: absolute
    align-items: center
    right: 10px
    border-radius: 25px
    cursor: pointer

    .plus-img-container
      padding: 10.5px
      display: flex
      justify-content: center
      align-items: center

    .paragraph-hint
      display: none
      pointer-events: none
      margin-left: 10px

    &:hover .paragraph-hint
      display: block

    &:hover + .paragraph-content .interactive-sentence
      background-color: $blue-200
      border-radius: 2px

.synopsis-main-content

    .section .title
        font-weight: 500

    .with-sub-title

        .synopsis-wrapper
            margin-left: 40px

            .section

                .title
                    font-weight: 400
                    font-style: italic
</style>
