import { FORM_ERRORS_TEXT, OMIM_ENTRY_TYPES } from "@/utils/constants";
import { parse, format } from "date-fns";

export function tryParseApiErrors(error) {
  let resultErrors = [];

  const validationErrors = error.response.data.validation_errors;

  if (validationErrors) {
    resultErrors.push("Followind validation errors detected: ");
    for (let key in validationErrors) {
      resultErrors.push(key + " " + "errors: " + validationErrors[key]);
    }
  } else if (error.response.data.error_details) {
    resultErrors = [error.response.data.error_details];
  } else {
    resultErrors = [FORM_ERRORS_TEXT.UNKNOWN_SERVER_ERROR];
  }
  return resultErrors;
}

export function renderArray(array) {
  return array.join(", ");
}

export function convertDateFormat(date) {
  if (!date) {
    return "";
  }

  return format(parse(date, "yyyy-MM-dd", new Date()), "MMM dd, yyyy");
}

export function convertDateFormatHours(date) {
  if (!date) {
    return "";
  }

  return format(parse(date, "yyyy/MM/dd HH:mm", new Date()), "MMM dd, yyyy");
}

export function convertDateFormatForNotes(date) {
  if (!date) {
    return "";
  }
  const dateWithoutTime = date.substring(0, 10);

  return format(parse(dateWithoutTime, "yyyy-MM-dd", new Date()), "MMM dd, yyyy");
}

export function getFirstLetter(term) {
  if (!term) {
    return "";
  }

  return term.slice(0, 1).toUpperCase();
}

export function getOmimTypeLetter(entryType) {
  return entryType == OMIM_ENTRY_TYPES.gene_and_phenotype ? "G/P" : getFirstLetter(entryType);
}

export function getOmimTypeClass(entryType) {
  return entryType.replace(/\W/g, "-");
}

// Use only for data format "Last name, First name"
export function authorNamePreprocess(author) {
  return author.split(",").reverse().join(" ");
}

export function setIntervalImmediately(func, interval) {
  func();
  return setInterval(func, interval);
}
