<template>
  <PageLayout>
    <div class="main-settings-block">
      <h1>Edit Your Information</h1>
      <FormKit
        v-model="userInfo"
        type="form"
        form-class="user-form"
        :actions="false"
        :errors="errors"
        @submit="changeData">
        <div class="avatar">
          <img src="https://github.com/mdo.png" alt="mdo" width="136" height="128" class="rounded-circle" />
        </div>
        <div class="col-7">
          <FormKit type="select" name="title" placeholder="Title" :options="PROFESSIONS_TITLE" />
        </div>
        <div class="col-7">
          <FormKit type="text" name="first_name" placeholder="First Name" />
        </div>
        <div class="col-7">
          <FormKit type="text" name="last_name" placeholder="Last Name" />
        </div>
        <div class="col-7">
          <button type="submit" class="btn btn-primary btn-user">Save changes</button>
        </div>
      </FormKit>
    </div>
  </PageLayout>
</template>

<script setup>
import { ref } from "vue";
import PageLayout from "@/layouts/RegisteredUser.vue";
import { PROFESSIONS_TITLE } from "@/utils/constants";

const userInfo = ref({});
const changeData = async () => {
  alert("Not implemented yet");
};
</script>

<style lang="sass" scoped>
.main-settings-block
    margin: 0 auto
    width: 90%
    padding: 20px 0 50px 0
.avatar
    width: 136px
    margin: 40px auto
.col-7
    margin: auto
h1
    text-align: center
</style>
