<template>
  <GuestUserLayout>
    <div class="col right-sign-block">
      <div class="form-wraper">
        <div class="p-5">
          <div class="text-start">
            <h1 class="h4 text-gray-900 mb-4">Create an Account!</h1>
          </div>
          <div v-if="registered">
            <div class="text-start text-success mb-5">
              <p>
                <i class="fa fa-check-circle fa-4x"></i>
              </p>
              <h5>Account created successfully</h5>
            </div>
            <p class="mb-4">Please check your <b>email inbox or spam</b> for a link to verify your account.</p>
          </div>
          <div v-else-if="failure">
            <div class="text-start text-danger">
              <p>
                <i class="fa fa-plus-circle fa-4x close-icon"></i>
              </p>
              <h5>{{ FORM_ERRORS_TEXT.REGISTRATION_FAILED }}</h5>
            </div>
            <p class="mb-4">
              {{ FORM_ERRORS_TEXT.UNKNOWN_SERVER_ERROR }}
            </p>
          </div>
          <div v-else>
            <FormKit
              v-slot="{ state: { loading } }"
              v-model="userInfo"
              type="form"
              form-class="user-form"
              :actions="false"
              :errors="errors"
              @submit="registerUser">
              <div class="row">
                <div class="col-5">
                  <FormKit
                    type="select"
                    name="title"
                    placeholder="Title"
                    :options="PROFESSIONS_TITLE"
                    validation="required" />
                </div>
                <div class="col-5">
                  <FormKit
                    type="select"
                    name="subspecialty"
                    placeholder="Subspecialty"
                    :options="SUBSPACIALTY_TITLE"
                    validation="required" />
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <FormKit
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    validation="required|length:2"
                    validation-label="First Name" />
                </div>
                <div class="col-5">
                  <FormKit
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    validation="required|length:2"
                    validation-label="Last Name" />
                </div>
              </div>
              <FormKit
                type="email"
                name="email"
                placeholder="Enter Your Email Address"
                validation="required|email|emailIsNotFree"
                :validation-rules="{ emailIsNotFree }"
                :validation-messages="{
                  email: FORM_ERRORS_TEXT.EMAIL_NOT_VALID,
                  emailIsNotFree: FORM_ERRORS_TEXT.EMAIL_IS_NOT_FREE,
                }" />
              <div class="row">
                <div class="col-6">
                  <FormKit
                    type="password"
                    name="password"
                    placeholder="Password"
                    :validation="INPUT_REQUIREMENTS.PASSWORD_REQUIRMENTS"
                    :validation-messages="{ matches: FORM_ERRORS_TEXT.PASSWORD_NOT_VALID }" />
                </div>
                <div class="col-6">
                  <FormKit
                    type="password"
                    name="password_confirm"
                    validation-label="Password confirmation"
                    validation="required|confirm"
                    placeholder="Repeat Password" />
                </div>
              </div>
              <div class="login-manipulations">
                <div class="text-start">
                  <router-link :to="{ name: 'password-reset-request' }" class="small text-underline-hover">
                    Forgot Password?
                  </router-link>
                </div>
                <div class="text-start">
                  <router-link :to="{ name: 'login' }" class="small text-underline-hover">
                    Already have an account? Login!
                  </router-link>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="submit" class="btn btn-primary btn-user">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  Register Account
                </button>
              </div>
            </FormKit>
          </div>
        </div>
      </div>
    </div>
  </GuestUserLayout>
</template>

<script setup>
import { ref } from "vue";
import freeEmailDomains from "free-email-domains";
import { INPUT_REQUIREMENTS, FORM_ERRORS_TEXT, PROFESSIONS_TITLE, SUBSPACIALTY_TITLE } from "@/utils/constants";
import { tryParseApiErrors } from "@/utils";

import GuestUserLayout from "@/layouts/GuestUser.vue";
import authService from "@/services/authService";

const errors = ref([]);
const registered = ref(false);
const userInfo = ref({});
const failure = ref(false);

const emailIsNotFree = ({ value }) => {
  const domainName = value.split("@")[1].toLowerCase();

  return !freeEmailDomains.includes(domainName);
};

const registerUser = async () => {
  try {
    await authService.register(userInfo.value);
    registered.value = true;
  } catch (error) {
    errors.value = tryParseApiErrors(error);
    failure.value = true;
  }
};
</script>

<style lang="sass" scoped>
.close-icon
  transform: rotate(45deg)
</style>
