// import Vue from 'vue'
import { createWebHistory, createRouter } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import { useAuthStore } from "@/stores";

import HomePage from "@/pages/HomePage";
import MyPhrases from "@/pages/MyPhrases";
import ConfirmEmail from "@/pages/ConfirmEmail";
import SignUp from "@/pages/SignUp";
import SignIn from "@/pages/SignIn";
import PasswordResetRequest from "@/pages/PasswordResetRequest";
import PasswordReset from "@/pages/PasswordReset";
import NotFoundPage from "@/pages/NotFoundPage";
import UserSettings from "@/pages/UserSettings";
import GeneticSearch from "@/pages/GeneticSearch";
import UserAgreement from "@/pages/UserAgreement";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import SettingsPage from "@/pages/SettingsPage";
import Eli8Page from "@/pages/Eli8Page";
import Eli8SimplificationPage from "@/pages/Eli8SimplificationPage";
import printingSmartPhrase from "@/components/PrintingSmartPhrase";

const publicAccessRoutes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404-error",
  },
  {
    path: "/confirm-email/:user_id/:token",
    name: "confirmEmail",
    component: ConfirmEmail,
    title: "Lamar Health",
  },
  {
    path: "/register",
    name: "register",
    component: SignUp,
    title: "Lamar Health - Create Account",
  },
  {
    path: "/login",
    name: "login",
    component: SignIn,
    title: "Lamar Health - Log In",
  },
  {
    path: "/password-reset-request",
    name: "password-reset-request",
    component: PasswordResetRequest,
    title: "Lamar Health",
  },
  {
    path: "/password-reset/:token",
    name: "password-reset",
    component: PasswordReset,
    title: "Lamar Health",
  },
  {
    path: "/404-error",
    name: "404-error",
    component: NotFoundPage,
    title: "Lamar Health",
  },
  {
    path: "/user-agreement",
    name: "user-agreement",
    component: UserAgreement,
    title: "Lamar Health - User Agreement",
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    title: "Lamar Health - Privacy Policy",
  },
];

const protectedRoutes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    title: "Lamar Health",
  },
  {
    path: "/my-phrases",
    name: "my-phrases",
    component: MyPhrases,
    title: "Lamar Health - My Phrases",
  },
  {
    path: "/settings",
    name: "settings",
    component: UserSettings,
    title: "Lamar Health - Settings",
  },
  {
    path: "/smart-phrase/:noteId?",
    name: "smart-phrase",
    component: GeneticSearch,
    title: "Lamar Health - Create New Phrase",
  },
  {
    path: "/user-profile",
    name: "user-profile",
    component: UserSettings,
    title: "Lamar Health - My Profile",
  },
  {
    path: "/settings-page",
    name: "settings-page",
    component: SettingsPage,
    title: "Lamar Health - Settings",
  },
  {
    path: "/eli8/",
    name: "eli8",
    component: Eli8Page,
  },
  {
    path: "/eli8/simplify/:noteId?",
    name: "simplify",
    component: Eli8SimplificationPage,
  },
  {
    path: "/eli8/simplify-draft-user-input/:noteId?",
    name: "simplify-draft-user-input",
    component: Eli8SimplificationPage,
  },
  {
    path: "/eli8/simplify-user-input/:noteId?",
    name: "simplify-user-input",
    component: Eli8SimplificationPage,
  },
  {
    path: "/eli8/review-simplification/:noteId?",
    name: "review-simplification",
    component: Eli8SimplificationPage,
  },
  {
    path: "/eli8/review-simplification-user-input/:noteId?",
    name: "review-simplification-user-input",
    component: Eli8SimplificationPage,
  },
  {
    path: "/eli8/simplified/:noteId?",
    name: "simplified",
    component: Eli8SimplificationPage,
  },
  {
    path: "/eli8/simplified-user-input/:noteId?",
    name: "simplified-user-input",
    component: Eli8SimplificationPage,
  },
  {
    path: "/printing-smart-phrase",
    name: "printing-smart-phrase",
    component: printingSmartPhrase,
  },
];

for (const r of publicAccessRoutes) {
  r.meta = { title: r.title || "Lamar Health" };
}

for (const r of protectedRoutes) {
  r.meta = { loginRequired: true, title: r.title || "Lamar Health" };
}

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: publicAccessRoutes.concat(protectedRoutes),
});

router.beforeEach((to) => {
  const authManager = useAuthStore();
  document.title = to.meta.title;
  if (!authManager.loggedIn && to.meta.loginRequired) {
    return { name: "login" };
  }
});

trackRouter(router);

export default router;
