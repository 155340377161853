<template>
  <div class="printing-wrapper">
    <div class="print-page-body">
      <h1 class="title">{{ title }}</h1>
      <span v-html="body.richText"></span>
    </div>
    <div class="print-footer">
      <span
        >Made with <span class="blue-span">Lamar Health</span>. To get alerts from experts on your genetics go to
        <a href="https://lamarhealth.com/" target="_blank">www.lamarhealth.com</a>.
      </span>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
// import sanitizeHtml from "sanitize-html";
// import { printingRichTextRules } from "@/utils/htmlSanitizeRules";

const title = localStorage.getItem("printingTitle");
const body = JSON.parse(localStorage.printingBody);

document.title = title;

onMounted(() => {
  window.print();
});
</script>

<style lang="sass" scoped>

.printing-wrapper
  display: flex
  flex-direction: column
  height: 93vh
  justify-content: space-between
  width: 80%
  margin: 20px auto 10px auto
  position: relative
  font-weight: 400
  color: $black

  .print-page-body

    .title
      color: $blue
      font-size: 24px

    .note-content
      display: block

  .print-footer
    border-top: 2px solid black
    border-bottom: 2px solid black
    padding: 20px 0
    .blue-span
      color: $blue

    a
      color: $blue-650
</style>
