import { defineStore } from "pinia";
import { ref } from "vue";

export const useClinicalNoteEditorStore = defineStore("clinical-note-editor", () => {
  const clinicalNoteId = ref(null);
  const noteSaveProcess = ref(null);

  return {
    clinicalNoteId,
    noteSaveProcess,
  };
});
